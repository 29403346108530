<template>
    <div>
        <Banner/>
        <Notice :Notice='Banner1.notice'/>
        <div class="yas">
        <Friendly-Links class="content"/>
        </div>
        <Performance :Data='Cate' :Http='Http' :Banner='Banner1'/>
        <News  :Newsname='News' :NewsNav='NewsNav' @Newslieb='Newslieb' :Banner='Banner1'/>
    </div>
</template>

<script>
import Banner from '@/components/pc/Banner'
import Notice from '@/components/pc/Notice'
import News from '@/components/pc/News'
import Business from '@/components/pc/Business'
import Performance from '@/components/pc/Performance'
import Course from '@/components/pc/Course'
import FriendlyLinks from '@/components/pc/FriendlyLinks'
import Float from '@/components/pc/Float'
import{mapState} from'vuex';
export default {
    components:{
      Banner,
      Notice,
      News,
      Business,
      Performance,
      Course,
      FriendlyLinks,
      Float
    },
    data() {
        return {
            naml:{
                cid:1,
                limit:2,
                page:1,
            },
            namltwo:{
                cid:2,
                limit:2,
                page:1,
            },
        }
    },
    mounted() {
        // //获取新闻
        // this.$store.dispatch('PostNews',this.naml);
        this.Newslieb(1)
    },
    computed:{
        ...mapState({
            Cate:state=>state.share.Cate,
            Banner1:state=>state.share.Banner1,
            floatdada:state=>state.share.floatdada,
            Notice:state=>state.share.Notice,
            News:state=>state.share.News,
            NewsNav:state=>state.share.NewsNav,
            Http:state=>state.share.Http
        })
    },
    methods: {
        Newslieb(id){
            this.namltwo.cid=id
            this.$store.dispatch('PostNews',this.namltwo);
        }
    },
}
</script>

<style scoped>
    .yas{
        background-color: #f7f7f7;
        overflow: hidden;
    }
    @media (max-width: 768px){
        .content{
            overflow: hidden;
        }
    }
</style>
