<template>
  <Business-tow/>
</template>

<script>
import BusinessTow from '@/components/pc/BusinessTow'
export default {
    components:{
        BusinessTow
    }
}
</script>

<style scoped>

</style>