import Vue from 'vue'
import App from './App.vue'
//引入路由
import router from '@/router'
//引入仓库
import store from '@/store'
import { WOW } from 'wowjs';
//防抖于节流
import VueLazyload from 'vue-lazyload'
import loadimage from '@/assets/errorimage.png'
Vue.use(VueLazyload, {
  // preLoad: 1.3, //预加载的宽高比
  loading: loadimage,//图片加载状态下显示的图片
  // error: errorimage, //图片加载失败时显示的图片
  attempt: 1, // 加载错误后最大尝试次数
})
Vue.prototype.$wow = new WOW({
  boxClass: 'wow', // 需要执行动画的元素的 class
  animateClass: 'animated', //animation.css 动画的 class
  offset: 0, // 距离可视区域多少开始执行动画
  mobile: true, // 是否在移动设备上执行动画
  live: true, // 异步加载的内容是否有效
});
//全局组件
import 'animate.css'
import Pagination from '@/components/pc/Pagination';
import Tabulation from '@/components/pc/Tabulation';
import Details from '@/components/pc/Details';
import Carousel from '@/components/Carousel';
import CarouselMobile from '@/components/CarouselMobile';
Vue.component(Pagination.name,Pagination)
Vue.component(Tabulation.name,Tabulation)
Vue.component(Details.name,Details)
Vue.component(Carousel.name,Carousel)
Vue.component(CarouselMobile.name,CarouselMobile)
Vue.config.productionTip = false
new Vue({
  render: h => h(App),
  router,
  store  //注册仓库:组件实例对象的身上会多一个$store属性
}).$mount('#app')

