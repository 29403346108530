//API进行统一管理
import requests from './axios';

//首页banner 
export const reqBanner=()=>requests({url:'/banner',method:'POST'})
//总经理致辞
export const Banner1=(params)=>requests({url:'/index/index',method:'POST',data:params})   
//类型 1、公司介绍 2、总经理致辞 3、企业文化 4、组织结构 5、供热服务 6、服务承诺 7、供热范围 8、违章用热举报
export const About=(params)=>requests({url:'/company/index',method:'POST',data:params})
//通知公告
export const Nt=(params)=>requests({url:'/news/nt',method:'POST',data:params})
//通知公告-详情
export const NtInfo=(params)=>requests({url:'/news/ntInfo',method:'POST',data:params})
//新闻列表
export const News=(params)=>requests({url:'/news/index',method:'POST',data:params})
//新闻列表-详情
export const NewsInfo=(params)=>requests({url:'/news/info',method:'POST',data:params})
//资质荣誉
export const Cate=(params)=>requests({url:'/honor/index',method:'POST',data:params})
//典型列表
export const Cases=(params)=>requests({url:'/cases',method:'POST',data:params})
//典型列表-详情
export const CasesInfo=(params)=>requests({url:'/casesInfo',method:'POST',data:params})
//装置列表
export const CaseTp=(params)=>requests({url:'/caseTp',method:'POST',data:params})
//典型案例主要业绩
export const CaseOther=(params)=>requests({url:'/caseOther',method:'POST',data:params})
//装置列表-详情
export const CaseTpInfo=(params)=>requests({url:'/caseTpInfo',method:'POST',data:params})
//对外交流
export const Exchange=(params)=>requests({url:'/exchange',method:'POST',data:params})
//对外交流-详情
export const ExchangeInfo=(params)=>requests({url:'/exchangeInfo',method:'POST',data:params})
//涉外项目
export const Pj=(params)=>requests({url:'/pj',method:'POST',data:params})
//涉外项目-详情
export const PjInfo=(params)=>requests({url:'/pjInfo',method:'POST',data:params})
//涉外项目-其他
export const OtherPj=(params)=>requests({url:'/OtherPj',method:'POST',data:params})
//社会荣誉
export const Honor=(params)=>requests({url:'/honor',method:'POST',data:params})
//社会荣誉-详情
export const HonorInfo=(params)=>requests({url:'/honorInfo',method:'POST',data:params}) 
//供热常识
export const Performance=(params)=>requests({url:'/hear/index',method:'POST',data:params}) 
//供热常识-详情
export const PerformanceInfo=(params)=>requests({url:'/hear/info',method:'POST',data:params})
//联系我们
export const Main=(params)=>requests({url:'/index/main',method:'POST',data:params})
//友情链接
export const Link=(params)=>requests({url:'/link',method:'POST',data:params})
//政策分类
export const MemberCt=(params)=>requests({url:'/policy/cate',method:'POST',data:params})
//政策列表
export const Member=(params)=>requests({url:'/policy/index',method:'POST',data:params})
//政策详情
export const MemberInfo=(params)=>requests({url:'/policy/info',method:'POST',data:params})   
//员工详情
export const Stylist=(params)=>requests({url:'/stylist',method:'POST',data:params})   

