<template>
    <div class="nark">
        <!--资质荣誉-->
        <div class="title">
            <p>
                <i>组织结构</i>
            </p>
            <span>organizational structure</span>
        </div>
        <img src="img/zx-5.jpg" class="beij  pc">
        <img src="img/zx-8.jpg" class="beij  mobile">
    </div>
</template>

<script>
export default {

}
</script>
<style scoped>
    .beij{
        width: 100%;
    }
</style>