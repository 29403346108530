<template>
    <div class="nrk exchange">
        <div class="Servic ">
            <p>对外交流项目展示</p>
        </div>
        <div v-for="item in Exchange" :key="item.id" class="exchange-nr">
            <ul>
                <li v-for="img in item.img_list" :key="img.id" @click="Details(img.id)">
                    <img v-lazy="Http+img.src"/>
                </li>
            </ul>
            <h1>{{item.title}}</h1>
        </div>
    </div>
</template>

<script>
 import{mapState} from'vuex';
export default {
    mounted() {
        this.$store.dispatch('PostExchange');
    },
    computed:{
        ...mapState({
            Exchange:state=>state.share.Exchange,
            Http:state=>state.share.Http,
        })
    },
    methods: {
        Details(id){
            this.$router.push({
                name:'ExcDetails',
                    query:{
                        id:id,
                    }
            })
        }
    },
}
</script>
<style scoped>
    .exchange{
        margin: 36px auto;
        overflow: hidden;
    }
    .exchange-nr{
        width: 100%;
    }
    .exchange-nr h1{
        text-align: center;
        margin-bottom: 40px;
        margin-top: 20px;
        font-size: 18px;
    }
    .exchange-nr ul{
        display: flex;
        justify-content: center;  
    }
    .exchange-nr ul li{
        width: 31%;
        margin-left: 3.5%;
        height: 260px;
        overflow: hidden;
        cursor: pointer;
    }
    .exchange-nr ul li img{
        width: 100%;
        height: 260px;
    }
    .exchange-nr ul li:hover img {
    transform: scale(1.1);
    transition: all 0.5s ease; 
    }
    .exchange-nr ul li:nth-child(1){
        margin-left: 0;
    }
    @media (max-width: 768px){
        .exchange-nr ul li img{
            height: auto;
        }
        .exchange-nr ul{
            display: block;
        }
        .exchange-nr ul li{
            width: 100%;
            margin-left: 0%;
            height: auto;
            margin-bottom: 20px;
        }
        .smallContent>>>img {
        width: 100% !important;
        }
        .Servic{
            margin-top: 0;
        }
        .Servic p{
            padding: 0;
            font-size: 22px;
        }
    }
</style>