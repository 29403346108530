//vuex模块的小仓库
//仓库储存数据
const state={
    
};
//修改state唯一手段
const mutations={

};
//处理actions,可以写自己的业务逻辑,也可以异步
const actions={

};
//计算属性,用于简化仓库数据,让组件获取仓库的数据更方便
const getters={
    
};
export default{
    state,
    mutations,
    actions,
    getters
}