<template>
    <div>
        <News-List :data='News' :title="title"/>
        <Pagination 
        :pageNo="naml.page" 
        :pageSize='naml.limit' 
        :total="News.page" 
        :continues="5"
        @getPageNo='getPageNo'
        />
    </div>
</template>

<script>
import {mapState} from 'vuex'
import NewsList from '@/components/pc/NewsList'
export default {
  data() {
    return {
        naml:{
          cid:2,
          limit:6,
          page:1,
        },
        title:{
            name:'行业资讯',
            English:'Industry information'
        }
    }
  },
    components:{
      NewsList
    } ,
    activated() {
        this.postNews(this.naml)
    },
    computed:{
        ...mapState({
            News:state=>state.share.News
        })
    },
    methods: {
        getPageNo(id){
            this.naml.page=id
            this.$store.dispatch('PostNews',this.naml);
        },
        postNews(naml){
            this.$store.dispatch('PostNews',naml);
        }
    },
}
</script>

<style>

</style>