<template>
    <div>
        <News-List :data='Notice' :title="title"/>
        <Pagination 
        :pageNo="naml.page" 
        :pageSize='naml.limit' 
        :total="Notice.page" 
        :continues="5"
        @getPageNo='getPageNo'
        />
    </div>
</template>

<script>
import {mapState} from 'vuex'
import NewsList from '@/components/pc/NewsList'
export default {
  data() {
    return {
        naml:{
          cid:1,
          limit:6,
          page:1,
        },
        title:{
            name:'公司动态',
            English:'Company dynamics'
        }
    }
  },
    components:{
      NewsList
    } ,
    activated() {
        this.postNews(this.naml)
    },
    computed:{
        ...mapState({
            Notice:state=>state.share.Notice
        })
    },
    methods: {
        getPageNo(id){
            this.naml.page=id;
            this.postNews(this.naml)
        },
        postNews(naml){
            this.$store.dispatch('PostNews',naml);
        }
    },
}
</script>

<style>

</style>