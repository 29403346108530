<template>
  <div id="app" v-cloak>
    <!-- <Ioading v-show="!apparent" /> -->
    <router-view></router-view>
  </div>
</template>

<script>
import Ioading from '@/components/Ioading'
export default {
  name: 'App',
  data() {
    return {
      apparent:false,
      Link:{
        page:1,
        limit:100,
      },
    }
  },
  components:{
    Ioading
  } ,
  mounted() {
    this.$nextTick(() => {
      this.$wow.init();
    });
    setTimeout(function() {
      this.apparent = true;
    }.bind(this), 3000)
    // //获取banner
    // this.$store.dispatch('shareBbanner',this.Link);
    // //获取友情链接
    // this.$store.dispatch('PostLink');
    //联系我们
    this.$store.dispatch('PostMain');
    //政策分类
    this.$store.dispatch('PostMemberCt');
    // 资质荣誉
    this.$store.dispatch('PostCate');
    //总经理致辞
    this.$store.dispatch('PostBanner1',{type:2});
  },
}
</script>

<style>
  .banone{
    width: 100%;
  }
  .nrks{
    width: 1330px;
    margin: 0 auto;
  }
  #app{
    overflow: hidden;
  }
  [v-cloak] {
      display: none;
  }
  
  .title{
      text-align: center;
      padding: 30px 0;
      overflow: hidden;
      margin: 30px 0;
  }
  .title p{
      font-size: 24px;
      font-weight: bold;
      display: inline-block;
      position: relative;
  }
  .title p i{
      position: relative;
      z-index: 9;
  }
  .title p::after{
      content: '';
      position: absolute;
      top: -14px;
      left: -14px;
      width: 30px;
      height: 30px;
      background-color: #31cf47;
      z-index: 1;
  }
  .title span{
      color: #31cf47;
      font-size: 14px;
      margin-top: 4px;
      display: block;
  }
  .nrk{
    width: 1200px;
    margin: 0 auto;
  }
  .nark{
    width: 100%;
    min-width: 1200px;

  }
  .Servic{
        text-align: center;
        margin-top: 30px;
        margin-bottom: 30px;
  }
  .Servic p{
        font-size: 26px;
        padding: 18px 0;
        display: block;
        font-weight: bold;
  }
  .Servic span{
        font-size: 18px;
        line-height: 30px;
        display: block;
  }
  .Servic img{
    display: block;
    margin: 0 auto;
  }
  .bottom-btn{
    overflow: hidden;
    margin-bottom: 26px;
  }
  .hs{
    color: #0019a7;
  }
  .img-in{
    width: 100%;
  }
  .detecti-text{
    width: 1200px;
  }
  .detecti img{
    width: 100% !important;
  }
  .mobile{
    display: none;
  }
  @media (max-width: 768px){
    .pc{
      display: none;
    }
    .mobile{
      display: block;
    }
    .title{
      margin: 20px 0;
      padding: 15px 0;
    }
    .detecti-text,.detecti-mans{
      width: auto !important;
    }
    .noe{
      width: 100% !important;
      margin-bottom: 20px;
    }
    .detecti-mans .noe{
      margin-top: 0 !important;
    }
    .detecti-text span{
      margin-bottom: 20px !important;
      font-size: 14px !important;
    }
    .nrk{
      width:92%;
      margin: 0 auto;
    }
    .nark,.nrks{
      width: 100%;
      min-width: auto;
    }
    .img-banner{
      width: 160%;
      margin-left: -30%;
    }
  }
</style>
