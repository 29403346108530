<template>
    <div>
        <img src="img/banner-0.jpg" class="banone pc">
        <img src="img/web-baner1.jpg" class="banone mobile">
        <div class="top-img"></div>
        <div class="top-img1">
            <h1>GENERAL MANAGER’S ADDRESS</h1>
            <div class="zc-text">
                <img src="@/assets/zx-16.jpg" > 
                <p class="litext">
                    <span>Dear users in Jiutai District, on behalf of Rongxiang Heating Co., Ltd., in Jiutai District Changchun, I would like to express my heartfelt gratitude to the vast number of users and friends from all walks of life who care and support the development of heating in Jiutai District!</span> 
                </p>
            </div>
            <div class="sdiwq">
                    In recent years, Rongxiang Heating Co., Ltd. has entered a stage of robust development, an elevation in management and service quality, and a phase where all employees boost their spirits, unite and strive together, advancing through reform and innovation!
            </div>
            <div class="sdiwq">Looking to the future, with heavy responsibilities on our shoulders, in our future work, we have confidence, determination, and capability. Under the leadership of the district committee and government, we will continue to uphold the principle of “promoting the economic development of Jiutai District, improving the urban living environment, and enhancing the quality of residential heating”. Our goal is to establish the image of Rongxiang, build a branded enterprise, and foster a corporate culture of “family, harmony, righteousness, love, and sincerity.” We will continue to carry forward the company’s entrepreneurial spirit of “innovative operation and daring to be pioneering” adhere to the business philosophy of “quality heating, creating harmony, serving the people, and contributing to society”, strengthen management, provide enthusiastic service, focus our energy, and create a new situation in the heating business in Jiutai District.</div>
            <div class="sdiwq">Quality heating, creating harmony, serving the people, and contributing to society</div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            data:{
                type:2
            },
        }
    },
}
</script>

<style scoped>

    .top-img1{
        background: url('@/assets/zx-19.jpg') top center  no-repeat;
        overflow: hidden;
        padding-top: 280px;
        padding-bottom: 60px;
    }
    .zc-text{
        width: 1145px;
        margin: 0 auto;
        overflow: hidden;
    }
    .zc-text{
        color: #fff;
        margin-top: 40px;
    }
    .zc-text p{
        width: 630px;
        float: left;
        margin-top: 14px;
    }
    .zc-text img{
        float: right;
        margin: 26px;
        border: 3px #eee solid;
        border-radius: 5px;
        margin-right: 0;
        width: 470px;
    }
    .top-img1 h1{
        color: #fff;
        text-align: center;
        font-size: 32px;
        letter-spacing: 1px;
    }
    .litext span{
        display: block;
        text-indent: 2em;
        margin-top: 80px;
    }
    .litext,.sdiwq{
        padding-top: 1%;
        font-size: 22px;
        line-height: 40px;
        text-align: justify;
        color: #fff;
        width: 80%;
        margin: 0 auto;
    }
    .sdiwq{
        width: 1145px;
        text-indent: 0;
    }
    @media (max-width: 768px){
        .top-img{
            background-size: 100%;
            height: 60px;
            display: none;
        }
        .top-img1{
            width: 100%;
            background: url('@/assets/zx-21.jpg') top center  no-repeat;
            background-size: 100%;
            overflow: hidden;
            padding-bottom: 80px;
            padding-top: 20px;
        }
        .zc-text img{
            width: 80%;
            margin: 0 auto;
            height: auto;
            float: none;
            display: block;
        }
        .zc-text{
            width: 84%;
            margin-top: 20px;
        }
        .zc-text p{
            width: 100%;
        }
        .top-img1 h1{
            margin-top: 60px;
            font-size: 24px;
        }
        .litext,.sdiwq{
            font-size: 14px;
            line-height: 26px;
            
        }
        .litext span{
            margin-top: 10px;
        }
        .sdiwq{
            width: 84%;
            text-indent: 2em;
            margin-top: 20px;
        }
    }
</style>