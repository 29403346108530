<template>
    <div>
       <div class="Servic ">
            <p>{{this.$route.query.title?this.$route.query.title:'提取浓缩'}}</p>
            <span>—— 主要装置实例图片 ——</span>
        </div>
        <Tabulation :data='CaseTp.data' @Tabulaid='Tabulaid'/>
               <div class="Servic ">
            <p>{{this.$route.query.title?this.$route.query.title:'提取浓缩'}}</p>
            <span>—— 相关案例图片 ——</span>
        </div>
        <ul class="nrk work">
            <li v-for="item in Cases.data" :key="item.id" @click="List(item.id)">
                <div class="img-nrk">
                <img :src="Http+item.src">
                </div>
                <span>
                    {{item.title}}
                </span>
            </li>
        </ul>
    </div>
</template>

<script>
import{mapState} from'vuex';
export default {
    data() {
        return {
            data:{
                page:1,
                limit:10,
                cid:1,
            }
        }
    },
    mounted() {
        if(this.$route.query.id){
            this.data.cid=this.$route.query.id
        }
        //获取banner
        this.$store.dispatch('PostCases',this.data);
        this.$store.dispatch('PostCaseTp',this.data);
    },
    computed:{
        ...mapState({
            Cases:state=>state.share.Cases,
            CaseTp:state=>state.share.CaseTp,
            MemberCt:state=>state.share.MemberCt,
            Http:state=>state.share.Http
        })
    },
    methods: {
        Tabulaid(id){
                this.$router.push({
                    name:'Details',
                        query:{
                            id:id,
                            title:2,
                        }
                })
        },
        List(id){
            this.$router.push({
                name:'Details',
                    query:{
                        id:id,
                        title:1,
                    }
            })
        }
    },
}
</script>

<style scoped>
    .work{
        overflow: hidden;
        margin: 20px auto;
        padding: 2% 2%;
    }
    .work li{
        width: 32%;
        float: left;
        margin-right: 2%;
        cursor: pointer;
        box-shadow: 0px 3px 3px #d9d9d9;
        border-radius: 10px;
        overflow: hidden;
        position: relative;
        overflow: hidden;
        max-height: 400px;
        margin-bottom: 40px;
    }
    .work li:nth-child(3n){
        margin-right: 0;
    }
    .work li .img-nrk{
        width: 100%;
        height: 300px;
        overflow: hidden; 
    }
    .work li .img-nrk img{
        width: 100%;
        margin: 0 auto;
        border-radius: 10px;
        height: 300px;    
    }
    .work li:hover img {
    transform: scale(1.1);
    transition: all 0.5s ease; 
    }
    .work li span{
        text-align: center;
        display: block;
        margin: 34px 0;
        font-size: 18px;
    }
    .work li:hover{
        color: #0019a7;
    }
    @media (max-width: 768px){
        .work li{
            width: 100%;
        }
        .work li .nmkimg,.work li img{
            height: auto;
        }
    }
</style>