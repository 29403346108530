//vuex模块的小仓库
//仓库储存数据
import {NtInfo,Nt,Stylist,reqBanner,Banner1,About,News,NewsInfo,Cases,Cate,CaseTp,CasesInfo,CaseOther,CaseTpInfo,Exchange,ExchangeInfo,Pj,PjInfo,OtherPj,Honor,HonorInfo,Performance,PerformanceInfo,Main,Link,MemberCt,Member,MemberInfo} from '@/api'
const state={
    //网址
    nids:1,
    Http:'https://ccjtrx.com',
    // PC首页导航   
    navigation:[
        {
            url:'/Pc/home',
            name:'首页', 
        },
        {
            url:'/Pc/About',
            name:'公司概况',
            son:[
                {url:'Company',name:'公司简介'},
                {url:'Servic',name:'总经理致辞'},
                {url:'System',name:'企业文化'},
                {url:'Idea',name:'资质荣誉'},
                {url:'Organization',name:'组织架构'},
            ]
        },
        {
            url:'/Pc/PcNews',
            name:'新闻资讯',
            son:[
                {url:'NewsNotice',name:'通知公告'},
                {url:'Notice',name:'公司动态'},
                {url:'NewsOne',name:'行业资讯'},
            ]
        },
        {
            url:'/Pc/Technology',
            name:'供热服务',
            son:[
                {url:'Patent',name:'服务承诺'},
                {url:'Proprietary',name:'供热常识'},
                {url:'Designer',name:'供热范围'},
                {url:'DesignerReport',name:'违章用热举报'},
            ]
        },
        {
            url:'/Pc/Project',
            name:'政策法规',
            son:[]
        },
        {
            url:'/Pc/ContactUs',
            name:'联系我们',
        },
    ],
    floatdada:[
        {url:'Detection',name:'社会荣誉',english:'Route guidance',src:'fils-1.png'},
        {url:'FloEngineering',name:'检测中心',english:'Case search',src:'fils-2.png'},
        {url:'FloHonor',name:'工程监理',english:'Hospital navigation',src:'fils-3.png'},
        {url:'Recognize',name:'认证咨询',english:'Online registration',src:'fils-4.png'},
    ],
    Englishnavigation:[
        {url:'EnglishContact',name:'Company Profile'},
        {url:'EnglishAddress',name:'President Speech'},
        {url:'EnglishNews',name:'Contact Us'},
    ],
    //首页banner
    banner:[],
    //新闻理念
    study:[
        {name:'资质情况',text:'吉林医药设计院具有国家住房和城乡建设部批准的化工、石化、医药行业甲级设计资质；国家发展和改革委员会批准的甲级工程咨询资质；压力容器和压力管道设计资质。' },
        {name:'管理体系',text:'吉林医药设计院有限公司通过了质量体管理体系、环境管理体系和职业健康安全管理体系认证，是国内为数不多通过QEHS三体系认证的医药工程设计单位之一。' },
        {name:'专业齐全',text:'吉林医药设计院设有医药工艺、食品工艺、化妆品工艺、设备、管道、建筑、结构、采暖、空调净化、电气、自控、给排水、技术经济、总图运输等专业处室。可以 承担大中小型规模的生化生物药、化学原料药、中成药、药物制剂、医疗器械以及药品内包材等类型的医药工程项目和食品工程、日用化工工程、生物安全实验室、 洁净手术室、 工程总承包、项目管理、工程监理、药品认证咨询及空调净化环境检测等服务项目。是国家制药机械标准委员会委员单位。' },
        {name:'装备先进',text:'吉林医药设计院设有局域网工作站、绘图机、晒图机、复印机；设计软件有各专业的CAD设计软件、三维设计软件、压力管道和压力容器设计计算软件' },
        {name:'工程业绩',text:'吉林医药设计院经过多年千余个设计项目的积淀，现已经成为全国医药设计行业的主流设计院，设计项目遍布全国各个省市自治区，修正药业集团、敖东药业、东宝药业、金马药业、哈药集团、双鹤药业、北京紫竹药业、葵花药业集团、山西亚宝、西安万隆药业、江苏康泰药业、贵州信邦药业、无限极集团、大连百利、大连美罗等国内知名企业与我院建立了战略合作伙伴关系。' },
        {name:'技术专长',text:'吉林医药设计院在中药提取、注射剂、生物制剂及药用制剂等类型的设计经验丰富，特色独到。可以做到在保证符合GMP认证的基础上，充分考虑企业的管理需要 ，优化生产工艺，节省一次性投资，降低运行成本，让每一项设计成品都能准确地反映用户设定的美好蓝图！' },
        {name:'技术成果',text:'吉林医药院设计院的技术水平和产品质量得到客户的赞誉和国家相关管理机构的认可，建院以来多次获得国家和省部级优秀设计项目奖。' }
       
    ],
    studyEnglish:[
        {name:'Qualifications',text:'Our institute boasts Class-A design qualification for chemical, petrochemical, pharmaceutical industry approved by the State Ministry of Housing and Urban Rural Development，Class-A  engineering consulting qualification approved by National Development and Reform Commission， qualification for pressure vessel and pipeline design.' },
        {name:'Management System',text:'Jilin Pharmaceutical Design Institute Co., Ltd. has passed the certification of quality management system, environmental management system, and occupational health and safety management system, and is one of the few pharmaceutical engineering design units in China that have passed the QEHS three system certification.' },
        {name:'Specialities',text:'We have sections for medicine technology, food processing, cosmetics technology, equipment, pipeline, construction, structure, heating, air conditioning and purification, electrical, automation, water supply and drainage, technology and economy, transportation and the like. We can undertake large, middle and small-scale pharmaceutical engineering projects, including biochemical medicine, chemical raw material medicine, Chinese medicine, pharmaceutical preparations, medical devices and pharmaceutical packaging material and services for food engineering, daily chemical engineering, biological safety laboratory, clean operation room, engineering general contracting, project management, project supervision, drug certification consulting and air-conditioning purification environmental testing. Our institute is one of the members of the National Pharmaceutical Machinery Standard Committee.' },
        {name:'Advanced Equipment',text:'Jilin Medical Design Institute has a LAN workstation, drawing machine, printer, and copier; Design software includes various professional CAD design software, 3D design software, pressure pipeline and pressure vessel design calculation software' },
        {name:'Engineering Performance',text:'After years of accumulation of over a thousand design projects, Jilin Pharmaceutical Design Institute has become the mainstream design institute in the national pharmaceutical design industry. The design projects are spread throughout various provinces, cities, and autonomous regions across the country, including Revised Pharmaceutical Group, Aodong Pharmaceutical, Dongbao Pharmaceutical, Jinma Pharmaceutical, Harbin Pharmaceutical Group, Shuanghe Pharmaceutical, Beijing Zizhu Pharmaceutical, Sunflower Pharmaceutical Group, Shanxi Yabao, Xian Wanlong Pharmaceutical, Jiangsu Kangtai Pharmaceutical, Guizhou Xinbang Pharmaceutical Famous domestic enterprises such as Infinite Group, Dalian Baili, and Dalian Meiluo have established strategic partnerships with our institute.' },
        {name:'Technical Expertise',text:'Jilin Medical Design Institute has rich experience in the design of traditional Chinese medicine extraction, injections, biological preparations, and medicinal preparations, with unique characteristics. On the basis of ensuring compliance with GMP certification, we can fully consider the management needs of the enterprise, optimize production processes, save one-time investment, reduce operating costs, and ensure that every design product accurately reflects the beautiful blueprint set by the user!' },
        {name:'Technological Achievements',text:'The technical level and product quality of the design institute of Jilin Medical Institute have been praised by customers and recognized by relevant national management institutions. Since its establishment, the institute has won multiple national and provincial level excellent design project awards.' }
       
    ],
    NewsNav:[
        {url:'/English/EnglishHome',name:'Home'},
        {url:'/English/EnglishBlurb',name:'Company Introduction'},
        {url:'/English/EnglishNews',name:'General Manager Speech'},
        {url:'/English/EnglishContact',name:'Company culture'},
        {url:'/English/EnglishHonor',name:'Organization structure'},
        {url:'/English/EnglishOrganization',name:'Qualifications and honors'},
        {url:'/English/EnglishContactUs',name:'Contact us'}
    ],
    Banner1:[],
    About:{},
    News:[],
    NewsTrends:[],
    NewsLaw:[],
    Notice:[],
    NewsInfo:{},
    Cases:{},
    Cate:{},
    CaseTp:{},
    CasesInfo:{},
    CaseTpInfo:{},
    CaseTpInfodata:{},
    Exchange:[],
    ExchangeInfo:{},
    Pj:[],
    PjInfo:{},
    OtherPj:{},
    CaseOther:{},
    Honor:[],
    Honorother:[],
    HonorInfo:{},
    Performance:[],
    Performancether:[],
    PerformanceInfo:{},
    Main:{},
    Link:[],
    MemberCt:{},
    Member:{},
    MemberInfo:{},
    Stylist:[],
    Nt:[],
    NtInfo:[]
};
//修改state唯一手段
const mutations={
    NT(state,data){
        state.Nt=data;
    },
    NTINFO(state,data){
        state.NtInfo=data;
    },
    BANNER(state,data){
        state.banner=data;
    },
    BANNER1(state,data){
        state.Banner1=data;
    },
    BANNER(state,data){
        state.banner=data;
    },
    ABOUT(state,data){
        state.About=data
    },
    NEWS(state,{data,type}){
        console.log(type)
        if(type===1){
            state.Notice=data;
        }else{
            state.News=data;
        }
    },
    NEWSINFO(state,data){
        state.NewsInfo=data
    },
    CATE(state,data){
        state.Cate=data
    },
    CASES(state,data){
        state.Cases=data
    },
    CASETP(state,data){
        state.CaseTp=data
    },
    CASESINFO(state,data){
        state.CasesInfo=data;
        
    },
    CASETPINFO(state,data){
        state.CaseTpInfodata=data;
    },
    EXCHANGE(state,data){
        state.Exchange=data;
    },
    EXCHANGEINFO(state,data){
        state.ExchangeInfo=data;
    },
    PJ(state,data){
        state.Pj=data;
    },
    PJINFO(state,data){
        state.PjInfo=data;
    },
    OTHERPj(state,data){
        state.OtherPj=data;
    },
    CASEOTHER(state,data){
        state.CaseOther=data;
    },
    HONOR(state,{data,type}){
        if(type===1){
            state.Honor=data;
        }else{
            state.Honorother=data;
        }
    },
    HONORINFO(state,data){
        state.HonorInfo=data;
    },
    PERFORMANCE(state,{data,type}){
        state.Performance=data;
    },
    PERFORMANCEINFO(state,data){
        state.PerformanceInfo=data;
    },
    MAIN(state,data){
        state.Main=data;
    },
    LINK(state,data){
        state.Link=data;
    },
    MEMBERCT(state,data){
        state.navigation[4].son=data;
        console.log(state.navigation[4])
    },
    MEMBER(state,data){
        console.log(data)
        state.Member=data.data.info;
    },
    MEMBERINFO(state,data){
        state.MemberInfo=data;
    },
    STYLIST(state,data){
        state.Stylist=data;
    },
    NIDS(state,data){
        state.nids=data;
    }
};
let limit=10;
let page=1;
//处理actions,可以写自己的业务逻辑,也可以异步
const actions={
    //首页banner 
    async shareBbanner({commit}){
        let result= await reqBanner();
        if(result.state==200){
            commit("BANNER",result.data)
        }
    },
    //首页banner1
    async PostBanner1({commit}){
        let result= await Banner1();
        if(result.state==200){
            commit("BANNER1",result.data)
        }
    },
    //公司概况
    async PostAbout({commit},params={}){
        let result= await About(params);
        if(result.state==200){
            commit("ABOUT",result.data)
        }
    },
    //新闻列表
    async PostNews({commit},params={limit:limit,page:page}){
        let result= await News(params);
        if(result.state==200){
            console.log(result)
            commit("NEWS",{data:result.data,type:params.cid})
        }
    },
    //通知公告
    async PostNt({commit},params={limit:limit,page:page}){
        let result= await Nt(params);
        if(result.state==200){
            console.log(result)
            commit("NT",result.data)
        }
    },
    //通知公告-详情
    async PostNtInfo({commit},params={limit:limit,page:page}){
        let result= await NtInfo(params);
        if(result.state==200){
            console.log(result)
            commit("NTINFO",result.data)
        }
    },
    //新闻列表-详情
    async PostNewsInfo({commit},params={limit:limit,page:page}){
        let result= await NewsInfo(params);
        if(result.state==200){
            commit("NEWSINFO",result.data)
        }
    },
    //典型列表
    async PostCate({commit},params={limit:limit,page:page,cid:1}){
        let result= await Cate(params);
        if(result.state==200){
            commit("CATE",result)
        }
    },
    //典型列表
    async PostCases({commit},params={limit:limit,page:page,cid:1}){
        let result= await Cases(params);
        if(result.state==200){
            commit("CASES",result)
        }
    },
    //装置列表
    async PostCaseTp({commit},params={limit:limit,page:page,cid:1}){
        let result= await CaseTp(params);
        if(result.state==200){
            commit("CASETP",result)
        }
    },
    //典型列表-详情
    async PostCasesInfo({commit},params={}){
        let result= await CasesInfo(params);
        if(result.state==200){
            commit("CASESINFO",result.data)
        }
    },
    //装置列表-详情
    async PostCaseTpInfo({commit},params={}){
        let result= await CaseTpInfo(params);
        if(result.state==200){
            commit("CASETPINFO",result.data)
        }
    },
    //装置列表-详情
    async PostCaseOther({commit},params={}){
        let result= await CaseOther(params);
        if(result.state==200){
            commit("CASEOTHER",result.data)
        }
    },
    //对外交流
    async PostExchange({commit},params={}){
        let result= await Exchange(params);
        if(result.state==200){
            commit("EXCHANGE",result.data)
        }
    },
    //对外交流-详情
    async PostExchangeInfo({commit},params={}){
        let result= await ExchangeInfo(params);
        if(result.state==200){
            commit("EXCHANGEINFO",result.data)
        }
    },
    //涉外项目
    async PostPj({commit},params={}){
        let result= await Pj(params);
        if(result.state==200){
            commit("PJ",result.data)
        }
    },
    //涉外项目-详情
    async PostPjInfo({commit},params={}){
        let result= await PjInfo(params);
        if(result.state==200){
            commit("PJINFO",result.data)
        }
    },
    //涉外项目-其他
    async PostOtherPj({commit},params={}){
        let result= await OtherPj(params);
        if(result.state==200){
            commit("OTHERPj",result.data)
        }
    },
    //社会荣誉
    async PostHonor({commit},params={}){
        let result= await Honor(params);
        if(result.state==200){
            commit("HONOR",{data:result.data,type:params.type})
        }
    },
    //社会荣誉-详情
    async PostHonorInfo({commit},params={}){
        let result= await HonorInfo(params);
        if(result.state==200){
            commit("HONORINFO",result.data)
        }
    },
    //获奖项目
    async PostPerformance({commit},params={}){
        let result= await Performance(params);
        if(result.state==200){
            commit("PERFORMANCE",{data:result.data.info,type:params.type})
        }
    },
    //获奖项目-详情
    async PostPerformanceInfo({commit},params={}){
        let result= await PerformanceInfo(params);
        if(result.state==200){
            commit("PERFORMANCEINFO",result.data)
        }
    },
    //友情链接
    async PostLink({commit},params={}){
        let result= await Link(params);
        if(result.state==200){
            commit("LINK",result.data)
        }
    },
    //政策分类
    async PostMemberCt({commit},params={}){
        let result= await MemberCt(params);
        if(result.state==200){
            commit("MEMBERCT",result.data)
        }
    },
    //政策列表
    async PostMember({commit},params={}){
        let result= await Member(params);
        if(result.state==200){
            commit("MEMBER",result)
        }
    },
    //政策详情
    async PostMemberInfo({commit},params={}){
        let result= await MemberInfo(params);
        if(result.state==200){
            commit("MEMBERINFO",result.data.info)
        }
    },
    //联系我们
    async PostMain({commit},params={}){
        let result= await Main(params);
        if(result.state==200){
            commit("MAIN",result.data)
        }
    },
    //设计师风采
    async PostStylist({commit},params={}){
        let result= await Stylist(params);
        if(result.state==200){
            commit("STYLIST",result)
        }
    },
};
//计算属性,用于简化仓库数据,让组件获取仓库的数据更方便
const getters={
    
};
export default{
    state,
    mutations,
    actions,
    getters
}