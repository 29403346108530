<template>
    <div class="yans">
        <div class="nrk bottom-btn">
            <div class="title">
                <p>
                    <i>服务承诺</i>
                </p>
                <span>Service commitment</span>
            </div>
            <div class="gongr">
                <div class="Servic">
                    <p>供热服务承诺书</p>
                </div>
                <div class="gongr-rq">
                    <h1>8月</h1>
                    <span>2023年</span>
                </div>
                <div class="litext" v-html="About[5].content"></div>
            </div>
        </div>
    </div>
</template>

<script>
import {mapState} from "vuex"
export default {
    data() {
        return {
            data:{
                type:6
            },
        }
    },
    mounted() {
        this.$store.dispatch('PostAbout',this.data);
    },
    computed:{
		...mapState({
			study:state=>state.share.study,
            About:state=>state.share.About
		})
        
	},
}
</script>

<style scoped>
    .litext{
        width: 80%;
        color: #666666;
        font-size: 16px;
        line-height: 32px;
        margin: 0 auto;
    }
    .litext p{
        margin-bottom: 16px;
    }
    .mobile-display{
        display: none;
    }
    .gongr-rq{
        width: 100px;
        height: 97px;
        background-color: #f4f4f4;
        text-align: center;
        color: #0019a7;
        overflow: hidden;
    }
    .gongr-rq h1{
        font-size: 30px;
        margin-top: 16px;
    }
    .gongr-rq span{
        font-size: 16px;
        margin-top: 6px;
    }
    .gongr{
        width: 94%;
        padding: 3%;
        background-color: #fff;
        overflow: hidden;
    }
    .yans{
        background-color: #f7f7f7;
        overflow: hidden;
    }
     @media (max-width: 768px){
        .litext{
            width: 100%;
        }
        .Servic p{
            font-size: 18px;
            padding: 18px 0;
            display: block;
            font-weight: bold;
        }
        .mobile-display{
            display: block;
        }
        .pc-display{
            display: none;
        }
    }
</style>