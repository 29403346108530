<template>
    <div class="nrk">
        <div class="Servic ">
            <p>主要业绩</p>
        </div>
        <div class="achie">
            <div v-html="CaseOther.content"></div>
        </div>
    </div>
</template>

<script>
import{mapState} from'vuex';
export default {
    mounted() {
        this.$store.dispatch('PostCaseOther');
    },
    computed:{
        ...mapState({
            CaseOther:state=>state.share.CaseOther,
        })
    },
}
</script>

<style >
    .achie{
        width: 100%;
        max-height: 700px;
        overflow: auto;
        background: url('@/assets/zx-9.jpg')no-repeat bottom right;
        background-size: 70%;
        margin-bottom: 40px;

    }
    .achie p{
        margin-bottom: 20px;
        font-size:24px;
    }
    .achie p span{
        margin-bottom: 20px;
        font-size:18px !important;
    }
    @media (max-width: 768px){
        .achie{
            height: auto;
        }
    }
</style>