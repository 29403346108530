<template>
    <div class="paging">
	    <button :disabled='pageNo===1' @click="$emit('getPageNo',pageNo-1)">上一页</button>
        <button 
        v-show="startEndNum.start>1" 
        @click="$emit('getPageNo',1)"
        :class="{activue:pageNo===1}"
        >
            1
        </button>
        <button v-show="startEndNum.start>2">...</button>
        <button 
        v-for="(item,index) in startEndNum.end" 
        :key="index" 
        v-show="item>=startEndNum.start"
        @click="$emit('getPageNo',item)"
        :class="{activue:pageNo===item}"
        >
            {{item}}
        </button>
        <button v-show="startEndNum.end<totalPage-1">...</button>
        <button 
        v-show="startEndNum.end<totalPage" 
        @click="$emit('getPageNo',totalPage)"
        :class="{activue:pageNo===totalPage}"
        >
        {{totalPage}}
        </button>
	    <button :disabled='pageNo===totalPage' @click="$emit('getPageNo',pageNo+1)">下一页</button>
        <button >共{{total}}条</button>
	</div>
</template>

<script>
export default {
    name:'Pagination',
    props:[
    'pageNo',       //当前第几页
    'pageSize',     //代表每一页展示多少条数据
    'total',        //代表整个分页一共要展示多少条数据
    'continues'     //代表分页连续页码个数
    ],
    computed:{
        //总共多少页
        totalPage(){
            //向上取整
            return Math.ceil(this.total/this.pageSize)
        },
        startEndNum(){
            const{continues,pageNo,totalPage}=this;
            //计算出连续的页码起始数字与结束数字
            let start=0,end=0
            //连续页面数字5【至少5页】
            if(continues>totalPage){
                    start=1;
                    end=totalPage;
            }else{
                //起始数字
                start=pageNo-parseInt(continues/2);
                //结束数字
                end=pageNo+parseInt(continues/2);
                // 把出现不正常的现象【start数字出现0丨负数】纠正
                if(start<1){
                    start=1;
                    end=continues;
                }
                //把出现不正常的现象【end数字大于总页面】纠正
                if(end>totalPage){
                    end=totalPage;
                    start=totalPage-continues+1 
                }
            }
            return {start,end}
        }
    }
}
</script>

<style scoped>
    .paging {
        width: 100%;
        margin: 30px 0;
        display: flex;
        justify-content: center;
    }
    .paging {
        display: flex;
        padding-left: 0;
        list-style: none;
    }
    .paging button{
        position: relative;
        display: block;
        color: #0d6efd;
        text-decoration: none;
        background-color: #fff;
        border: 1px solid #dee2e6;
        transition: color .15s ease-in-out, 
        background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
        padding: 0.375rem 0.75rem;
        color: #444;
        cursor: pointer;
    }

    .paging .activue {
        background-color: #0019a7;
        color: #fff;
    }
    .paging button:not(:first-child) .page-link {
        margin-left: -1px;
    }
    a:hover{
        text-decoration: none;
    }
        /* .paging button:hover {
            z-index: 2;
            color: #0a58ca;
            background-color: #e9ecef;
            border-color: #dee2e6;
            display: block;
        } */
    button:disabled {
    background-color: #ddd;
    cursor: not-allowed;
    color: #999 ;
    }
</style>