//对于axios进行二次封装
import axios from 'axios';
//引入进度条
import nprogress from 'nprogress';
//进度条样式
import "nprogress/nprogress.css"
let token = 'A3L2E6vRBQHiQ7u7n';
const requests=axios.create({
    //配置对象
    //基础路径,发送请求的时候,路径当中会出现API
    baseURL:"/index",
    headers: {
        'TOKEN':token,
      },
    //请求超时的时间5s
    timeout:5000,
})
//请求拦截器: 在发请求之前,请求拦截器可以检查到,可以在请求发出去之前做一些事情
requests.interceptors.request.use((config)=>{
    //config:配置对象,对象里面有一个属性很重要,headers请求头
    nprogress.start();
    return config
})
//相应拦截器
requests.interceptors.response.use((res)=>{
    nprogress.done();
    return res.data
},(error)=>{
    //相应失败的回调函数
    return Promise.reject(new Error('faile'))
})
//对外暴露 
export default requests

