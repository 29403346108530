<template>
  <div id="container"></div>
</template>
<script>
export default {
    meta:'BaiduMap',
    mounted() {
        var map = new BMapGL.Map("container");
		// var point = new BMapGL.Point(116.404, 39.915);
		var point = new BMapGL.Point(125.319895,43.875135);
		map.centerAndZoom(point, 19);
		map.enableScrollWheelZoom(true);
		var scaleCtrl = new BMapGL.ScaleControl();  // 添加比例尺控件
		map.enableDragging(true);    //可拖拽
		map.addControl(scaleCtrl);
		var zoomCtrl = new BMapGL.ZoomControl();  // 添加缩放控件
		map.addControl(zoomCtrl);
		var cityCtrl = new BMapGL.CityListControl();  // 添加城市列表控件
		map.addControl(cityCtrl);
    },
}
</script>

<style scoped>
    #container {
        width: 100%;
        height: 400px;
    }
</style>