<template>
    <div>
        <img src="img/banner-0.jpg" class="banone pc">
        <img src="img/web-baner1.jpg" class="banone mobile">
        <div class="title">
            <p>
                <i>QUALIFICATIONS AND HONORS</i>
            </p>
        </div>
        <div class="ldea nrk">
            <div class="Servic nrk" v-for="item in News.data" :key="item.id">
                <img :src="Http+item.src" class="beij">
                <span>{{item.name}}</span>
            </div>
        </div>
        <!-- <Pagination 
        :pageNo="naml.page" 
        :pageSize='naml.limit' 
        :total="News.total" 
        :continues="5"
        @getPageNo='getPageNo'
        /> -->
    </div>
</template>

<script>
import {mapState} from 'vuex'
export default {
    data() {
        return {
            naml:{
            limit:8,
            page:1,
            },
        }
    },
    activated() {
        this.postNews(this.naml)
    },
    computed:{
        ...mapState({
            News:state=>state.share.Cate,
            Http:state=>state.share.Http
        })
    },
    methods: {
        getPageNo(id){
            this.naml.page=id;
            this.postNews(this.naml)
        },
        postNews(naml){
            this.$store.dispatch('PostCate',naml);
        }
    },
}
</script>

<style>
    .ldea{
        overflow: hidden;
    }
    .ldea img{
        height: 230px;
        width: 320px;
    }
    .ldea>div{
        width: 33%;
        float: left;
        overflow: hidden;
    }
    .ldea span{
        display: block;
        height: 60px;
        margin-top: 20px;
    }
    @media (max-width: 768px){
        .ldea img{
            width: 90%;
            margin-top: 16px;
        }
        .ldea>div{
            width: 100%;
        }
    }
</style>