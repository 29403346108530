<template>
    <!--公司简介-->
    <div class="course nark">
        <div class="course-nr">
            <ul class="course-text">
                <li>
                    <div><i>{{figure1}}</i><p>+</p></div>
                    <span class="wow animate__animated animate__bounce">25年发展历程</span>
                </li>
                <li>
                    <div><i>{{figure2}}</i><p>+</p></div>
                    <span class="wow animate__animated animate__bounce" data-wow-delay="0.3s">40多项奖项</span>
                </li>
                <li>
                    <div><i>{{figure3}}</i><p>+</p></div>
                    <span class="wow animate__animated animate__bounce" data-wow-delay="0.6s">1000余家优质客户</span>
                </li>
                <li>
                    <div><p>+</p><i>{{figure4}}</i></div>
                    <span class="wow animate__animated animate__bounce" data-wow-delay="0.9s">4700多个项目</span>
                </li>   
            </ul>
            <div class="course-bottom-text  wow animate__animated animate__fadeInUp">吉林医药设计院有限公司（以下简称吉林医药设计院）创建于1986年，民营企业,中国医药工程设计协会副会长单位吉林省勘察设计行业AAA级信用企业。拥有一批经验丰富的优秀工程设计人员，包括设计大师和获政府津贴的专家执业注册人员及正、副高级职称人员，各类工程技术人员占员工总数的90%以上。</div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            figure1: 1,
            figure2: 5,
            figure3: 800,
            figure4: 4400,
            faiked: false,
            liedn: 0,
            linq: 0
        }
    },
    mounted() {
        window.addEventListener("scroll", this.handleScroll);
    },
    methods: {
        handleScroll() {
            let top = Math.floor(document.documentElement.scrollTop || document.body.scrollTop);
            if (top > 2800 && this.faiked == false) {
                this.mountq();
                this.faiked = true
            }
        },
        mountq() {
            this.lia = setInterval(() => {
                this.figure1 < 37 ? this.figure1++ : clearInterval(this.lia);
                if (this.opacity <= 0) this.opacity = 1
            }, 40)
            this.lil = setInterval(() => {
                this.figure2 < 40 ? this.figure2++ : clearInterval(this.lil);
                if (this.opacity <= 0) this.opacity = 1
            }, 40)
            this.lid = setInterval(() => {
                this.figure3 < 1000 ? this.figure3++ : clearInterval(this.lid);
                if (this.opacity <= 0) this.opacity = 1
            },5)
            this.lidsq = setInterval(() => {
                this.figure4 < 4700 ? this.figure4++ : clearInterval(this.lidsq);
                if (this.opacity <= 0) this.opacity = 1
            }, 0.1)
        },
    }
}
</script>

<style scoped>
    .course {
        background: url(./img/beij_2.jpg) no-repeat center center;
        overflow: hidden;
        margin-top:5%;
    }

    .course-nr {
        width: 80%;
        margin: 0 auto;
        margin-top: 150px;
    }

    .course-text {
        width: 100%;
        overflow: hidden;
    }

    .course-text li {
        float: left;
        width: 25%;
    }
    .course-text li:nth-child(2){
        text-align: center;
    }
    .course-text li:nth-child(2) i{
        margin-left:120px;
    }
    .course-text li:nth-child(3){
        text-align: center;
    }
    .course-text li:nth-child(3) i{
        margin-left:60px;
    }
    .course-text li:last-child{
        text-align: right;
    }
    .course-text li:last-child span{
        text-align: right;
        margin-right: 37px;
    }
    .course-text li:last-child i{
        float: right;
    }
    .course-text li:last-child p{
        float: right;
    }
    .course-text li>div{
        font-size: 76px;
        color: #fff;
        font-weight: bold;
        overflow: hidden;
    }

    .course-text li p {
        font-size: 50px;
        float: left;
    }

    .course-text span {
        font-size: 28px;
        margin-top: 30px;
        color: #fff;
        display: block;
    }

    .course-text li>div i {
        float: left;
    }
    .course-bottom-text {
        font-size: 26px;
        margin-top: 70px;
        color: #fff;
        text-align: justify;
        line-height: 50px;
        margin-bottom: 200px;
    }
    @media (max-width: 768px){
        .course-bottom-text {
            font-size: 14px;
            margin-top: 4px;
            line-height:26px;
            margin-bottom: 20px;
        }
        .course-text span{
            font-size: 14px;
            text-align: center;
            margin-top: 12px;
        }
        .course-text li{
            width: 100%;
            margin-bottom: 20px;
        }
        .course-text li:nth-child(2) div,.course-text li:nth-child(3) div{
            margin-left: 0;
        }
        .course-text li>div{
            font-size: 40px;
            display: flex;
            justify-content: center;
        }
        .course-text li:nth-child(2) i{
            margin-left: 16px;
        }
        .course-text li p{
            font-size: 24px;
        }
        .course-text li:nth-child(3) i{
            margin-left: 12px;
        }
        .course-nr{
            margin-top: 40px;
        }
    }
</style>