<template>
    <div>
        <div class="Servic hs">
            <p>设计师风采</p>
        </div>
        <ul class="nrk work">
            <li>
                <div class="lisf">
                    <div class="nmkimg">
                        <img v-lazy="Http+name.logo">
                    </div>
                </div>
                <div class="liss">
                    <span>{{name.name}}</span>
                    <div class="text" v-html="ursl(name.content)"></div>
                    <div class="gens" @click="back">更多设计师</div>
                </div>
            </li>
        </ul>
    </div>
</template>

<script>
 import{mapState} from'vuex';
export default {
    data() {
        return {
            name:{}
        }
    },
    mounted() {
        this.name=JSON.parse(localStorage.getItem('Lawyer'));
    },
    computed:{
        ...mapState({
            Stylist:state=>state.share.Stylist,
            Http:state=>state.share.Http,
        })
    },
     methods: {
        ursl(content=''){
            if(content!=''){
                let noticeContent = content;
                let imgReg = RegExp(/<img [^>]*src=['"]([^'"]+)[^>]*>/gi);//定义正则，筛选出img元素
                let matchres=noticeContent.match(imgReg);
                if(matchres){
                matchres.forEach((item,index)=>{
                    let _tempStr = item.slice(0,item.length-2);
                    let _index = _tempStr.indexOf('/');
                    let _str= _tempStr.substring(0, _index) + this.Http +  _tempStr.substring(_index, _tempStr.length) + '"/>';
                    noticeContent=noticeContent.replace(item,_str)
                })
                }
                return noticeContent
            }
        },
        back(){
            this.$router.back()
        }
    },
}
</script>

<style scoped>
    .work{
        overflow: hidden;
        margin: 20px auto;
        padding: 1%;
    }
    .work li{
        width: 92%;
        padding: 2% 2%;
        float: left;
        background-color: #eee;
        margin-right: 2.4%;
        cursor: pointer;
        margin-bottom: 30px;
        overflow: hidden;
        box-shadow: 0px 0px 8px #d9d9d9;

    }
    .work li:nth-child(5n){
        margin-right: 0;
    }
    .work li .nmkimg{
        overflow: hidden;
    }
    .work li img{
        width: 100%;
        margin: 0 auto;
        display: block;
    }

    /* .work li span{
        text-align: center;
        display: block;
        margin: 20px 0;
        font-size: 18px;
        color: #164db2;
    } */
    .lisf{
        float: left;
        width: 20%;
    }
    .liss{
        float: right;
        width: 74%;
        padding: 2% 0;
    }
    .liss span{
        color: #333;
        font-size: 24px;
        font-weight: bold;
    }
    .text{
        padding: 2%;
        text-align: justify;
        min-height: 120px;
    }
    .gens{
        border: #333 1px solid;
        width: 120px;
        height: 36px;
        text-align: center;
        line-height: 36px;
        border-radius: 10px;
        margin: 0 auto;
    }
    @media (max-width: 768px){
        .work li{
            width: 100%;
        }
        .work li .nmkimg,.work li img{
            height: auto;
        }
    }
</style>