<template>
    <!--通知公告-->
    <section class="otice">
        <div class="oticeFrame nrk">
            <img src="./img/oticeIcon.jpg" class="oticeIcon">
            <div class="oticeNews" id="marquee2">
                <vue-seamless-scroll
                 class="warp"
                :data="Noticep.data"
                :class-option="defaultOption"
                >  
                <ul>
                    <li v-for="item in Noticep.data" :key="item.id" @click="Details(item.id)">
                        <a>· {{item.title}} 
                            <!-- <span class="risj">[{{item.push_time}}]</span> -->
                        </a>
                    </li>
                </ul>
                </vue-seamless-scroll>
            </div>
            <img src="./img/oticeIcon1.jpg" class="oticeIcons">
        </div>
    </section>
</template>

<script>
import {mapState} from 'vuex'
import vueSeamlessScroll from 'vue-seamless-scroll'
export default {
    name:'NNotice',
    props:['Notice'],
    data() {
        return {
            windowWidth: document.body.clientWidth,
            listData: [{
                'title': '【关注荣祥热力】在家缴纳热费',
                'id': '0000'
                },
            ],
            defaultOption: {
                step: 1, // 数值越大速度滚动越快
                limitMoveNum: 2, // 开始无缝滚动的数据量 this.dataList.length
                hoverStop: true, // 是否开启鼠标悬停stop
                direction: 1, // 0向下 1向上 2向左 3向右
                openWatch: true, // 开启数据实时监控刷新dom
                singleHeight:70, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
                singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
                waitTime: 3000, // 单步运动停止的时间(默认值1000ms)
            },
            naml:{
                limit:6,
                page:1,
            },
        }
    },
    components:{
        vueSeamlessScroll
    },
    computed:{
        ...mapState({
            Noticep:state=>state.share.Nt
        })
    },
    mounted(){
        this.$store.dispatch('PostNt',this.naml);
        window.onresize = () => {
            return (() => {
                this.windowWidth = document.documentElement.clientWidth // 宽
            })()
        }
    },
    watch: {
        windowWidth: {
            immediate: true, //初始化时让handler调用一下
            //handler什么时候调用？当mane发送改变时
            handler(newvalue, oldvalue) {
                if(newvalue<600){
                    console.log('1')
                    this.defaultOption.singleHeight=47
                }else{
                    console.log('1')
                    this.defaultOption.singleHeight=70
                }
            }
        }
    // 监听页面宽度

    },
    methods: {
        Details(id){
            this.$router.push({
                name:'NewsDetails',
                    query:{
                        id:id,
                        inst:true
                    }
            })
        },
        // Details(id){
        //     this.$router.push({
        //         name:id=='0000'?'PaymentDetails':'NewsDetails',
        //             query:{
        //                 id:id,
        //         }
        //     })
        // },
    },
}
</script>

<style scoped>
  .warp {
    height: 100px;
    width: 100%;
    margin: 0 auto;
    overflow: hidden;
    cursor: pointer;
    }
    .otice{
        width: 100%;
        overflow: hidden;
        box-shadow: 0px 3px 3px #d9d9d9;
    }
    .oticeFrame{
        height: 70px;
        margin: 0 auto;
    }
    .oticeFrame .oticeIcon{
        float: left;
    }
    .oticeNews{
        width: 65%;
        float: left;
        height: 70px;
        overflow: hidden;
    }
    .oticeIcons{
        flex: right;
        margin-top: 3px;
    }
    .oticeNews li{
        float: left;
        line-height: 70px;
        width: 100%;
        padding: 0 20px;
        overflow : hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
    }
    .oticeNews li a{
        display: block;
        font-size: 22px;
        color: #0e0e0e;
        letter-spacing: 1px;
    }
    .oticeNews li a:hover{
        color: #3dabed;;
    }
    .risj{
        color: #999;
    }
    @media (max-width: 768px){
        .oticeNews li a{
            font-size: 16px;
        }
		.oticeFrame{
			height: 50px;
		}
        .oticeFrame img{
            width: 30%;
            margin-top: 4px;
        }
        .oticeNews li {
            width: 100%;
            line-height: 47px;
        }
        .oticeNews{
            height: 50px;
            width: 70%;
        }
	}
</style>
