<template>
    <div class="detecti">
        <div class="nrk">
            <div class="Servic nrk">
                <p>认证咨询</p>
            </div>
            <img src="img/zx-13.jpg">
            <div class="detecti-text">
                <span>吉林医药设计院有限公司药品认证服务中心是专门从事医药行业各类咨询、认证业务的专业机构。</span>
                <span>中心拥有一批从事制药企业管理和医药工程设计的专业咨询技术人才，主要为客户提供GMP、GSP、GPP、GAP、GLP、GGP等与药品有关项目的咨询、策划、质量管理体系文件编制等服务。</span>
                <span>几年来，相继完成了通化金马药业股份有限公司、大连金泉生物工程制药有限公司、呼伦贝尔康益有限公司、黑龙江五常葵花药业有限公司、吉林省华洋药业有限公司、长春今来药业有限公司等药品企业的近百项咨询服务，为这些企业的质量管理及后续发展奠定了坚实的基础。</span>
                <span>我们竭诚希望与医药界的同仁精诚合作，以我们的实力、诚实守信的工作态度，为客户提供优质高效服务，同客户携手发展壮大。</span>
            </div>
        </div>
    </div>
</template>

<script>
export default {

}
</script>

<style scoped>
    .detecti{
        width: 100%;
        background: url('@/assets/zx-13.jpg') center center;
        overflow: hidden;
        margin-top: 20px;
    }
    .detecti img{
        display: block;
        margin: 40px auto;
    }
    .detecti-text{
        margin: 0 auto;
    }
    .detecti-text span{
        text-align: justify;
        display: block;
        font-weight: bold;
        margin-bottom: 40px;
        text-indent: 2em;
        font-size: 16px;
    }
</style>