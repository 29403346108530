<template>
    <ul class="float" v-if="faiked">
        <li v-for="(item,index) in Data" :key="index">
            <router-link :to="{name:item.url}">
                <img :src="'img/'+item.src">
                {{item.name}}
            </router-link>
        </li>
    </ul>
</template>

<script>
export default {
    name:'Float',
    data() {
        return {
            faiked:false
        }
    },
    props:['Data'],
    mounted() {
        window.addEventListener("scroll", this.handleScroll);
        this.handleScroll()
    },
    methods: {
        handleScroll() {
            let top = Math.floor(document.documentElement.scrollTop || document.body.scrollTop);
            if (top > 670) {
                this.faiked = true
            }else{
                this.faiked = false
            }
        }
    }
}
</script>

<style scoped>
    .float{
        position: fixed;
        top: 15%;
        right: 2%;
    }
    .float{
        background-color: #f6f6f6;
        overflow: hidden;
        box-shadow: 0px 0px 4px #d9d9d9;
    }
    .float li{
        width: 90px;
        padding: 10px 0 20px 0;
        text-align:center;
        border-bottom: 1px #edeef2 solid;
        cursor: pointer;
    }
    .float li span{
        font-size: 12px;
        display: block;
        text-align: center;
        margin-top: 12px;
    }
    .float li:hover{
        background-color: #fff;
    }
    .float li:nth-child(4){
        border-bottom:none
    }
    .float li img{
        display: block;
        margin: 0 auto;
    }
    @media (max-width: 768px){
        .float{
            position: relative;
            top: 0%;
            right: 0%;
            width: 92%;
            padding: 0 4%;
            margin:  0 auto;
            box-shadow:none;
            margin-bottom: 20px;
            margin-top: 20px;
        }
        .float li{
            width: 49.5%;
            float: left;
        }
        .float li:nth-child(1){
            border-right: 1px #eee solid;
        }
        .float li:nth-child(3){
            border-right: 1px #eee solid;
        }
    }
</style>