<template>
    <div>
        <div class="nrks">
            <Nav-English/>
        </div>
        <router-view></router-view>
        <BottomNavigation kiss='0'/>
    </div>
</template>

<script>
import NavEnglish from '@/components/pc/NavEnglish';
import BottomNavigation from '@/components/pc/BottomNavigation';
import {mapState} from 'vuex'
export default {
    name:'HeadNav',
    components:{
      BottomNavigation,
      NavEnglish
    },
    data() {
        return {
            changeIndex:-1
        }
    },
}
</script>

<style scoped>
</style>