<template>
    <div>
        <img src="img/banner-0.jpg" class="banone pc">
        <img src="img/web-baner1.jpg" class="banone mobile">
        <!--资质荣誉-->
        <div class="title">
            <p>
                <i>Company Introduction</i>
            </p>
        </div>
        <div class="nrk company">
            <p class="litext">
                <span>Changchun Jiutai Rongxiang Heating Power Co., Ltd. was established on August 13, 2009 with the approval of Jiutai Administration Bureau for Industry and Commerce of Changchun City. The registered capital of the enterprise is RMB 24 million yuan and the business address is 687 Xinhua Street, Jiutai City; the enterprise is a limited liability company, with a unified social credit code of 912201816914656823; business scope of the company: licensed business items: heating services; general business items: pipeline network construction.</span>      
            </p>
            <img src="img/xt-1.jpg" class="litext-text">
                <!--公司简介-->
        </div>
        <div class="course nark">
            <div class="course-nr">
                <ul class="course-text">
                    <li>
                        <div><i>{{figure1}}</i><p>ten thousand+</p></div>
                        <span class="wow animate__animated animate__bounce">Providing heating services to approximately 120000 households and non resident users in Jiutai City</span>
                    </li>
                    <li>
                        <div><i>{{figure2}}</i><p>people</p></div>
                        <span class="wow animate__animated animate__bounce" data-wow-delay="0.3s">staff</span>
                    </li>
                    <li>
                        <div><i>{{figure3}}</i><p style="font-size: 14px;">10000 square meters</p></div>
                        <span class="wow animate__animated animate__bounce" data-wow-delay="0.6s">In net area</span>
                    </li> 
                </ul>
            </div>
        </div>
        <div class="nrk bottom">
            <span>The legal representative of the company is Chu Yuehua, female, 56 years old with the ID No. 222401196504102149. She has been engaged in business and enterprise management for a long time, and has rich experience in enterprise management and extraordinary courage. As a representative of the CPPCC of Jilin Province and the People’s Congress of Yanbian Prefecture, she has won several awards such as March 8th Red Flag Bearer of Yanbian Prefecture, Excellent Female Entrepreneur, Excellent Enterprise Operator, May Day Labor Medal of Jilin Province and other honorary titles.</span>
            <span>On July 22, 2009, Jilin Huashang Construction Group Co., Ltd. signed the Franchise Agreement for the Construction of the Urban Centralized Heating Project in Jiutai District, Changchun City with Jiutai District Government of Changchun City through investment promotion, obtaining an exclusive operation right for 30 years for urban heating in Jiutai District, Changchun City. In August of the same year, Changchun Jiutai Rongxiang Heating Co., Ltd. was established and mergered Changchun Jiutai District Urban Construction Property Management Co., Ltd., a former state-owned enterprise, representing Huashang Group in exercising its powers and assuming its obligations as agreed in the agreement. The company’s main production and operation item is to provide centralized heating for residents and enterprises in the urban area of Jiutai in winter.</span>
            <span>Changchun Jiutai Rongxiang Heating Co., Ltd. has been continuously operating for twelve heating periods in the urban area of Jiutai. Since its establishment, the company has been committed to promoting economic development, improving urban living environment, and improving the quality of heating for residents. While continuously improving basic infrastructure, it actively renovates old pipeline networks and adheres to scientific heating. With the support and cooperation of the Jiutai District Committee, District Government, and relevant departments in Changchun City, the company has successively acquired over 110 old and outdated small boiler houses, greatly improving the regional heating conditions and the urban environment, and achieving the expected goals of centralized heating engineering construction.</span>
            <span>On the basis of ensuring normal heating for grid-connected users, Changchun Jiutai Rongxiang Heating Co., Ltd. has completed the key engineering projects such as the centralized heating source plant, primary and secondary pipeline networks, heat exchange station construction, and household renovation as scheduled according to the agreement. The heating capacity can meet the heat demand of 12 million square meters.</span>
            <span>In the heating period of 2013, the company introduced the domestic leading heat network balance regulation technology, and on the basis of achieving the city’s heating temperature balance, the company maximized energy conservation and emission reduction, becoming an efficient and energy-saving enterprise, making outstanding contributions to the harmonious development of Jiutai District, Changchun City. It has been successively rated as Provincial Winter Heating Safety Operation and Standardized Service Pacesetter, Diamond-level People’s reputation Gold Medal Unit of Jilin Province, Exemplary Organization of Changchun City.</span>
            <span>In 2014, Changchun Jiutai Rongxiang Thermal Power Co., Ltd. signed a contract with Huaneng Power Plant to construct a primary network with a pipe diameter of 1200mm and a total length of approximately 12000 km of pipe trenches. This achieved cogeneration and greatly improved the heating quality and living environment of the residents in urban area of Jiutai.</span>
            <span>According to the relevant government documents, the heating period in Jiutai District lasts for 168 days from October 20th to April 6th of the following year. The actual heating area from 2017 to 2018 is 8.02 million square meters, the actual heating area from 2018 to 2019 is 8.97 million square meters, the actual heating area from 2019 to 2020 is 9.26 million square meters, and the revenue from 2019 to 2020 reached 198.21 million yuan. The actual supplied area from 2020 to 2021 is 9.69 million square meters, and the revenue from 2020 to 2021 reached 206.52 million yuan. The heating area during the heating period of 2021-2022 is 10.21 million square meters, and the heating fee receivable is 216.08 million yuan. During the heating period of 2022-2023, the heating area is 11.09 million square meters, and the heating fee receivable is 25.623 million yuan.</span>
            <span>As of December, 2022, the cumulative bank loan balance of Changchun Jiutai Rongxiang Heating Power Co., Ltd. is 119.99 million yuan, including: ① 30 million yuan from Shanghai Pudong Development Bank, one-year current loan, is a guaranteed loan; ② 40 million yuan from Jiutai Rural Commercial Bank, one year current loan, the third-party real estate and land mortgage loan; ③ 49.99 million yuan from Huaxia Bank, a one-year current loan with a collateral method of 35006 square meters of net land from Changchun Tongsheng Hongye Real Estate Development Co., Ltd., a third party, with a certificate number (CGY [2015] No. 081001050) as collateral, with an assessed value of 176745300 yuan.</span>
            <span>Jiutai District, Changchun City is a node area of Changchun-Jilin-Tumen Development Strategy of Jilin Provincial Government. It was abolished as a district in December, 2014 and officially incorporated into the administrative division of Changchun City. With the rapid development of the regional economy, the heating area will also continue to increase.</span>
            <span>The company adheres to the policy of “building the external image and strengthening internal management”, continuously deepening internal reforms, improving the construction of grassroots organizations, creating good atmosphere that “the company is my home, everyone loves her”, cultivating a unique corporate technology culture with the company’s characteristics, highlighting the modern management concept of “people-orientation”, and providing strong spiritual motivation and talent support for the company’s reform and development. The company has established six functional departments, including a comprehensive office, production technology department, material supply department, financial department, inspection team, and business service department, six production heating offices, and one heat source factory. The total number of registered employees is 159, including more than 50 professional and technical personnel, and more than 70 employees with a bachelor’s degree or above. It is the only centralized heating operation enterprise in Jiutai District, Changchun City.</span>
            <span>The company has the excellent internal audit procedure: employees at all levels of the enterprise must be authorized and approved in order to handle relevant economic transactions. Without authorization and approval, these personnel are not allowed to access these businesses. For relevant positions, division of labor and responsibility must be assigned, and cannot be undertaken by the same person. When economic transactions are recorded, a series of measures and methods must be taken to ensure the authenticity, timeliness, and accuracy of accounting records. In the process of business processing, the company’s requirements for business management and relevant precautions, etc. are made into written documents and rules and regulations, issued to all levels of staff, or hung in public places such as offices and warehouses. It is ensured that personnel at all levels of the enterprise have qualities that are suitable for the work they are responsible for in order to ensure the quality of business work.</span>
            <span>Since its official operation in 2009, the company has always adhered to the business philosophy of “high-quality heating, co-creation of harmony, serving the people, and dedicating to society”, with strengthening internal management as the main line, adjusting structure and concentrating benefits as the means, continuously improving the company’s management system and improving service quality. With the goal of “establishing the image of Rongxiang and building a brand enterprise”, the company has achieved the dream of “looking at the blue sky, watching white snow, heating thousands of homes, and warming thousands of hearts” of the people in Jiutai District, contributing to the heating industry and environmental construction in Jiutai District.</span>
        </div>
    </div>
    
</template>

<script>
import {mapState} from "vuex"
export default {
    data() {
        return {
            figure1: 12,
            figure2: 180,
            figure3: 1100,
            figure4: 4700,
            faiked: false,
            liedn: 0,
            linq: 0,
            data:{
                type:1
            },
            n:'./img/n-'
        }
        
    },
    activated() {
        // this.$store.dispatch('PostAbout',this.data);
    },
    computed:{
		...mapState({
			study:state=>state.share.study,
            About:state=>state.share.About
		})
        
	},
}
</script>

<style scoped>
    .company{
        margin: 2% auto;
        overflow: hidden;
    }
    .litext{
        width: 800px;
        float: left;
        padding-top: 1%;
        font-size: 14px;
        line-height: 36px;
        text-align: justify;
    }
    .litext-text{
        width: 350px;
        float: right;
    }
    .litext span{
        font-size: 24px;
        display: block;
        color: #333;
        margin-bottom: 30px;
        text-align: justify;
        text-indent: 2em;
        line-height: 36px;
    }
    .bottom {
        overflow: hidden;
        margin-bottom: 65px;
    }
    .bottom span{
        font-size: 24px;
        display: block;
        color: #333;
        margin-top: 65px;
        text-align: justify;
        text-indent: 2em;
    }
    .course {
        width: 100%;
        background: url('@/assets/beij_2.jpg') no-repeat center center;
        overflow: hidden;
        margin-top: 90px;
    }

    .course-nr {
        width: 1435px;
        margin: 0 auto;
        margin-top: 60px;
        margin-bottom: 60px;
    }

    .course-text {
        width: 100%;
        overflow: hidden;
    }

    .course-text li {
        float: left;
        width: 33%;
    }
    .course-text li:nth-child(2){
        text-align: center;
    }
    .course-text li:nth-child(2) div{
        text-align: center;
        margin-left: 160px;
    }
    .course-text li:nth-child(3){
        text-align: center;
    }
    .course-text li:nth-child(3) div{
        text-align: center;
        margin-left: 130px;
    }
    .course-text li>div{
        font-size: 76px;
        color: #fff;
        font-weight: bold;
        overflow: hidden;
    }

    .course-text li p {
        font-size: 20px;
        float: left;
    }

    .course-text span {
        font-size: 20px;
        margin-top: 30px;
        color: #fff;
        display: block;
    }

    .course-text li>div i {
        float: left;
    }
    .course-bottom-text {
        font-size: 26px;
        margin-top: 70px;
        color: #fff;
        text-align: justify;
        line-height: 50px;
        margin-bottom: 200px;
    }
    .liesc{
        margin-top: 40px;
        overflow: hidden;
    }
    .liesc>div{
        width: 43%;
        float: left;
        margin-right: 4%;
        border: 1px #eee solid;
        border-radius: 15px;
        padding: 1% 2%;
        margin-bottom: 24px;
    }
    .liesc>div:nth-child(2n){
        margin-right: 0%;
    }
    .letr{
        width: 26%;
        border-right: 1px #eee solid;
        float: left;
        margin-top: 10px;
    }
    .letr img{
        width: 60%;
        margin: 0 auto;
        display: block;
    }
    .letr p{
        text-align:center;
        font-size: 16px;
        font-weight: bold;
        margin-top: 6px;
    }
    .liesc .rlis{
        width: 67%;
        float: right;
        font-size: 14px;
        text-align: justify;
        display: flex;
        align-items:center;
        justify-content: space-between;
        height: 140px;
        overflow: hidden;
        text-indent: 2em;
    }
    .liesc .rlis span{
        display: block;

    }
    @media (max-width: 768px){

        .litext span,.bottom span{
            font-size: 16px;
        }
        .bottom span{
            margin-top: 30px;
        }
        .liesc .rlis{
            height: auto;
            padding: 16px 0;
        }
        .copyright{
            margin-top: 20px;
        }
        .bottomNavigation{
            display: none;
        }
        .copyright-title img{
            position:unset;
            margin:0 auto;
            display: block;
            margin-top: 20px;
        }
        .copyright-title span{
            display: block;
            margin: 0 auto;
            margin-top: 12px;
        }
        .litext{
            width: 100%;
            font-size: 14px;
            line-height: 30px;
        }
        .litext-text {
            width: 100%;
        }
        .liesc>div{
            width: 95%;
        }
        .liesc .rlis{
            font-size: 13px;
        }
        .liesc .rlis{
            width: 70%;
        }
        .liesc .rlis span{
            width: 96%;
        }
        .letr{
            margin-top: 25px;
        }
        .liesc:nth-child(2) div .rlis{
            height: 200px;
        }
        .course{
            margin-top: 40px;
        }
    }
    @media (max-width: 768px){
        .course-bottom-text {
            font-size: 14px;
            margin-top: 4px;
            line-height:26px;
            margin-bottom: 20px;
        }
        .course-text span{
            font-size: 14px;
            text-align: center;
            margin-top: 12px;
        }
        .course-text li{
            width: 100%;
            margin-bottom: 20px;
        }
        .course-text li:nth-child(2) div,.course-text li:nth-child(3) div{
            margin-left: 0;
        }
        .course-text li>div{
            font-size: 40px;
            display: flex;
            justify-content: center;
        }
        .course-text li:nth-child(2) i{
            margin-left: 16px;
        }
        .course-text li p{
            font-size: 24px;
        }
        .course-text li:nth-child(3) i{
            margin-left: 12px;
        }
        .course-nr{
            margin-top: 40px;
            width: auto;
            margin-bottom: 20px;
        }
    }
</style>