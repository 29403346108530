<template>
    <div>
      <div class="nrks">
        <Nav/>
      </div>
      <router-view></router-view>
      <bottom-navigation></bottom-navigation>
    </div>
</template>

<script>
import Nav from '@/components/pc/Nav'
import BottomNavigation from '@/components/pc/BottomNavigation'

export default {
    name:'Pc',
    components:{
      Nav,
      BottomNavigation
    } ,
}
</script>

<style>

</style>