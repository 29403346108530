<template>
    <div class="timelien">
        <div class="nrk">
           <div class="Servic tosp">
                <p>{{mane}}</p>
            </div>
            <div class="timelien-text">
                <div class="timelien-nrk" v-for="item in data" :key="item.id">
                    <div class="timelien-dian">
                        <h1>{{item.time||'更早'}}</h1>
                        <i></i>
                    </div>
                    <div class="timelien-rift">
                        <div class="rift-text">
                            <h1>{{item.info||item.dw}}</h1>
                            <span>{{item.title}}</span>
                        </div>
                        <h2 class="ilief" v-if="item.info">{{item.dw}}</h2>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name:'Timeline',
    props:['data','mane'],
    mounted() {
        this.data=this.data.reverse()
    },
}
</script>

<style scoped>
    .timelien{
        width: 100%;
        background: url(@/assets/zx-10.jpg) center center;
        overflow: hidden;
    }
    .timelien-text{
        width: 100%;
        padding: 0 5%;
        overflow: auto;
        max-height: 800px;
        margin-bottom: 40px;
    }
    .timelien-text::-webkit-scrollbar { width: 14px;}
    .timelien-text::-webkit-scrollbar-track { background-color: #e9e9f4;  border-radius: 100px;}
    .timelien-text::-webkit-scrollbar-thumb { box-shadow: inset 0 0 6px #002cd0;  border-radius: 100px;}
    .timelien-text::-webkit-scrollbar {width: 16px;} 
/* .timelien-text::-webkit-scrollbar-track { background-color: #e9e9f4;    border-radius: 100px;} 
.timelien-text::-webkit-scrollbar-thumb { background-color: #002cd0;    border-radius: 100px;} */
    .tosp{
        margin-top: 60px;
    }
    .timelien-nrk{
        overflow: hidden;
        position: relative;
    }
    .timelien-dian{
        width: 6%;
        float: left;
        font-size: 18px;
        text-align: right;
    }
    .timelien-dian i{
        width: 25px;
        height: 25px;
        display: block;
        border-radius: 50px;
        background-color: #002cd0;
        position: absolute;
        top: 0;
        left: 8.2%;
    }
    .timelien-rift{
        width: 82%;
        padding: 1% 4%;
        float: left;
        margin-left: 3%;
        border-left: 1px #4a4a4e solid;
    }
    .ilief{
        float: left;
        color: #4B53A2;
        margin-top: 20px;
    }
    .rift-text{
        width: 80%;
        float: left;
    }
    .rift-text span{
        margin-top: 18px;
        padding-bottom: 30px;
        color: #999;
        display: block;
    }
    @media (max-width: 760px) {
        .Servic p{
            font-size: 16px;
        }
        .timelien-rift{
            margin-left: 20%;
            width: 84%;
        }
        .timelien-dian{
            width: 16%;
        }
        .timelien-dian i{
            left: 16.5%;
        }
        .timelien-dian h1{
            font-size: 14px;
            margin-top: 4px;
        }
        .timelien-text{
            max-height: auto;
        }
    }
</style>