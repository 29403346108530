<template>
      <div class="Servic nrk">
        <p></p>
        <img src="img/zx-6.jpg" class="beij">
        <img src="img/zx-14.jpg" class="beijn">
    </div>
</template>

<script>
export default {

}
</script>

<style scoped>
    .beijn{
        display: none;
    }
    @media (max-width: 768px){
    .systemzs li{
        width: 100%;
    }
    .beij{
        display: none;
    }
    .beijn{
        display: block;
        width: 100%;
    }
    }
</style>