<template>
    <div>
        <!--资质荣誉-->
        <div class="nrk">
            <div class="title ">
                <p>
                    <i>企业文化</i>
                </p>
                <span>corporate culture</span>
            </div>
        </div>
        <img src="img/zx-9.jpg" class="nark pc">
        <img src="img/zx-13.jpg" class="nark mobile">
    </div>
</template>

<script>
import {mapState} from 'vuex'
export default {
    data() {
        return {
            data:{
                type:3
            },
        }
    },
    activated() {
        this.$store.dispatch('PostAbout',this.data);
    },
    computed:{
		...mapState({
			study:state=>state.share.study,
            About:state=>state.share.About
		})
        
	},
}
</script>

<style scoped>
    .beij{
        width: 100%;
    }
    .beij{
        margin-top: 20px;
    }
    .beijn{
        display: none;
    }
    .systemzs{
        width: 100%;
        overflow: hidden;
        margin: 26px auto;
    }
    .systemzs li{
        width: 33%;
        float: left;
        text-align: center;
    }
    .systemzs li img{
        width: 58%;
        margin-bottom: 12px;
    }
    .lisn span{
        color: #2d3c56;
        line-height: 70px;
        display: block;
        font-size: 20px;
    }
    .beijn{
        width: 100%;
    }
    .litext{
        width: 100%;
        font-size: 18px;
        line-height: 30px;
    }
    .litext-text{
        width: 36%;
        float: right;
    }
     @media (max-width: 768px){
        .systemzs li{
            width: 100%;
        }
        .beij{
            display: none;
        }
        .beijn{
            display: block;
        }
        .systemzs li p{
            margin-bottom: 20px;
        }
        .lisn span{
            font-size: 16px;
            line-height: 50px;
        }
     }
</style>
