<template>
    <div class="detecti">
        <div class="nrk">
            <div class="Servic nrk">
                <p>工程监理</p>
            </div>
            <img src="img/zx-10.jpg">
            <div class="detecti-text">
                <span>医药工程监理是吉林医药设计院为客户提供全过程工程技术服务的主要业务之一，我院专业配套齐全，拥有建筑、结构、设备、工艺、电气、自控、暖通、给排水、技经等专业技术人员，其中有几十人次持有注册监理工程师等执业资格，技术力量雄厚，项目管理经验丰富</span>
                <span>多年来，我院的监理业绩突出，社会信誉良好，先后完成了长春英平药业有限公司、中古合资长春海伯尔生物技术有限公司、辽宁好护士药业有限公司、山东加华药业有限公司、长春金赛药业有限公司、韩国人参公社中国工厂等数十家制药企业建设项目的工程监理和项目管理工作。</span>
                <span>针对医药建设行业对工程监理的特殊要求，遵循“守法、诚信、公正、科学”的监理准则，坚持经济效益和社会效益同步提高，我院以有效的监理和良好的服务赢得了项目业主的高度赞誉和信任。</span>
                <span>我们将竭诚为客户提供规范化、标准化和高效优质、安全可靠的医药工程建设监理和项目管理服务，为医药建设行业健康发展起到良好保障和促进作用。</span>
            </div>
        </div>
    </div>
</template>

<script>
export default {

}
</script>

<style scoped>
    .detecti{
        width: 100%;
        background: url('@/assets/zx-13.jpg') center center;
        overflow: hidden;
        margin-top: 20px;
    }
    .detecti img{
        display: block;
        margin: 40px auto;
    }
    .detecti-text{
        margin: 0 auto;
    }
    .detecti-text span{
        text-align: justify;
        display: block;
        font-weight: bold;
        margin-bottom: 40px;
        text-indent: 2em;
        font-size: 16px;
    }
</style>