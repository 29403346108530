<template>
    <div class="nark">
        <div class="top-img"></div>
        <div class="top-img1">
            <h1>总经理致辞</h1>
            <div class="zc-text">
                <img src="@/assets/zx-16.jpg" > 
                <p class="litext">
                    <span style="text-indent: 2em;">九台区广大热用户，我谨代表长春市九台区荣祥热力有限公司，向关心支持九台区供热事业发展的广大用户和社会各界朋友们致以最衷心的感谢！</span>
                    <span>近几年，是荣祥热力公司实现强劲发展阶段，是经营管理上台阶、阳光服务上水平的提升阶段；更是全体员工提振精神，团结拼搏，与进俱进，改革创新奋进阶段！</span>    
                </p>
            </div>
            <div class="sdiwq">
                    展望未来，重任在肩，在今后的工作中，我们有信心、有决心、有能力，在区委、区政府领导下，继续以 “促进九台区经济发展、改善城市生活环境，提高居民供热质量”为宗旨，以“树立荣祥形象、打造品牌企业”为目标，以“家和义爱诚”企业文化为引领，继续发扬公司“创新经营，敢为人先”的企业精神，秉承“优质供热、共创和谐，服务百姓、奉献社会”的经营理念，强化管理，热诚服务凝神聚气，真抓实干，开创九台区供热事业的全新局面。
            </div>
        </div>
    </div>
</template>

<script>
import {mapState} from "vuex"
export default {
    data() {
        return {
            data:{
                type:2
            },
        }
    },
    computed:{
		...mapState({
			study:state=>state.share.study,
            About:state=>state.share.About
		})
	},
    activated() {
        // this.$store.dispatch('PostAbout',this.data);
    },
}
</script>

<style scoped>
    .top-img{
        height: 311px;
        background: url('@/assets/zx-14.jpg') top center  no-repeat;
        margin-top: 20px;
    }
    .top-img1{
        height: 880px;
        background: url('@/assets/zx-15.jpg') top center  no-repeat;
    }
    .zc-text{
        width: 1145px;
        margin: 0 auto;
        overflow: hidden;
    }
    .zc-text{
        color: #fff;
        margin-top: 40px;
    }
    .zc-text p{
        width: 630px;
        float: left;
        margin-top: 14px;
    }
    .zc-text img{
        float: right;
        margin: 26px;
        border: 3px #eee solid;
        border-radius: 5px;
        margin-right: 0;
        width: 470px;
    }
    .top-img1 h1{
        color: #fff;
        text-align: center;
        font-size: 32px;
        letter-spacing: 1px;
    }
    .litext span{
        display: block;
    }
    .litext,.sdiwq{
        padding-top: 1%;
        font-size: 24px;
        line-height: 48px;
        text-align: justify;
        color: #fff;
        width: 80%;
        margin: 0 auto;
    }
    .sdiwq{
        width: 1145px;
        text-indent: 0;
    }
    @media (max-width: 768px){
        .top-img{
            background-size: 100%;
            height: 60px;
            display: none;
        }
        .top-img1{
            width: 100%;
            background: url('@/assets/zx-20.jpg') top center  no-repeat;
            background-size: 100%;
            overflow: hidden;
            padding-bottom: 80px;
        }
        .zc-text img{
            width: 80%;
            margin: 0 auto;
            height: auto;
            float: none;
            display: block;
        }
        .zc-text{
            width: 84%;
            margin-top: 20px;
        }
        .zc-text p{
            width: 100%;
        }
        .top-img1 h1{
            margin-top: 60px;
            font-size: 26px;
        }
        .litext,.sdiwq{
            font-size: 16px;
            line-height: 28px;
            
        }
        .litext span{
            margin-top: 20px;
        }
        .sdiwq{
            width: 84%;
            text-indent: 2em;
            margin-top: 20px;
        }
    }
</style>