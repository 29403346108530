<template>
    <div class="nrk">
        <div class="top-text pps">
            <h1>关注</h1>
            <h2>荣祥热力</h2>
        </div>
        <div class="top-text">
            <h1 class="nns">在家</h1>
            <h2 class="tts">缴纳热费</h2>
        </div>
        <div class="top-text pps wedb">
            <img :src="Http+Main.er" class="img">
            <div class="jies">
                <h3>公众号：九台荣祥供热有限公司</h3>
                <h3 class="ppss">缴费方式：</h3>
                <div class="jies-text">
                    <p>1.可以在窗口使用现金缴费</p>
                    <p>2.关注九台荣祥供热有限公司微信公众号，在服务大厅缴费</p>
                    <p>3.在荣祥热力指定的银行进行缴费</p>
                </div>
            </div>
        </div>
        <div class="img-1">
            <img src="img/zx-14.jpg">
        </div>
        <div class="top-text ppssss">
            <h3 class="fone">足不出户、轻松缴费</h3>
        </div>
        <div class="top-text ppss">
            <h3>第一次需要绑定缴费，以后均不需要绑定，可以直接缴费</h3>
        </div>
        <div class="top-sne pps">
            <div class="jies">
                <h3 class="lset">绑定方法注意事项</h3>
                <div class="jies-text jies-re">
                    <p>· 可以用用户卡号和用户名进行绑定</p>
                    <p>· 小区绑定可以模糊搜索，百俊星品搜家百俊即可</p>
                    <p>· 一个微信号可以绑定多个小区、在我的、我的房屋</p>
                    <p>· 缴费成功后，如需要正规发票，可以到窗口申请</p>
                </div>
            </div>
        </div>
        <div class="top-text pps bottom wedb">
            <p>24小时客服务电话:0431-82333879</p>
            <p class="mas-1">地址:吉林省长春市九台区新华大街687号</p>
        </div>
    </div>
</template>

<script>
import{mapState} from'vuex';
export default {
        computed:{
        ...mapState({
            Main:state=>state.share.Main,
            Http:state=>state.share.Http,
        })
    },
}
</script>

<style  scoped>
    .img-1{
        text-align: center;
        margin-top: 20px;
        overflow: hidden;
    }
    .img-1 img{
        border-radius: 10px;
    }
    .bottom{
        margin-bottom: 20px;
    }
    .mas-1{
        margin-left: 20px;
    }
    .top-sne{
        width: 630px;
        margin: 0 auto;
    }
    .jies .lset{
        float: left;
        width: 200px;
        font-size: 46px;
        border-right: 2px #333 solid;   
    }
    .jies-re{
        float: right;
    }
    .fone{
        font-size: 40px;
    }
    .jies-text{
        font-size: 14px;
    }
    .jies-text p{
        margin-top: 10px;
    }
    .jies{
        border: 1px #333 solid;
        margin-left: 30px;
        border-radius: 15px;
        overflow: hidden;
        padding: 18px 30px;
    }
    .jies h3{
        font-size: 18px;
    }
    .img{
        border: 1px #eee solid;
    }
    .ppss{
        margin-top: 10px;
    }
    .pps{
        margin-top: 20px;
    }
    .ppssss{
        margin-top: 60px;
    }
    .top-text{
        width: 100%;
        display: flex;
        justify-content: center;
    }
    .top-text h1{
        color: #f44f53;
        font-size: 65px;
    }
    .top-text h2{
        font-size: 40px;
        margin-top: 28px;
        margin-left: 6px;
    }
    .top-text .nns{
        color: #333;
        font-size: 56px;
    }
    .top-text .tts{
        color:#f44f53;
        font-size: 36px;
        margin-top: 22px;
    }
    @media (max-width: 768px){
        .wedb{
            width: 100%;
            display: block;
        }
        .wedb img{
            display: block;
            margin: 0 auto;
        }
        .jies{
            margin-left: 0;
            margin-top: 20px;
        }
        .img-1{
            width: 100%;
        }
        .img-1 img{
            width: 100%;
        }
        .fone{
            font-size: 26px;
        }
        .ppssss{
            margin-top: 20px;
        }
        .top-sne{
            width: 100%;
        }
        .jies .lset{
            float: none;
            margin: 0 auto;
            border-right: none;
            margin-bottom: 10px;
        }
        .wedb p{
            text-align: center;
            margin-left: 0;
            margin-bottom: 10px;
        }
    }
</style>