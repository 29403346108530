<template>
    <!--底部导航-->
    <section>
        <div class="bottomNavigation nark" v-if="kiss!=0">
            <ul class="bottomNavigation-navigation nrk" v-if="kiss!=0">
                <li v-for="(item,index) in navigations" :key="index" class="daoh">
                    <h1>{{item.name}}</h1>
                    <div v-for="(son,indexs) in item.son" :key="indexs">
                        <router-link
                        v-if="son.name" 
                        :to="{name:son.url}">
                            {{son.name}}
                        </router-link>
                        <a v-else @click="tabid(son)" style=" cursor: pointer;">
                            {{son.title}}
                        </a>
                    </div>

                </li>
                <li class="lxwm">
                    <h1>联系我们</h1>
                    <a>电&emsp;&emsp;话：{{Main.mobile}}</a>
                    <a>邮&emsp;&emsp;箱：{{Main.email}}</a>
                    <a>地&emsp;&emsp;址：{{Main.address}}</a>
                </li>
                <li class="rwm">
                    <img :src="Http+Main.er">
                    <a>九台荣祥热力公众号</a>
                </li>
            </ul>
            <div class="bottom-text">
                <span v-html="Main.ba"></span><span class="nns">{{Main.copy}}</span><span class="nns">技术支持：纲易传媒</span>
            </div>
        </div>
        <div class="bottom-text" v-if="kiss==0">
                <span class="nns">
                    Copyright: Rongxiang Thermal Power Co., Ltd., Jiutai District, Changchun City
                </span>
                <span class="nns sss">
                    Address: No. 687 Xinhua Street, Jiutai City, Jilin Province Phone: 0431-82333879 Website registration/license number: Ji ICP Bei 2021008384-1
                </span>
        </div>
        <!--底部版权-->
        <div class="copyright" v-if="kiss!=0">
            <div class="copyright-title nrk">
                <p>{{Main.copy}}<span>电话:{{Main.mobile}}</span></p>
                <p>地址：{{Main.address}}
                <p class="ssl">
                    <i>备案: </i>
                    <i v-html="Main.ba" class="bas"></i>
                </p>
                <p>技术支持：纲易传媒</p>
                <img :src="Http+Main.er" class="copyright-rwm">
            </div>
        </div>
    </section>
</template>

<script>
import throttle from "lodash/throttle"
import{mapState,mapMutations} from'vuex';
export default {
    props:['kiss'],
    computed:{
        ...mapState({
            Main:state=>state.share.Main,
            Http:state=>state.share.Http,
            nids:state=>state.share.nids,
            navigation(state){
                let navigation=state.share.navigation
                return navigation
                
            },
            navigations(){
                let naviga=this.navigation;
                naviga=naviga.slice(1)
                naviga=naviga.slice(0,-1);
                return naviga
            }
        })
    },
    methods: {
        ...mapMutations(['NIDS']),
        shifts(e){
            e=e.shift()
            return e
        },
        tabid:throttle(function(index){
            if(this.nids!=index.id){
                this.NIDS(index.id);
                this.$router.push({
                    name:'Tabulation',
                        query:{
                            id:index.id,
                            title:index,
                        }
                }) 
            }  
        },50)
    },
}
</script>
<style>
    .sss{
        display: block;
        margin-top: 12px;
    }
    .bottomNavigation{
        width: 100%;
        overflow: hidden;
        padding: 50px 0 30px 0;
        background-color: #373b41;
    }
    .bottomNavigation-navigation{
        margin: 0 auto;
        overflow: hidden;
        padding:0 1px;
    }
    .bottomNavigation-navigation li{
        float: left;
        width: 135px;
        border-left: 1px #dcdcdc solid;
        padding-left: 20px;
        position: relative;
    }
    .bottomNavigation-navigation .rwm{
        width: 140px;
        flex: right;
    }
    .rwm img{
        width: 140px;
        height: 140px;
    }
    .rwm a{
        text-align: center;
        display: inline-block;
        margin-top: 14px;
    }
    .bottomNavigation-navigation .lxwm{
        width: 340px;
    }
    .bottomNavigation-navigation li h1{
        margin-bottom: 40px;
        font-size: 16px;
        color: #fff;
        font-weight: normal;
    }
    .bas{
        float: left;
        margin-left: 5px;
    }
    .bas a{
        color: #fff;
    }
    .bottomNavigation-navigation li a{
        display: block;
        padding-bottom: 10px;
        color: #999;
        font-size: 12px;
    }
    .bottomNavigation-navigation .daoh a:hover{
        color: #fff;
    }
    .bottomNavigation-navigation li::after{
        content:"";
        width: 3px;
        height: 30px;
        background-color: #31cf47;
        left: -2px;
        top: 0;
        position: absolute;
    
    }
    .copyright{
        width: 100%;
        overflow: hidden;
        padding: 27px 0;
        background-color: #373b41;
        position: relative;
        z-index: 99;
    }
    .copyright-title{
        margin: 0 auto;
        position: relative;
    }
    .copyright-title p{
        color: #fff;
        margin-top: 12px;
        font-size: 12px;
        text-align: center;
    }
    .copyright-title span{
        margin-left: 12px;
    }
    .copyright-title img{
        position: absolute;
        top: -20px;
        right: 150px;
        width: 100px;
        height: 100px;

    }
    .bottom-text{
        padding: 36px;
        text-align: center;
        border-top: 1px #525457 solid;
        background-color: #373b41;
    }
    .nns{
        color: #999;
        margin-left: 12px;
    }
    .copyright{
        display: none;
    }
    @media (max-width: 768px){
        .copyright{
            display: block;
         }
        .copyright{
            margin-top: 20px;
        }
        .bottomNavigation{
            display: none;
        }
        .copyright-title img{
            position:unset;
            margin:0 auto;
            display: block;
            margin-top: 20px;
        }
        .copyright-title span{
            display: block;
            margin: 0 auto;
            margin-top: 12px;
        }
        .ssl{
            display: flex;
            justify-content: center;
        }
    }
</style>