<template>
     <div class="Nav">
        <router-link to='/pc'><img src="./img/logo.jpg"></router-link>
        <div class="Navigation" :class="fast?'flte':'rlte'">
            <div class="sidq" @click="mobileNavigation"></div>
            <div class="Navigation-nrk">
                <ul class="Navigation-fence-oen" :class="taBot?'navBtn':'navBtn-fans'" @mouseleave="changeIndex=-1">
                    <li v-for="(item,index) in navigation" :key="index">
                        <div @mouseenter='change(index)' class="nksid">
                            <div class="shoujk"  @click="taBots(item)"></div>
                            <span @click="fast=false">
                                <router-link :to="item.url" active-class="lans">{{item.name}}</router-link>
                            </span>
                        </div>
                        <dl class="Navigation-fence-zid">
                        <dd :style="{display:changeIndex==index?'block':'none'}">
                            <div class="lied"></div>
                            <div class="dd">
                                <span v-for="(son,inds) in item.son" :key="inds">
                                    <router-link :to="{name:son.url}" active-class="lans" v-if="!son.title">{{son.name}}</router-link>
                                    <a v-else @click="tabid(son)" style=" cursor: pointer;">{{son.title}}</a>
                                </span>
                            </div>
                        </dd>
                    </dl>
                    </li>
                </ul>
                <ul class="Nav-son" :class="taBot?'leftBtf':'leftBtf-fans'" v-if="taBot">
                    <li class="Nav-son-top">
                        <div @click="taBot=false"></div>
                        <p>{{datason.name}}</p>
                    </li>
                    <li class="Nav-son-nr" v-for="(item,index) in datason.son" :key="index"  @click="mobileNavigation">
                         <router-link :to="{name:item.url}" active-class="lans" v-if="!item.title">{{item.name}}</router-link>
                          <a v-else @click="tabid(item)" style=" cursor: pointer;">{{item.title}}</a>
                    </li>
                </ul>
            </div>
        </div>
        <div class="Navigation-cut">
            <div class="Navigation-mane">
                <p class="Navigation-mane-click"><a>中文</a></p>
                <p><router-link to="/English">English</router-link></p>
            </div>
        </div>
        <div class="lastlist" @click="mobileNavigation">
            <span></span>
            <span></span>
            <span></span>
        </div>
     </div>
</template>

<script>
import throttle from "lodash/throttle"
import {mapState,mapMutations} from 'vuex'
export default {
    name:'HeadNav',
    data() {
        return {
            changeIndex:-1,
            fast:false,
            taBot:false,
            datason:''
        }
    },
    computed:{
        ...mapState({
            navigation:state=>state.share.navigation,
            nids:state=>state.share.nids
        })
    },
    methods: {
        ...mapMutations(['NIDS']),
        change:throttle(function(index){
            this.changeIndex=index
        },50),
        mobileNavigation(){
            this.fast=!this.fast;
            if(this.fast===false){
                this.taBot=false
            }
        },
        taBots(e){
            this.taBot=!this.taBot;
            this.datason=e;
        },
        tabid:throttle(function(index){
            if(this.nids!=index.id){
                this.NIDS(index.id);
                this.$router.push({
                    name:'Tabulation',
                        query:{
                            id:index.id,
                            title:index,
                        }
                }) 
            }   
        },50)
    },

}
</script>

<style scoped>
    .lastlist{
        float:right;
        width: 60px;
        height: 60px;
        margin-left: 20px;
        margin-top: 8%;
        display: none;
    }
    .lastlist span {    
        display: block;
        width: 100%;
        height: 2px;
        background: #fff;
        border-radius: 2px;
        animation: navBtn 2s infinite ease;
        transition: all 0.4s ease 0s;
        background: #516598;
        margin-top: 6px;
    }
    .lastlist span:nth-child(2) {
        margin: 6px 0;
        animation-delay: 0.5s;
    }
    .lastlist span:nth-child(3) {
        animation-delay: 1.0s;
    }
    a:hover{
            text-decoration: none;
    }
    .Nav{
        width: 100%;
        height: 100px;
        display: flex;
        display: -webkit-flex;
        align-items:center;
        justify-content: space-between;
    } 
    .Navigation-fence {
        float: right;
        position: relative;
    }
    .Navigation{
        float: left;
        width: 700px;
    }
    .Navigation-fence-oen {
        width: 100%;
        float: left;
    }
    .Navigation-fence-oen li {
        float: left;
        line-height: 104px;
        width: 15%;
        font-size: 18px;
        text-align: center;
        position: relative;
    }
    .Navigation-fence-oen li:nth-child(1) .shoujk{
        display: none;
    }
    .Navigation-fence-oen li:nth-child(7) .shoujk{
        display: none;
    }
    .Navigation-fence-zid {
        position: absolute;
        width: 100%;
        margin-top: 0px;
        z-index: 999;
    }

    .Navigation-fence-zid dd {
        width: 100%;
        text-align: center;
        margin-top: -30px;
        border-radius: 2px;
        padding-bottom: 14px;
        overflow: hidden;
        line-height: 24px;
    }

    .Navigation-fence-zid .lied {
        border-top: 2px #0019a7 solid;
        width: 80%;
        margin: 0 auto;
    }

    .Navigation-fence-zid .dd {
        width: 100%;
        text-align: center;
        background: rgba(0, 25, 167, .5);
        overflow: hidden;
    }

    .Navigation-fence-zid dd span {
        width: 100%;
        display: block;
        color: #fff;
        position: relative;
        font-size: 14px;
       line-height: 38px;
    }
    .Navigation-fence-zid dd a{
        color: #fff;
        display: block;
        width: 100%;
        font-size: 15px;
    }
    .Navigation-fence-zid dd span:hover a{
        color: #333;
    }
    .Navigation-cut {
    float: right;
    line-height: 104px;
    background-color: red;
    /* 针对不支持渐变的浏览器 */
    background-image: linear-gradient(to bottom right, #0084ff, #090252);
    height: 40px;
    width: 170px;
    border-radius: 50px;
    }

    .Navigation-cut p {
        float: left;
        line-height: 34px;
        width: 50%;
        text-align: center;
    }
    .Navigation-mane {
        width: 96%;
        margin: 0 auto;
        margin-top: 3px;
        background-color: #f2f2f2;
        height: 34px;
        border-radius: 50px;
    }
    .Navigation-mane-click {
        background-color: #fff;
        border-radius: 50px;
        box-shadow: 5px 0px 5px #d9d9d9;
    }
    .dd span:hover {
        background-color: #fff;
        color: #333;
    }
    .lans{
        color: #0019a7;
    }
    .Nav-son{
        position: absolute;
        left: -100%;
        top: 0;
        display: none;
    }
    .Nav-son-top{
        border-bottom: 1px solid transparent;
        line-height: 20px;
        height: 40px;
        margin: 0;
        width: 100%;
        color: #999;
        position: relative;
        line-height: 40px;
    }
    .Nav-son-top p{
        margin-left: 45%;
    }
    .Nav-son{
        width: 90%;
        padding: 0 5%;
        overflow: hidden;
    }
    .Nav-son-nr{
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        color: inherit;
        line-height: 20px;
        display: block;
        width: 90%;
        float: right;
        padding: 10px 10px 10px 0px;
        margin: 0;
        border-bottom: 1px #DADADA solid;
        color: #565656;
    }
    .Nav-son-nr a{
        display: block;
        width: 100%;
    }
    .Nav-son-top div::before{
        content: '';
        border: 2px solid transparent;
        display: inline-block;
        width: 8px;
        height: 8px;
        margin: auto;
        position: absolute;
        top: 0;
        bottom: 0;
        -webkit-transform: rotate(-45deg);
        -moz-transform: rotate(-45deg);
        -ms-transform: rotate(-45deg);
        -o-transform: rotate(-45deg);
        transform: rotate(-45deg);
        left: 20px;
        border-color: rgba(0, 0, 0, 0.3);
        border-right: none;
        border-bottom: none;
        text-align: center;
    }

    @keyframes mymove {
        0%{right: -60%;display: block;}
        100%{right: 0%;}
    }
    @keyframes navBtn{
        50%{width: 60%;}
        100%{width: 100%;}
    }
    @keyframes navBtnps{
        0%{margin-left: 0;}
        100%{margin-left: 100%;}
    }
    @keyframes navBtnps-fans{
        0%{margin-left: 100%;}
        100%{margin-left: 0%;}
    }
    @keyframes leftBtf{
        0%{left: -100%;}
        100%{left: 0%;}
    }
    @keyframes leftBtf-fans{
        0%{left: 0%;}
        100%{left: -100%;}
    }
    @media (max-width: 768px){
        .navBtn{
        animation: navBtnps 1s infinite ease;
        animation-iteration-count: 1;
         animation-fill-mode: forwards;
        }
        .navBtn{
            animation: navBtnps 1s infinite ease;
            animation-iteration-count: 1;
            animation-fill-mode: forwards;
        }
        .navBtn-fans{
            animation: navBtnps-fans 1s infinite ease;
            animation-iteration-count: 1;
            animation-fill-mode: forwards;
        }
        .leftBtf{
            animation: leftBtf 1s infinite ease;
            animation-iteration-count: 1;
            animation-fill-mode: forwards;
            display: block;
        }
        .leftBtf-fans{
            animation: leftBtf-fans 1s infinite ease;
            animation-iteration-count: 1;
            animation-fill-mode: forwards;
            display: block;
        }
        .sidq{
            width:39%;
            float: left;
            height: 100vh;
        }
        .Navigation-fence-zid{
            display: none;
        }
        .lastlist{
            display: block;
        }
        .Nav{
            height: 80px;
        }
        .Nav img{
            width: 90%;
            margin-left: 5%;
        }
        .Navigation{
            position: fixed;
            z-index: 999;
            width: 100%;
            height: 100vh;
            top: 0;
            right: -60%;
            display: none;
 
        }
        .Navigation-nrk{
            width: 60%;
            float:right;
            background-color: #f3f3f3;
            height: 100vh;
            position: relative;
            overflow: hidden;
        }
        .nksid a{
            font-size: 14px;
            text-align: right;
        }
        .Navigation-fence-oen li{
            width: 90%;
            line-height: 60px;
            border-bottom: 1px rgba(0, 0, 0, 0.1) solid;
            text-align: right;
            margin: 0 auto;
            float:none;
            line-height: 50px;
            position: relative;
            overflow: hidden;
        }
        .Navigation-fence-oen li .shoujk:before {
            content: '';
            border-left-width: 1px;
            border-left-style: solid;
            display: block;
            position: absolute;
            top: 0;
            bottom: 0;
            left: 50px;
            border-color: rgba(0, 0, 0, 0.1);
        }
        .shoujk{
            float: left;
            width: 25%;
            height: 50px;
        }
        .nksid a{
            width: 70%;
            padding-right: 5%;
            display: block;
            float:right;
        }
        .Navigation-fence-oen .shoujk:after {
            content: '';
            border: 2px solid transparent;
            display: inline-block;
            width: 8px;
            height: 8px;
            margin: auto;
            position: absolute;
            top: 0;
            bottom: 0;
            -webkit-transform: rotate(-45deg);
            -moz-transform: rotate(-45deg);
            -ms-transform: rotate(-45deg);
            -o-transform: rotate(-45deg);
            transform: rotate(135deg);
            border-color: rgba(0, 0, 0, 0.3);
            border-top: none;
            border-left: none;
            left: 20px;
        }
        .Navigation-cut{        
            height: 34px;
            width: 230px;
        }
        .Navigation-cut p,.Navigation-mane{
            height: 28px;
        }
        .Navigation-cut p{
            line-height: 28px;
        }
        .flte{
            animation: mymove 0.5s infinite ease;
            animation-iteration-count: 1;
            animation-fill-mode: forwards;
            display: block;
        }
    }
</style>