<template>
    <div class="subnav">
        <ul class="nrk"  @mouseleave="changeIndex=-1">
            <li v-for="(item,index) in data" :key="index" class="item" @mouseenter='change(index)'>
            <div v-if="!item.name" class="nsid" :class="id==item.id?'nlis':''" @click="tabid(item)">
                {{item.title}}
            </div>
            <router-link :to='{name:item.url}' active-class="nlis" v-else>{{item.name||item.title}}</router-link> 
                <ul class="son-nek" v-show="item.son&&index==changeIndex" @mouseleave="sons=-1">
                    <li v-for="(son,indexs) in item.son" :key="indexs"  @mouseenter='chsons(indexs)'>  
                        <p>
                        <router-link :to='{name:son.url}'>{{son.name}}</router-link>
                        </p>
                        <dl class="sons" v-show="son.son&&indexs==sons">
                            <dd v-for="(sons,index) in son.son" :key="index">
                                <router-link :to='{name:sons.url}'>{{sons.name}}</router-link>
                            </dd>
                        </dl>
                    </li>
                </ul>  
            </li>
        </ul>
    </div>
</template>

<script>
import {mapMutations,mapState} from 'vuex'
import throttle from "lodash/throttle"
export default {
    data() {
        return {
            changeIndex:-1,
            sons:-1,
            id:1
        }
    },
    computed:{
        ...mapState({
            nids:state=>state.share.nids
        })
    },
    props:['data'],
     watch: {
        nids: {
            immediate: true, //初始化时让handler调用一下
            //handler什么时候调用？当mane发送改变时
            handler(newvalue, oldvalue) {
                this.id=newvalue;
                // console.log('mane呗修改了', newvalue, oldvalue)
            }
        }
    },
    methods: {
        ...mapMutations(['NIDS']),
        change:throttle(function(index){
            this.changeIndex=index
        },50),
        chsons:throttle(function(index){
            this.sons=index
        },50),
        
        tabid:throttle(function(index){
        if(this.nids!=index.id){
            this.id=index.id;
            this.NIDS(index.id);
            this.$router.push({
                name:'Tabulation',
                    query:{
                        id:index.id,
                        title:index,
                    }
            }) 
        }
        },50)
    },
}
</script>

<style>
    .subnav{
        width: 100%;
        height: 80px;
        box-shadow: 0px 3px 3px #d9d9d9;
    }
    .subnav>ul{
        margin: 0 auto;
        height: 80px;
        overflow: hidden;
    }
    .subnav>ul>.item{
        line-height: 80px;
        float: left;
        margin-right: 50px;
        position: relative;
    }
    .subnav>ul>.item a,.nsid{
        font-size: 16px;
        cursor: pointer;
    }
    .nlis::after{
        content: '';
        width: 100%;
        height: 2px;
        background-color: #0019a7;
        position: absolute;
        bottom: 6px;
        left: 0;
    }
    .son-nek{
        position: absolute;
        top: 70px;
        left: -6px;
        border: 1px #eee solid;
        background-color: #fff;
        width: 60px;
        z-index: 99;
        line-height: 30px;
        text-align: center;
        border-radius: 5px;
        cursor: pointer;
    }
    .son-nek li{
        position: relative;
    }
    .son-nek li:hover p{
        background-color: #0019a7;
        color: #fff;
    }
    .son-nek li:hover p a{
        color: #fff;
    }
    .son-nek::after{
    content: '';
    background: url(./img/jtl.png) no-repeat ;
    background-size: 100%;
    width: 24px;
    height: 14px;
    position: absolute;
    top:-13px;
    left: 18px;
    z-index: 9999;
    }
    .sons{
        position: absolute;
        left: 60px;
        top: 0;
        width: 60px;
        background-color: #fff;
        border: 1px #eee solid;
        cursor: pointer;
    }
    .sons dd:hover{
            background-color: #0019a7;
        color: #fff;
    }
    .sons dd:hover a{
        color: #fff;
    }
    a:hover {
        text-decoration: none;
    }
    @media (max-width: 768px){
        .subnav>ul>.item a,.nsid{
            font-size: 16px;
        }
        .subnav>ul{
            height: 60px;
        }
        .subnav>ul>.item {
            line-height: 60px;
            margin-right: 10px;
            text-align: center;
        }
        .subnav{
            height: 60px;
        }
        .son-nek{
            display: none;
        }
    }
</style>