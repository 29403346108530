<template>
    <div>
        <img src="img/banner-0.jpg" class="banone pc">
        <img src="img/web-baner1.jpg" class="banone mobile">
        <div class="title">
            <p>
                <i>ORGANIZATION STRUCTURE</i>
            </p>
        </div>
        <img src="img/zx-7.jpg" class="banone pc ">
        <img src="img/zx-11.jpg" class="banone mobile">
        <div></div>
    </div>
</template>

<script>
export default {

}
</script>

<style>

</style>