<template>
  <section>
    <div class="bisj">
         <!--资质荣誉-->
        <div class="title">
            <p>
                <i>{{!English?'资质荣誉':'QUALIFICATIONS'}}</i>
            </p>
            <span v-if="!English">Qualifications </span>
        </div>
        <div id="marquee1">
            <vue-seamless-scroll
            class="warp nark"
            :data="Data.data"
            :class-option="defaultOption"
            >
            <ul class="lsiqs">
                <li v-for="item in Data.data" :key="item.id" @click="List(item.id,item.title)">
                    <img :src="Http+item.src">
                    <!-- <div>
                        <p>{{item.title}}</p>
                    </div> -->
                </li>
            </ul>
            </vue-seamless-scroll>
        </div>
        <div  class="xiangq" @click="ursi">
            <p>{{!English?'查看详情':'Detail'}}</p>
            <span>></span>
        </div>
    </div>
  </section>
</template>

<script>
import throttle from "lodash/throttle"
import vueSeamlessScroll from 'vue-seamless-scroll'
export default {
    props:['Data','Http','Banner','English'],
    components:{
        vueSeamlessScroll
    },
    data() {
        return {
            inp:0,
            inps:-1,
            ishow:0,
            defaultOption: {
                step: 0.4, // 数值越大速度滚动越快
                // limitMoveNum: 5, // 开始无缝滚动的数据量 this.dataList.length
                hoverStop: true, // 是否开启鼠标悬停stop
                direction: 2, // 0向下 1向上 2向左 3向右
                openWatch: true, // 开启数据实时监控刷新dom
                singleHeight: 0, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
                singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
                waitTime: 1000, // 单步运动停止的时间(默认值1000ms)
            },
        }
    },
    methods: {
        List(id,title){
            // this.$router.push({
            //     name:'List',
            //         query:{
            //             id:id,
            //             title:title,
            //         }
            // })
        },
        ursi(){
            if(!this.English){
                this.$router.push({
                name:'Idea',
                })
            }else{
                this.$router.push({
                name:'EnglishOrganization',
                })
            }

        },
        change:throttle(function(index){
            this.inps=this.inp,this.inp=index
        },1300)

    },
}
</script>

<style scoped>
    .bisj{
        background: url('@/assets/bj-1.jpg') no-repeat center center;
        overflow: hidden;
    }
    .xiangq{
        display: flex;
        justify-content:center;
        margin: 30px 0;
        cursor: pointer;
    }
    .xiangq p{
        font-size: 18px;
        font-weight: bold;
    }
    .xiangq span{
        border-radius: 50px;
        border: 1px #333 solid;
        display: block;
        margin-left: 12px;
        width: 18px;
        height: 18px;
        line-height: 18px;
        font-size: 14px;
        text-align: center;
        margin-top: 2px;
    }
    .warp {
    height: 100%;
    width: 100%;
    margin: 0 auto;
    overflow: hidden;
    margin-top: 40px;
    }
    .work-nrk {
        margin: 0 auto;
    }

    .work-text {
        margin: 0 auto;
        margin-top: 120px;
    }

    .work-text i {
        font-size: 38px;
        font-weight: bold;
        color: #fff;
    }

    .work-text span {
        font-size: 24px;
        color: #fff;
        margin-left: 25px;
    }

    .work-img {
        width: 100%;
        margin-top: 53px;
    }

    .work-img li {
        width: 25%;
        float: left;
        text-align: center;
        margin-bottom: 94px;
    }

    .work-img li img {
        padding: 24px;
        border: 2px #fff solid;
        border-radius: 50px;
    }

    .work-img li p {
        margin-top: 40px;
        color: #fff;
        font-size: 18px;
        letter-spacing: 1px;
    }

    .pic {
        overflow: hidden;
        /*隐藏溢出*/
        height: 580px;
        margin: 0 auto;
        padding-top: 70px;
    }
    .lsiqs{
        list-style: none;
        padding: 0;
        margin: 0 auto;
        display: flex;
    }
    .lsiqs li{
        height: 230px;
        width: 320px;
        margin-left: 30px;
        overflow: hidden;
        position: relative;
        cursor: pointer;
    }
    .lsiqs li:hover img {
        transform: scale(1.02);
        transition: all 0.5s ease; 
    }
    .lsiqs li img{
        width: 100%;
        height: 230px;
    }
    .lsiqs li div{
        position: absolute;
        bottom: 0;
        left: 0;
        width: 350px;
        text-align: center;
        height: 45px;
        line-height: 45px;
        background-color: rgb(0 0 0 / 60%);
    }
    .lsiqs li div p{
        color: #fff;
    }
    .pic ul li {
        float: left;
        height: 580px;
        width: 100px;
    }

    .pic ul .pic1 {
        background-position: center center;
        /*图片居中  等比例*/
        background-repeat: no-repeat;
        cursor: pointer;
        /*图片不平铺*/
    }
    .active{
        animation: mymove 1.2s ease-in;
        animation-iteration-count: 1;
        animation-fill-mode: forwards;
    }
    .actives{
        animation: mymoves 1.2s ease-in;
        animation-iteration-count: 1;
        animation-fill-mode: forwards;
    }
    .eleactive {
    flex: 1 1 15%;
    }

    .eleactive > img {
    width: 100%;
    height: 100%;
    }
    .txt {
        background-color: #000;
        background: rgba(0, 0, 0, .5);
        height: 85px;
        width: 100%;
        min-width: 1300px;
        font-size: 22px;
        position: absolute;
        left: 0;
        bottom: 0;
    }

    .txt p {
        float: left;
        color: #fff;
    }

    .pic ul {
        overflow: hidden;
    }

    .pic li {
        position: relative;
        overflow: hidden;
    }

    .txt .p1 {
        width: 100%;
        text-align: center;
        margin: 0 auto;
        line-height: 85px;
    }

    .work-text-two {
        margin-top: 66px;
    }

    .work-text-two i {
        color: #333;
    }

    .work-text-two span {
        color: #333;
    }
    @keyframes mymove {
        from{
            width: 115px;
        }
        to{
            width: 1150px;
        }
    }
    @keyframes mymoves {
        from{
            width: 1150px;
        }
        to{
            width: 115px;
        }
    }
    @media (max-width: 768px){
        .warp{
            margin-top: 20px;
        }
        .pic{
            display: none;
        } 
        .work-img{
            margin-top: 10%;
        }
        .work-text i{
            font-size: 24px;
        }
        .work-text span{
            font-size: 12px;
        }
        .work-text img{
            width: 70%;
        }
        .work-text {
            margin-top: 6%;
        }
        .pic{
            padding-top: 20px;
        }
        .pic1 {
            background-size: 100%;
        }
    }
    @media (max-width: 768px){
        .lsiqs li{
            width: 180px;
            height: 150px;
        }
        .lsiqs li img{
            height: 150px;
        }
    }
</style>