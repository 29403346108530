<template>
      <div class="nrk">
        <ul class="News">
            <li v-for="item in data.data" :key="item.id" @click="Details(item.id)">
                <div class="date">
                     <h1>{{item.create_time.slice(-2)}}</h1>
                    <span>{{item.create_time.slice(0,7)}}</span>
                </div>
                <div class="name">
                    <h1>{{item.description||item.title}}</h1>
                </div>
                <img :src="item.logo?http+item.logo:'img/zx-1.jpg'">
            </li>
        </ul>
    </div>
</template>

<script>
import{mapState} from'vuex';
export default {
    name:'NewsList',
    data() {
        return { 
        }
    },    
    computed:{
        ...mapState({
            http:state=>state.share.Http
        })
    },
    props:['data','ititem'],
    methods:{
      Details(id){
        this.$emit('getAreaInfo',id);
        // this.$router.push({
        //     name:'DesignerDetailsPages',
        //         query:{
        //             id:id,
        //         }
        // })
      },
    }
}
</script>

<style scoped>
    .News{
        width: 100%;
        overflow: hidden;
        margin: 20px 0;
    }
    .News li{
        border: 1px #eee solid;
        width: 95%;
        padding: 2%;
        overflow: hidden;
        margin-bottom: 30px;
        display: flex;
        align-items:center;
        justify-content: space-between;
    }
    .News li .date{
        width: 10%;
        float: left;
        border-right: 1px #eee solid;
        text-align: center;
        overflow: hidden;
    }
    .News li .date h1,.News li .name h1{
        font-size: 18px;
        color: #333;
        overflow : hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
    }
    .News li .date span,.News li .name span{
        font-size: 16px;
        margin-top: 16px;
        display: block;
    }
    .News li .name{
        width: 67%;
        float: left;
        margin-left: 3%;
    }
    .News li:hover{
        border: 1px #0019a7 solid;
        cursor: pointer;
    }
    .News li .name span{
        text-align: justify;
        text-indent: 2em;
        overflow : hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        line-height: 28px;
        color: #666666;
    }
    .News li img{
        width: 15%;
        float:right;
    }
    @media (max-width: 768px){
        .News li .date h1,.News li .name h1{
            font-size: 18px;
            color: #0019a7;
        }
        .News li .name span{
            font-size: 14px;
        }
        .News li img{
            display: none;
        }
        .News li .name{
            width: 69%;
        }
        .News li .date{
            width: 24%;
        }
    }
</style>
