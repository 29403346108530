<template>
    <div>
        <img src="img/banner-1.jpg" class="nark img-banner">
        <div class="address-img">
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            text:{
                h1:'欢迎惠顾吉林医药设计院有限公司网站！',
                span:'吉林医药设计院有限公司是中国医药工程设计协会副会长单位，具有国家住房和城乡建设部批准的化工、石化、医药行业甲级设计资质，国家发展和改革委员会批准的甲级工程咨询资质；拥有一大批工程咨询、工程设计、工程施工、工程监理、药品认证咨询的行业专家和工程技术人员。我们秉承诚实信守的原则，坚持技术至上，科研创新，具有多项专有技术，努力为客户项目提供优质的、全过程的工程技术服务，让客户实现建设项目的设想，我们的追求就是让顾客项目成功，顾客项目的成功就是我们的成功。',
                pone:'创优秀设计交天下朋友！',
                ptoe:' 愿与各界同仁志士竭诚合作，共创美好未来！'
            }
        }
    },
}
</script>

<style scoped>
    .address{
        background: url(@/assets/zx-11.jpg) center center;
        overflow: hidden;
        padding:  0 0 3% 0;
        color: #fff;
        margin: 40px 0;
    }
    .address-img{
        background: url(@/assets/zx-12.jpg) center center;
        overflow: hidden;
        height: 1179px;
    }
    .address .nrk h1{
        color: #fff;
    }
    .address .nrk span{
        color: #fff;
        font-size: 18px;
        text-align: justify;
        text-indent: 2em;
        display: block;
        line-height: 26px;
    }
    .address .nrk p{
        font-size: 18px;
        margin-top: 30px;
        color: #fff;
        text-align: center;
    }
</style>