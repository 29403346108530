<template>
    <div class="nrk">
        <Details  :data='data'/>
    </div>
</template>

<script>
 import{mapState} from'vuex';
export default {
    activated() {
        this.$store.dispatch('PostPjInfo',{id:this.$route.query.id});
    },
    computed:{
        ...mapState({
            data:state=>state.share.PjInfo,
        })
    },
}
</script>

<style>

</style>