<template>
    <div>
        <img src="img/banner-5.jpg" class="nark img-banner">
            <div class="title">
                <p>
                    <i>联系我们</i>
                </p>
                <span>Contact Us</span>
            </div>
        <div class="lisd">
            <div class="lxwm nrk">
                <ul class="diz">
                    <li>
                        <img src="img/xiwm-2.jpg">
                        <p>{{Main.mobile}}</p>
                    </li>
                    <li>
                        <img src="img/xiwm-3.jpg">
                        <p>{{Main.email}}</p>
                    </li>
                    <li>
                        <img src="img/xiwm-4.jpg">
                        <p>{{Main.address}}</p>
                    </li>
                </ul>
                <img src="img/xiwm-1.jpg" class="tps">
            </div>
            <!-- <div class="nrk">
                <Baidu-Map/>
            </div> -->
        </div>
    </div>
</template>

<script>
import {mapState} from 'vuex'
import SubNavigation from '@/components/pc/SubNavigation'
import BaiduMap from '@/components/BaiduMap'
export default {
    components:{
      SubNavigation,
      BaiduMap
    } ,
     mounted() {
        this.$store.dispatch('PostMain');
    },
    computed:{
        ...mapState({
            Main:state=>state.share.Main,
            })
    }
}
</script>

<style scoped>
.lxwm{
    overflow: hidden;
    width: 1100px;
    margin: 0 auto;
}
.tps{
    float: right;
}
.lxwm .diz{
    width: 40%;
    float: left;
    margin-top: 80px;
}
.lxwm .diz li{
    display: flex;
    justify-content: left;
    margin-bottom: 20px;
}
.lxwm .diz li img{
    width: 35px;
    height: 35px;
}
.lxwm .diz li p{
    line-height: 35px;
    margin-left: 5px;
    font-size: 20px;
}
.employ {
    width: 90%;
    margin: 12px auto;
}

.employ h1 {
    text-align: center;
    color: #333333;
    font-size: 40px;
    padding: 60px 0 60px 0;
    font-weight: bold;
    overflow: hidden;
}

.employ p {
    text-align: justify;
    width: 100%;
    margin-bottom: 28px;
    font-size: 13px;
    color: #666666;
}
.lisd{
    width: 100%;
    overflow: hidden;
    padding: 20px 0;
}
.servicen-emr {
    margin: 0 auto;
    overflow: hidden;
    margin-bottom: 30px;
}

.servicen-emr li {
    float: left;
    width: 16%;
    padding: 26px 1.5%;
    background-color: #fff;
    overflow: hidden;
    margin-left: 1%;
    border: 1px #fff solid;
    height: 80px;
    border-radius: 10px;
}
.servicen-emr li h2{
    display: block;
    width: 100%;
}
.servicen-emr li>div>span{
    color: #999;
    margin-top: 12px;
    display: block;
}
.servicen-emr li:nth-child(1){
    margin-left: 0;
}
    @media (max-width: 768px){
        .tps{
            width: 90%;
            margin: 0 auto;
        }
        .lxwm .diz{
            width: 90%;
            margin: 0 auto;
            float: none;
        }
        .lxwm{
            width: 100%;
        }
        .servicen-emr li{
            width: 40%;
            margin-bottom: 20px;
            border:none;
            padding: 26px 3%;
        }
        .servicen-emr li:nth-child(2n){
            margin-left: 4%;
        }
        .Servic{
            margin-top: 10px;
        }
        .Servic p{
            font-size: 22px;
        }
    }
</style>