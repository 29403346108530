<template>
    <div>
        <img src="img/banner-5.jpg" class="nark img-banner">
        <sub-navigation :data='floatdada'></sub-navigation>
        <keep-alive>
            <router-view></router-view>
        </keep-alive>
    </div>
</template>

<script>
import {mapState} from 'vuex'
import SubNavigation from '@/components/pc/SubNavigation'
export default {
    components:{
      SubNavigation
    } ,
    computed:{
        ...mapState({
            floatdada:state=>state.share.floatdada
        })
    },
}
</script>

<style>

</style>