<template>
    <!--业务范围-->
    <div class="work nark">
        <div class="work-text nrk wow animate__animated animate__fadeInTopLeft">
            <p>
                <i>业务范围</i>
                <span>MAIN BUSINESS SCOPE</span>
            </p>
            <img src="./img/tu3.png">
        </div>
        <div class="work-nrk nrk">
            <ul class="work-img">
                <li class="wow animate__animated animate__bounceInUp">
                    <img src="./img/xip_1.png">
                    <p>大中型医药工程设计</p>
                </li>
                <li class="wow animate__animated animate__bounceInUp" data-wow-delay="0.2s">
                    <img src="./img/xip_2.png">
                    <p>食品工程设计</p>
                </li>
                <li class="wow animate__animated animate__bounceInUp" data-wow-delay="0.4s">
                    <img src="./img/xip_3.png">
                    <p>仓储物流工程设计</p>
                </li>
                <li class="wow animate__animated animate__bounceInUp" data-wow-delay="0.6s">
                    <img src="./img/xip_4.png">
                    <p>药用包材工程设计</p>
                </li>
                <li class="wow animate__animated animate__bounceInUp" data-wow-delay="0.8s">
                    <img src="./img/xip_5.png">
                    <p>手术室工程设计</p>
                </li>
                <li class="wow animate__animated animate__bounceInUp" data-wow-delay="1.0s">
                    <img src="./img/xip_6.png">
                    <p>医疗器械工程设计</p>
                </li>
                <li class="wow animate__animated animate__bounceInUp" data-wow-delay="1.2s">
                    <img src="./img/xip_7.png">
                    <p>日用化工工程设计</p>
                </li>
                <li class="wow animate__animated animate__bounceInUp" data-wow-delay="1.4s">
                    <img src="./img/xip_8.png">
                    <p>生物安全实验室</p>
                </li>
            </ul>
            <div class=" chakn">
            <router-link :to="{name:'Range'}">查看更多</router-link>    
            </div>
        </div>
    </div>
</template>

<script>
export default {

}
</script>

<style scoped>
    .chakn{
        width: 120px;
        height: 40px;
        line-height: 40px;
        font-size: 14px;
        text-align: center;
        color: #fff;
        border: 1px #eee solid;
        border-radius: 10px;
        margin: 0 auto;
        margin-bottom: 40px;
    }
    .chakn a{
        display: block;
        color: #fff;
        overflow: hidden;
    }
    .work {
    margin-top: 2%;
    background: url(./img/beij_1.jpg) no-repeat center center;
    overflow: hidden;
    }

    .work-nrk {
        margin: 0 auto;
    }

    .work-text {
        margin: 0 auto;
        margin-top: 120px;
    }

    .work-text i {
        font-size: 38px;
        font-weight: bold;
        color: #fff;
    }

    .work-text span {
        font-size: 24px;
        color: #fff;
        margin-left: 25px;
    }

    .work-img {
        width: 100%;
        margin-top: 53px;
        overflow: hidden;
    }

    .work-img li {
        width: 25%;
        float: left;
        text-align: center;
        margin-bottom: 94px;
        cursor:pointer
    }

    .work-img li img {
        padding: 24px;
        border: 2px #fff solid;
        border-radius: 50px;
    }

    .work-img li p {
        margin-top: 40px;
        color: #fff;
        font-size: 18px;
        letter-spacing: 1px;
    }
    .work-img li:hover img {
        background-color: #ffa918;
    }
    @media (max-width: 768px){
        .work-img{
            margin-top: 10%;
        }
        .work-text i{
            font-size: 24px;
        }
        .work-text span{
            font-size: 12px;
        }
        .work-text img{
            width: 70%;
        }
        .work-text {
            margin-top: 10%;
        }
        .work-img li img{
            padding: 10px;
            width: 50%;
        }
        .work-img li p{
            margin-top: 8px;
            font-size: 12px;
        }
        .work-img li{
            margin-bottom: 8%;
        }
    }
</style>