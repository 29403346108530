var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"nrk"},[_vm._m(0),_vm._m(1),_c('div',{staticClass:"top-text pps wedb"},[_c('img',{staticClass:"img",attrs:{"src":_vm.Http+_vm.Main.er}}),_vm._m(2)]),_vm._m(3),_vm._m(4),_vm._m(5),_vm._m(6),_vm._m(7)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"top-text pps"},[_c('h1',[_vm._v("关注")]),_c('h2',[_vm._v("荣祥热力")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"top-text"},[_c('h1',{staticClass:"nns"},[_vm._v("在家")]),_c('h2',{staticClass:"tts"},[_vm._v("缴纳热费")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"jies"},[_c('h3',[_vm._v("公众号：九台荣祥供热有限公司")]),_c('h3',{staticClass:"ppss"},[_vm._v("缴费方式：")]),_c('div',{staticClass:"jies-text"},[_c('p',[_vm._v("1.可以在窗口使用现金缴费")]),_c('p',[_vm._v("2.关注九台荣祥供热有限公司微信公众号，在服务大厅缴费")]),_c('p',[_vm._v("3.在荣祥热力指定的银行进行缴费")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"img-1"},[_c('img',{attrs:{"src":"img/zx-14.jpg"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"top-text ppssss"},[_c('h3',{staticClass:"fone"},[_vm._v("足不出户、轻松缴费")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"top-text ppss"},[_c('h3',[_vm._v("第一次需要绑定缴费，以后均不需要绑定，可以直接缴费")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"top-sne pps"},[_c('div',{staticClass:"jies"},[_c('h3',{staticClass:"lset"},[_vm._v("绑定方法注意事项")]),_c('div',{staticClass:"jies-text jies-re"},[_c('p',[_vm._v("· 可以用用户卡号和用户名进行绑定")]),_c('p',[_vm._v("· 小区绑定可以模糊搜索，百俊星品搜家百俊即可")]),_c('p',[_vm._v("· 一个微信号可以绑定多个小区、在我的、我的房屋")]),_c('p',[_vm._v("· 缴费成功后，如需要正规发票，可以到窗口申请")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"top-text pps bottom wedb"},[_c('p',[_vm._v("24小时客服务电话:0431-82333879")]),_c('p',{staticClass:"mas-1"},[_vm._v("地址:吉林省长春市九台区新华大街687号")])])
}]

export { render, staticRenderFns }