<template>
    <!--中间内容-->
    <div class="bottom-btn">
        <!-- <div class="detiqsz">
            <p>协会动态</p>
            <span></span>
            <p>律协党建</p>
            <span></span>
            <p class="dioxqit">文章详情</p>
        </div> -->
        <div class="detiqsz" @click="back">
            <span></span>
            <p class="dioxqit">返回</p>
        </div>
        <div class="detali-text">
            <h1>{{data.title}}</h1>
            <em>发布日期：{{data.create_time}}&emsp;&emsp;来源：{{data.title}}</em>
            <div v-html="ursl(data.content)" :class="inst?'nnslw':smallContent"></div>
        </div>
    </div>
</template>

<script>
import {mapState} from "vuex";
export default {
    name:'Details',
    props:['data','inst'],
    computed:{
        ...mapState({
            Http:state=>state.share.Http
        })
    },
    methods: {
    //     ursl(content=''){
    //         if(content!=''){
    //             let noticeContent = content;
    //             let imgReg = RegExp(/<img [^>]*src=['"]([^'"]+)[^>]*>/gi);//定义正则，筛选出img元素
    //             let _video = '<video src="'
    //             let matchres=noticeContent.match(imgReg);
    //             if(matchres){
    //             matchres.forEach((item,index)=>{
    //                 let _tempStr = item.slice(0,item.length-2);
    //                 let _index = _tempStr.indexOf('/');
    //                 let _str= _tempStr.substring(0, _index) + this.Http +  _tempStr.substring(_index, _tempStr.length) + '"/>';
    //                 noticeContent=noticeContent.replace(item,_str)
    //             })
    //             if (noticeContent.includes(_video)) {
	// 					noticeContent = noticeContent.replace(/<source src=\"/g, '<source src="ui-iva' + this.Http)
	// 			}
    //             }
    //             return noticeContent
    //         }
    //     },

        ursl(htmlStr) {
				console.log('htmlStr', htmlStr) // 从接口获取到富文本内容
				let apiSource = this.Http; // 地址端口
				const _imgL = '<img src="';
				const _video = '<video '
				const _href = '<a href="'
			
				if (htmlStr) {
					// 解析富文本，获取所有图片标签或链接
					const regex = /<img.*?src=["'](.*?)["']/g;
					const images = [];
					let match;
					while ((match = regex.exec(htmlStr)) !== null) {
						images.push(match[1]);
					}
					// 遍历每个图片链接，判断是否有前缀
					for (const image of images) {
						if (image.startsWith('http://') || image.startsWith('https://')) {
							console.log(`${image} 包含前缀`);
						} else {
							console.log(`${image} 不包含前缀`);
							// 给没有前缀的图片URL添加apiSource前缀
                            console.log(htmlStr.includes(_imgL), '=====')
							if (htmlStr.includes(_imgL)) {
								htmlStr = htmlStr.replace(/<img src=\"/g,'<img style="width:100%;height:auto" class="ui-iva" src="'+ apiSource)
							}
						}
					}
					if (htmlStr.includes(_video)) {
                        
						htmlStr = htmlStr.replace(/<source src=\"/g, '<source src="' + apiSource)
					}
					if (htmlStr.includes(_href)) {
						htmlStr = htmlStr.replace(/<a href=\"/g, '<a href="' + apiSource)
					}
			
				}
				console.log('htmlStr', htmlStr)
				return htmlStr;
		},
        back(){
            this.$router.back()
        }
    },
    filters: {
       timeFmtTime(e) {
            console.log(e)
            return this.ursl(e)
        },
    }
}
</script>

<style scoped>
    .detiqsz {
        width: 100%;
        padding: 30px 0;
        overflow: hidden;
        cursor: pointer;
    }

    .detiqsz p {
        float: left;
        color: #333;
        margin-right: 4px;
    }
     .smallContent>>>img {
        width: 100% !important;
        }
    .detiqsz span {
        width: 6px;
        height: 6px;
        border: 1px solid #333;
        border-left-width: 0;
        border-bottom-width: 0;
        transform: matrix(-0.71, 0.71, 0.71, 0.71, 0, 0);
        float: left;
        margin-left: 10px;
        margin-right: 4px;
        margin-top: 4px;
    }

    .detiqsz .dioxqit {
        color: #0019a7;
    }

    .detiqsz-text {
        width: 100%;
        overflow: hidden;
        background-color: #fff;
    }

    .detiqsz-textlet {
        width: 70%;
        padding: 6%;
        float: left;
    }

    .detiqsz-textlet h2 {
        font-size: 28px;
        color: #333;
        font-weight: bold;
    }

    /* .detiqsz-textlet text {
        display: block;
        width: 100%;
        padding: 30px 0;
        color: #999999;
        text-align: justify;
    } */

    /* .wexzq-we p {
        float: left;
        margin-right: 15px;
    } */

    .wexzq-we {
        color: #333;
    }

    .detiqsz-textresq {
        width: 25%;
        float: right;
        margin-top: 20px;
        margin-right: 3%;
    }

    .deaqxs {
        font-size: 18px;
        color: #333;
        text-align: center;
        margin-top: 40px;
        margin-bottom: 20px;
        font-weight: bold;
    }

    .det-aq li {
        line-height: 40px;
        width: 100%;
        border: 1px #122b50 solid;
        color: #122b50;
        text-align: center;
        margin-bottom: 20;
    }
    .detali-text {
        overflow: hidden;
        margin-top: 30px;
        /* text-align: center; */
        width: 100%;
    }

    .detali-text img {
        width: auto;
        display: block;
        margin: auto;
    }

    .detali-text h1 {
        font-size: 32px;
        font-weight: bold;
        color: #333;
        margin-bottom: 30px;
        text-align: center;
    }

    .detali-text em {
        text-align: center;
        color: #999999;
        display: block;
        margin-bottom: 40px;
        font-size: 14px;
    }

    /* .detali-text span {
        text-align: justify;
        display: block;
        margin-bottom: 30px;
        color: #333;
        font-size: 15px;
    }

    .detali-text i {
        display: inline-block;
        margin-left: 16px;
    }

    .detali-text img {
        margin: 0 auto;
    }

    .wenb p .giel {
        width: 30px;
        margin-right: 10px;
        margin-left: 10px;
    }

    .detali-text p {
        font-size: 14px;
        color: #666;
        margin-bottom: 20px;
        text-align: justify;
        line-height: 24px;
        text-indent: 2em;
    } */
    .nnslw>>>img {
        width: 60% !important;
        margin: 0 auto;
        display: block;
    }
    @media (max-width: 768px){
        .nnslw>>>img {
            width: 90% !important;
            margin: 0 auto;
            display: block;
        }
        .smallContent>>>img {
        width: 100% !important;
        height: auto !important;
        }
        .detali-text{
            margin-top: 0;
        }
        .detali-text h1{
            font-size: 26px;
        }
        .detali-text em{
            font-size: 12px;
        }
    }
</style>