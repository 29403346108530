<template>
    <div>
        <ul class="nrk work">
            <li v-for="item in Member.data" :key="item.id" @click="Details(item.id)">
                <div class="nmkimg">
                    <img v-lazy="Http+item.logo">
                </div>
                <span>{{item.title}}</span>
            </li>
        </ul>
        <Pagination 
        :pageNo="date.page" 
        :pageSize='date.limit' 
        :total="Member.total" 
        :continues="5"
        @getPageNo='getPageNo'
        />
    </div>
</template>

<script>
 import{mapState} from'vuex';
export default {
    data() {
        return {
            date:{
                cid:1,
                limit:9,
                page:1,
            }
        }
    },
    activated() {
        this.postNews(this.date)
    },
    computed:{
        ...mapState({
            Member:state=>state.share.Member,
            Http:state=>state.share.Http,
        })
    },
    methods: {
        Details(id){
            this.$router.push({
                name:'WorkDetails',
                    query:{
                        id:id,
                    }
            })
        },
        getPageNo(id){
            this.date.page=id
            this.postNews(this.date)
        },
        postNews(naml){
            this.$store.dispatch('PostMember',naml);
        }
    },
}
</script>

<style scoped>
    .work{
        overflow: hidden;
        margin: 20px auto;
    }
    .work li{
        width: 32%;
        float: left;
        background-color: #eee;
        margin-right: 2%;
        cursor: pointer;
        margin-bottom: 30px;
        overflow: hidden;
    }
    .work li:nth-child(3n){
        margin-right: 0;
    }
    .work li .nmkimg{
        height: 300px;
        overflow: hidden;
    }
    .work li img{
        width: 100%;
        height: 300px;
    }
    .work li:hover img {
        transform: scale(1.1);
        transition: all 0.5s ease;
    } 
    .work li span{
        text-align: center;
        display: block;
        margin: 34px 0;
        font-size: 18px;
        color: #164db2;
    }
    .work li:hover{
        background-color: #164db2;
    }
    .work li:hover span{
        color: #fff;
    }
    @media (max-width: 768px){
        .work li{
            width: 100%;
        }
        .work li .nmkimg,.work li img{
            height: auto;
        }
        .work li span{
            margin: 24px 0;
        }
    }
</style>