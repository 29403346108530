<template>
    <div>
        <!--资质荣誉-->
        <div class="title">
            <p>
                <i>公司简介</i>
            </p>
            <span>Heating company</span>
        </div>
        <div class="nrk company">
            <p class="litext">
                <span>长春市九台区荣祥热力有限公司成立于2009年8月13日，通过当时九台市人民政府招商引资进驻九台市，原名九台荣祥供热有限公司，2023年5月25日经长春市市场监督管理局核准，正式更名为长春市九台区荣祥热力有限公司。</span>
                <span>公司于2009年建设集中供热管网，2010年取消原九台城区100多个小锅炉房供热， 2014年九台荣祥与华能九台电厂合作，实现九台城区热电联产供热</span>
                <span>目前公司总供热面积近1100万平方米，员工180人，管理人员18人，水暖工程师10人，电气工程师6人，各专业技术人员60多人，服务人员近100人，是集建设、生产、经营为一体的综合性供热服务企业。公司一次网总长度128公里，二次网总长度268公里，管网健康状态良好，9台备用锅炉，97个换热站。</span>       
            </p>
            <img src="../img/xt-1.jpg" class="litext-text">
                <!--公司简介-->
        </div>
        <div class="course nark">
            <div class="course-nr">
                <ul class="course-text">
                    <li>
                        <div><i>{{figure1}}</i><p>万+</p></div>
                        <span class="wow animate__animated animate__bounce">为九台市约12万户居民及非居民用户提供供热服务</span>
                    </li>
                    <li>
                        <div><i>{{figure2}}</i><p>人</p></div>
                        <span class="wow animate__animated animate__bounce" data-wow-delay="0.3s">员工</span>
                    </li>
                    <li>
                        <div><i>{{figure3}}</i><p>万平方米</p></div>
                        <span class="wow animate__animated animate__bounce" data-wow-delay="0.6s">在网面积</span>
                    </li> 
                </ul>
            </div>
        </div>
        <div class="nrk bottom">
            <span>新时期公司以“促进九台区经济发展、改善城市生活环境，提高居民供热质量”为宗旨，以“树立荣祥形象打造品牌企业”为目标，秉承“优质供热、共创和谐，服务百姓、奉献社会”的经营理念，强化管理，热诚服务，赢得了社会各界的广泛赞誉。先后被评为“全省冬季供热安全运行和规范服务标兵单位”、“吉林省钻级百姓口碑金奖单位”、“长春市先进单位”。2016年被吉林省住房和城乡建设厅评为吉林省规范化考核优秀单位。</span>
            <span>公司全体员工将继续发扬公司“创新经营，敢为人先”的企业精神，以“家和义爱诚”企业文化为引领，精细管理，节能降耗，安全运行，以优质、高效、专业的供热获得企业的经济效益和社会效益。</span>
        </div>
    </div>
    
</template>

<script>
import {mapState} from "vuex"
export default {
    data() {
        return {
            figure1: 12,
            figure2: 180,
            figure3: 1100,
            figure4: 4700,
            faiked: false,
            liedn: 0,
            linq: 0,
            data:{
                type:1
            },
            n:'./img/n-'
        }
        
    },
    activated() {
        // this.$store.dispatch('PostAbout',this.data);
    },
    computed:{
		...mapState({
			study:state=>state.share.study,
            About:state=>state.share.About
		})
        
	},
}
</script>

<style scoped>
    .company{
        margin: 2% auto;
        overflow: hidden;
    }
    .litext{
        width: 60%;
        float: left;
        padding-top: 1%;
        font-size: 14px;
        line-height: 30px;
        text-align: justify;
    }
    .litext-text{
        width: 36%;
        float: right;
    }
    .litext span{
        font-size: 24px;
        display: block;
        color: #333;
        margin-bottom: 30px;
        text-align: justify;
        text-indent: 2em;
    }
    .bottom {
        overflow: hidden;
        margin-bottom: 65px;
    }
    .bottom span{
        font-size: 24px;
        display: block;
        color: #333;
        margin-top: 65px;
        text-align: justify;
        text-indent: 2em;
    }
    .course {
        width: 100%;
        background: url(../img/beij_2.jpg) no-repeat center center;
        overflow: hidden;
        margin-top: 90px;
    }

    .course-nr {
        width: 1435px;
        margin: 0 auto;
        margin-top: 60px;
        margin-bottom: 60px;
    }

    .course-text {
        width: 100%;
        overflow: hidden;
    }

    .course-text li {
        float: left;
        width: 33%;
    }
    .course-text li:nth-child(2){
        text-align: center;
    }
    .course-text li:nth-child(2) div{
        text-align: center;
        margin-left: 160px;
    }
    .course-text li:nth-child(3){
        text-align: center;
    }
    .course-text li:nth-child(3) div{
        text-align: center;
        margin-left: 140px;
    }
    .course-text li>div{
        font-size: 76px;
        color: #fff;
        font-weight: bold;
        overflow: hidden;
    }

    .course-text li p {
        font-size: 20px;
        float: left;
    }

    .course-text span {
        font-size: 20px;
        margin-top: 30px;
        color: #fff;
        display: block;
    }

    .course-text li>div i {
        float: left;
    }
    .course-bottom-text {
        font-size: 26px;
        margin-top: 70px;
        color: #fff;
        text-align: justify;
        line-height: 50px;
        margin-bottom: 200px;
    }
    .liesc{
        margin-top: 40px;
        overflow: hidden;
    }
    .liesc>div{
        width: 43%;
        float: left;
        margin-right: 4%;
        border: 1px #eee solid;
        border-radius: 15px;
        padding: 1% 2%;
        margin-bottom: 24px;
    }
    .liesc>div:nth-child(2n){
        margin-right: 0%;
    }
    .letr{
        width: 26%;
        border-right: 1px #eee solid;
        float: left;
        margin-top: 10px;
    }
    .letr img{
        width: 60%;
        margin: 0 auto;
        display: block;
    }
    .letr p{
        text-align:center;
        font-size: 16px;
        font-weight: bold;
        margin-top: 6px;
    }
    .liesc .rlis{
        width: 67%;
        float: right;
        font-size: 14px;
        text-align: justify;
        display: flex;
        align-items:center;
        justify-content: space-between;
        height: 140px;
        overflow: hidden;
        text-indent: 2em;
    }
    .liesc .rlis span{
        display: block;

    }
    @media (max-width: 768px){
        .litext span{
            font-size: 18px;
        }
        .bottom span{
            font-size: 18px;
            margin-top: 30px;
        }
        .liesc .rlis{
            height: auto;
            padding: 16px 0;
        }
        .copyright{
            margin-top: 20px;
        }
        .bottomNavigation{
            display: none;
        }
        .copyright-title img{
            position:unset;
            margin:0 auto;
            display: block;
            margin-top: 20px;
        }
        .copyright-title span{
            display: block;
            margin: 0 auto;
            margin-top: 12px;
        }
        .litext{
            width: 100%;
            font-size: 14px;
            line-height: 30px;
        }
        .litext-text {
            width: 100%;
        }
        .liesc>div{
            width: 95%;
        }
        .liesc .rlis{
            font-size: 13px;
        }
        .liesc .rlis{
            width: 70%;
        }
        .liesc .rlis span{
            width: 96%;
        }
        .letr{
            margin-top: 25px;
        }
        .liesc:nth-child(2) div .rlis{
            height: 200px;
        }
        .course{
            margin-top: 40px;
        }
    }
    @media (max-width: 768px){
        .course-bottom-text {
            font-size: 14px;
            margin-top: 4px;
            line-height:26px;
            margin-bottom: 20px;
        }
        .course-text span{
            font-size: 14px;
            text-align: center;
            margin-top: 12px;
        }
        .course-text li{
            width: 100%;
            margin-bottom: 20px;
        }
        .course-text li:nth-child(2) div,.course-text li:nth-child(3) div{
            margin-left: 0;
        }
        .course-text li>div{
            font-size: 40px;
            display: flex;
            justify-content: center;
        }
        .course-text li:nth-child(2) i{
            margin-left: 16px;
        }
        .course-text li p{
            font-size: 24px;
        }
        .course-text li:nth-child(3) i{
            margin-left: 12px;
        }
        .course-nr{
            margin-top: 40px;
            width: auto;
            margin-bottom: 20px;
        }
    }
</style>