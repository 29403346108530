<template>
  <div>
    <img src="img/banner-0.jpg" class="banone pc">
    <img src="img/web-baner1.jpg" class="banone mobile">
    <div class="otice">
      <div class="oticeFrame nrk">
        <img src="img/ywdj.jpg" class="oticeIcons">
      </div>
    </div>
    <div class="yas">
    <News-English  :English='n'/>
    </div>
    <Performance :Data='Cate' :Http='Http' :Banner='Banner1' :English='n'/>
    <div class="nrk jinz">
      <div class="address">
        <div class="address-top">
          <p>GENERAL MANAGER’S ADDRESS</p>
          <img src="img/hh.png">
        </div>
        <div class="address-text">
          <p>DEAR USERS IN JIUTAI DISTRICT, ON BEHALF OF RONGXIANG HEATING CO., LTD., IN JIUTAI DISTRICT CHANGCHUN, I WOULD LIKE TO EXPRESS MY HEARTFELT GRATITUDE TO THE VAST NUMBER OF USERS AND FRIENDS FROM ALL WALKS OF LIFE WHO CARE AND SUPPORT THE DEVELOPMENT OF HEATING IN JIUTAI DISTRICT!</p>
          <p>IN RECENT YEARS, RONGXIANG HEATING CO., LTD. HAS ENTERED A STAGE OF ROBUST DEVELOPMENT, AN ELEVATION IN MANAGEMENT AND SERVICE QUALITY, AND A PHASE WHERE ALL EMPLOYEES BOOST THEIR SPIRITS, UNITE AND STRIVE TOGETHER, ADVANCING THROUGH REFORM AND INNOVATION!</p>
          <span class="gd" @click="dj()">></span>
        </div>
        <img src="@/assets/zx-16.jpg" class="address-img">
      </div>
    </div>
  </div>
</template>

<script>
import{mapState} from'vuex';
import Banner from '@/components/pc/Banner';
import Performance from '@/components/pc/Performance'
import NewsEnglish from '@/components/pc/NewsEnglish';
export default {
    data() {
      return {
        n:1
      }
    },
    components:{
      Banner,
      NewsEnglish,
      Performance
    },
    computed:{
        ...mapState({
            Cate:state=>state.share.Cate,
            Banner1:state=>state.share.Banner1,
            floatdada:state=>state.share.floatdada,
            Notice:state=>state.share.Notice,
            News:state=>state.share.News,
            NewsNav:state=>state.share.NewsNav,
            Http:state=>state.share.Http
        })
    },
    methods: {
        dj(){
          this.$router.push({
            name:'EnglishNews',
          })
        },
        EnglishNews(){
            this.$router.push({name:'EnglishNews'})
        },
    },
}
</script>

<style scoped>
  .address-img{
    float: right;
    margin-top: 45px;
    border-right: 10px;
    border: 3px #fff solid;
    border-radius: 3px;
  }
  .jinz{
    background-color: #315ccf;
    overflow: hidden;
    margin-top: 86px;
    padding-bottom: 60px;
    margin-bottom: 60px;
  }
  .address{
    width: 94%;
    padding: 3%;
  }
  .oticeIcons{
    float: right;
    margin-top: 14px;
  }
  .address-top p{
    float: left;
    color: #fff;
    font-size: 20px;
  }
  .address-top img{
    float: left;
    margin-left: 10px;
  }
  .address-text{
    width: 560px;
    float: left;
  }
  .address-text p{
    display: block;
    color: #fff;
    text-align: justify;
    text-indent: 2em;
    font-size: 16px;
    margin-top: 30px;
    line-height: 28px;
  }
  .gd{
    border-radius: 50px;
    border: 1px #fff solid;
    color: #fff;
    display: block;
    margin-left: 12px;
    width: 22px;
    height: 22px;
    line-height: 20px;
    font-size: 14px;
    text-align: center;
    float: right;
    margin-top: 20px;
  }
  .address-top{
    margin-top: 45px;
    overflow: hidden;
    padding-bottom: 10px;
    border-bottom: 2px #fff solid;
  }
  .otice{
      width: 100%;
      overflow: hidden;
      box-shadow: 0px 3px 3px #d9d9d9;
  }
  .oticeFrame{
      height: 70px;
      margin: 0 auto;
  }
  .yas{
      background-color: #f7f7f7;
      overflow: hidden;
  }
    .xlwm{
        width: 100%;
        margin: 30px 0;
        display: block;
        cursor: pointer;
    }
    @media (max-width: 768px){
      .jinz{
        margin-top: 40px;
      }
      .address-img{
        width: 100%;
      }
      .address-text{
        width: 100%;
      }
      .xlwm{
        display: none;
      }
      .oticeIcons{
        width: 60%;
        margin: 0 auto;
        display: block;
        margin-top: 14px;
        float: none;
      }
      .oticeFrame{
        height: 54px;
      }
    }
</style>