<template>
    <div class="detecti">
        <div class="nrk">
            <div class="Servic nrk">
                <p>检测中心</p>
            </div>
            <img src="img/zx-11.jpg">
            <div class="detecti-text">
                <span>吉林省医药净化环境检测中心创建于1990年，是从事制药、医疗器械、食品等领域的洁净厂房以及科研机构、医院、疾控质检中心等领 域的洁净实验室空调净化系统检测的专业机构。我们一直秉承“上乘质量，科学数据，优质服务，及时准确出具检测结果” 的服务宗旨自成立至今已经完成了吉林省、黑龙江省、内蒙古自治区、上海市、江苏省等地的二百多家制药厂、食品厂、医疗器械厂、医院、质检中心、科研机构 实验室的检测工作，取得了用户的广泛认可。</span>
                <span>丰富的检测经验、齐全的仪器配备、优秀的人才配置、以及热情和先进的服务理念，使我们获得了吉林省质量技术监督局颁发的计量认证资质，能够对空调系统、洁净环境性能做出客观有效的技术评价，提供的检测报告真实公正，具有国家认可的法律效力。</span>
                <span>检测中心技术力量雄厚，检测人员均经过相关部门培训，并取得了相应的证书。检测仪器可靠齐全，可以完成洁净度级别、风量、压差、沉降菌、浮游菌、气流流型、甲醛浓度等项目的检测。</span>
                <span>收费合理，服务周到，检测速度快，出具报告及时，检测报告可以有效作为GMP认证、工程评价以及验收的依据。</span>
                <span>欢迎来新老朋友来我中心指导工作、洽谈业务，我们将竭诚为您提供优质的服务！</span>
            </div>
            <div class="detecti-mans">
                <div class="noe">
                    <span>● 联系人：潘峰</span>
                    <span>● 电子邮箱：peter0431＠126.com </span>
                    <span>● 传真：0431-85649252 </span>
                    <span>● 网址：www.jlpdi.net</span>
                    <span>● 电话：18686618169、13069110608、 0431-85649252  </span>
                </div>
                <img src="img/zx-12.jpg">
            </div>
        </div>
    </div>
</template>

<script>
export default {

}
</script>

<style scoped>
    .detecti{
        width: 100%;
        background: url('@/assets/zx-13.jpg') center bottom no-repeat;
        overflow: hidden;
        margin-top: 20px;
    }
    .detecti img{
        display: block;
        margin: 40px auto;
    }
    .detecti-text{
        margin: 0 auto;
    }
    .detecti-text span{
        text-align: justify;
        display: block;
        font-weight: bold;
        margin-bottom: 40px;
        text-indent: 2em;
        font-size: 16px;
    }
    .detecti-mans{
        overflow: hidden;
        width: 1200px;
        margin: 40px auto;
    }
    .detecti-mans .noe{
        width: 60%;
        float: left;
        margin-top: 40px;
    }
    .detecti-mans .noe span{
        display: block;
        margin-bottom: 20px;
        font-size: 16px;
        font-weight: bold;
    }
    .detecti-mans img{
        flex: right;
        width: 160px;
    }
</style>