<template>
      <div class="nrk">
        <div class="Servic ">
            <p>新闻中心</p>
            <span>—— 聚焦新闻资讯，了解行业知识 —— </span>
        </div>
        <ul class="News">
            <li>
                <div class="date">
                    <h1>18</h1>
                    <span>2023/05</span>
                </div>
                <div class="name">
                    <h1>第61届（2021年秋季）全国制药机械博览会开展</h1>
                    <span> 11月2日9时，第61届（2021年秋季）全国制药机械博览会在成都中国西部国际博览城正式拉开帷幕。吉林医药设计院有限公司、吉林医药食品工程有限公司、吉林省泽众流体技术有限公司、吉林清新伟业净化设备有限责任公司联合参加本届博览会。</span>
                </div>
                <img src="/img/xt-2.jpg">
            </li>
        </ul>
        <Pagination :pageNo="1" :pageSize='3' :total="90" :continues="5"/>
    </div>
</template>

<script>
export default {
    data() {
        return { 
        }
    },
    mounted() {
        this.$store.dispatch('PostNews')
    },
}
</script>

<style scoped>
    .News{
        width: 100%;
        overflow: hidden;
        margin: 20px 0;
    }
    .News li{
        border: 1px #eee solid;
        width: 95%;
        padding: 2%;
        overflow: hidden;
        margin-bottom: 30px;
        display: flex;
        align-items:center;
        justify-content: space-between;
    }
    .News li .date{
        width: 10%;
        float: left;
        border-right: 1px #eee solid;
        text-align: center;
        overflow: hidden;
    }
    .News li .date h1,.News li .name h1{
        font-size: 22px;
         color: #0019a7;
    }
    .News li .date span,.News li .name span{
        font-size: 16px;
        margin-top: 16px;
        display: block;
    }
    .News li .name{
        width: 67%;
        float: left;
        margin-left: 3%;
    }
    .News li:hover{
        border: 1px #0019a7 solid;
        cursor: pointer;
    }
    .News li .name span{
        text-align: justify;
        text-indent: 2em;
        overflow : hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        line-height: 28px;
        color: #666666;
    }
    .News li img{
        width: 15%;
        float:right;
    }
    @media (max-width: 768px){
        .News li .date h1,.News li .name h1{
            font-size: 18px;
            color: #0019a7;
        }
        .News li .name span{
            font-size: 14px;
        }
        .News li img{
            display: none;
        }
        .News li .name{
            width: 69%;
        }
        .News li .date{
            width: 24%;
        }
    }
</style>
