<template>
  <ul class="nrk tabulation" @click="clickid">
    <li v-for="(item,index) in data" :key="index" >
        <img :src="Http+item.src" :data-id='item.id'>
    </li>
  </ul>
</template>

<script>
import{mapState} from'vuex';
export default {
    name:'Tabulation',
    computed:{
        ...mapState({
            Http:state=>state.share.Http
        })
    },
    props:['data'],
    methods: {
        clickid(event){
            let element=event.target;
            if(element.dataset.id){
                this.$emit('Tabulaid',element.dataset.id)
            }
        }
    },
}
</script>

<style scoped>
    .tabulation{
        padding: 20rpx 0;
        overflow: hidden;
    }
    .tabulation li{
        width: 32%;
        float: left;
        margin-right: 2%;
        cursor: pointer;
        box-shadow: 0px 3px 3px #d9d9d9;
        border-radius: 10px;
        overflow: hidden;
        position: relative;
        overflow: hidden;
        height: 300px;
        margin-bottom: 40px;
    }
    .tabulation li:nth-child(3n){
        margin-right: 0;
    }
    .tabulation li img{
        width: 100%;
        min-height: 300px;
        display: block;
    }
    .tabulation li:hover img {
        transform: scale(1.1);
        transition: all 0.5s ease; 
    }
    @media (max-width: 768px){
        .tabulation li{
        width: 100%;
        }
    }
</style>