<template>
    <div class="nrk">
        <Details  :data='this.$route.query.inst?Ntinfo:data.info' :inst='this.$route.query.inst'/>
    </div>
</template>

<script>
 import{mapState} from'vuex';
export default {
    activated() {
        let url=this.$route.query.inst?'PostNtInfo':'PostNewsInfo';
        this.$store.dispatch(url,{id:this.$route.query.id});
    },
    computed:{
        ...mapState({
            data:state=>state.share.NewsInfo,
            Ntinfo:state=>state.share.NtInfo,
        })
    },
}
</script>

<style>

</style>