<template>
    <img src='img/js-3.jpg' class="ims nrk">
</template>

<script>
export default {

}
</script>

<style scoped>
    .ims{
        width: 100%;
        margin: 20px 0;
    }
</style>