import { render, staticRenderFns } from "./ActivityTabulation.vue?vue&type=template&id=52416c51&scoped=true&"
import script from "./ActivityTabulation.vue?vue&type=script&lang=js&"
export * from "./ActivityTabulation.vue?vue&type=script&lang=js&"
import style0 from "./ActivityTabulation.vue?vue&type=style&index=0&id=52416c51&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "52416c51",
  null
  
)

export default component.exports