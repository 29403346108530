<template>
    <div>
        <img src="img/banner-4.jpg" class="nark img-banner">
        <sub-navigation :data='navigation[4].son'></sub-navigation>
                <!--供热常识-->
         <div class="title">
            <p>
                <i>{{navigation[4].son[nids-1].title}}</i>
            </p>
            <span>{{navigation[4].son[nids-1].e_title}}</span>
        </div>
        <keep-alive>
            <router-view></router-view>
        </keep-alive>
    </div>
</template>

<script>
import {mapState} from 'vuex'
import SubNavigation from '@/components/pc/SubNavigation'
export default {
    components:{
      SubNavigation
    } ,
    computed:{
        ...mapState({
            navigation:state=>state.share.navigation,
            MemberCt:state=>state.share.MemberCt,
            nids:state=>state.share.nids
        })
    },
}
</script>

<style>

</style>