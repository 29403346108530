<template>
    <div class="nrk">
        <div class="news">
            <div class="news-left yanst wow animate__animated animate__fadeInLeft" @click="News">
                <div class="news-left-nr">
                        <p><i>总经理致辞</i><img src="img/hh.png"></p>
                        <i class="news-left-nr-men">General Manager Speech</i>
                        <div v-html="Banner.jl.content"></div>
                        <span class="gd">
                            >
                        </span>
                </div>
            </div>
            <div class="news-left news-tab-bj wow animate__animated animate__fadeInLeft news-leftld">
                <ul class="news-tab">
                    <li :class="index==lnt?'news-tab-click':''" 
                    v-for="(item,index) in nametab" :key="index"
                    @mouseover='lieb(index)'
                    >
                    {{item.name}}
                    </li>
                </ul>
                <ul class="news-tab-son">
                    <li v-for="(item,index) in lnt==1?Banner.news1:Banner.news2" :key="item.id" @click="Details(item.id)" >
                        <div class="news-content" v-if="index<2">
                            <a>
                                <img :src="'img/zx-'+index+'.jpg'" class="xt-2">
                                <div class="naws-text">
                                    <i>{{item.title}}</i>
                                    <p>{{item.desc}} <span class="news-more">查看更多></span></p>
                                    <div class="news-left-time">
                                    <img src="./img/xt_2.png">
                                        <span>{{item.create_time}}</span>
                                    </div>
                                </div>
                            </a>
                        </div>
                    </li>
                    <!-- <a @click="NewsJump">
                        
                    </a> -->
                </ul>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            lnt:1,
            nametab:[
                {id:1,name:'公司动态'},
                {id:2,name:'行业资讯'},
            ]
        }
    },
    props:['Newsname','NewsNav','Banner'],
    methods: {
        Addls(){
            this.$router.push({name:'Address'})
        },
        News(){
            this.$router.push({name:'Servic'})
        },
        lieb(index){
            this.lnt=index
            // this.$emit('Newslieb',this.nametab[index].id)
        },
        NewsJump(){
            let url=this.NewsNav[this.lnt+1].url
            this.$router.push({
                name:url,
            })
        },
        Details(id){
            this.$router.push({
                name:'NewsDetails',
                    query:{
                        id:id,
                }
            })
        },
    },
}
</script>

<style scoped>
    a:hover{
        text-decoration:none;
    }
    .news {
        width: 100%;
        margin: 0 auto;
        padding: 10px;
        overflow: hidden;
        margin-top: 4%;
    }
    .naws-text p{
        height: 90px;
    }
    .news-left {
        float: left;
        width: 820px;
        height: 530px;
        overflow: hidden;
        cursor: pointer;
    }
    .news-left-img {
        position: relative;
    }

    .news-left-img img {
        width: 100%;
        height: 320px;
    }
    .naws-text i{
        font-size: 18px;
        font-weight: bold;
        margin-top: 20px;
    }
    .liedqn {
        position: absolute;
        top: 300px;
        right: 16px;
        font-size: 18px;
        color: #3dabed;
    }
    .news-tab{
        width: 100%;
        height: 85px;
        background-color: #fff;
    }
    .news-tab li{
        float: right;
        width: 20%;
        background-color: #fff;
        text-align: center;
        line-height: 85px;
        font-size: 18px;
        cursor: pointer;
    }
    .news-tab .news-tab-click{
        /* background-color: #0082e4; /* 针对不支持渐变的浏览器 */
        /* background-image: linear-gradient(to right, #008df1 , #0d1f61);  */
        color: #315ccf;
    }
    .news-left-text {
        width: 183px;
        height: 58px;
        text-align: center;
        color: #fff;
        position: absolute;
        top: 0;
        left: 0;
        line-height: 58px;
        background-color: #000;
        /* 不支持线性的时候显示 */
        background-image: linear-gradient(to right, #0385fb, #09014f);
        font-size: 20px;
        z-index: 1;
    }
    .news-tab-son{
        background-color: #fff;
        background: url('@/assets/zx-2.jpg') no-repeat top  center ;
        height: 500px;
    }
    .news-tab-son li{
        overflow: hidden;
    }
    .news-left-nr {
        width: 84%;
        margin: 0 auto;
        margin-top: 40px;
        color: #fff;   
    }
    .yanst{
        background-color: #315ccf;
        width: 370px;
        height: 530px;
    }
    .news-left-nr p {
        font-size: 20px;
        font-weight: bold;
        letter-spacing: 2px;
        overflow: hidden;
    }
    .news-left-nr p img{
        margin-left: 4px;
        margin-top: 3px;
        float: left;
    }
    .news-left-nr p i{
        float: left;
    }
    .news-left-nr div {
        font-size: 12px;
        color: #fff;
        width: 100%;
        text-align: justify;
        margin-top: 24px;
        text-indent: 2em;
        line-height: 22px;
        /* height:280px; */
        overflow : hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 13;
        -webkit-box-orient: vertical;
    }

    .news-left-nr div span {
        color: #0086d1;
    }

    .news-folird {
        float: right;
        position: relative;
    }

    .news-left-ul {
        position: relative;
        width: 1620px;
    }

    .news-left-ul li {
        float: left;
        width: 540px;
        overflow: hidden;
        position: relative;
    }
    .news-left-time img {
        width: 16px;
        height: 16px;
        float: left;
        margin-right: 6px;
    }
    .news-content a{
    width: 90%;
    padding: 18px 5% 18px 5%;
    display: block;
    }
    .news-content i{
        font-size: 16px;
        overflow : hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp:1;
        -webkit-box-orient: vertical;
        color: #333;
    }
    .news-content div{
        margin-top: 8px;
        overflow: hidden;
        padding-left: 5%;
    }
    /* .news-content:hover{
        background-color: #fff;
    } */
    .news-content:hover i{
        background-color: #fff;
        background-image: linear-gradient(to right, #0083f1, #120f3e); /* 线性渐变背景，方向向上 */
        -webkit-background-clip: text; /* 背景被裁剪成文字的前景色 */
        -webkit-text-fill-color: transparent; /* 文字填充颜色变透明 */   

    }
    .news-content .xt-2 {
        float: left;
        width: 270px;
        height: 180px;
        margin-right: 6px;
    }
    .news-more {
        width: 100%;
        height: 50px;
        line-height: 45px;
        color: #0081f0;
        text-align: center;
        font-size: 12px;
    }
    .news-left-time img {
        width: 16px;
        height: 16px;
        float: left;
    }
    .news-left-time i {
        font-size: 12px;
        font-weight: normal;
        float: left;
        margin-left: 4px;
        color: #999;
    }
    .news-left-time {
        float: right;
        padding: 0;
    }
    .news-left-nr-men{
        position: relative;
        color: #31cf47;
        margin-top: 40px;
        display: block;
        padding-bottom: 20px;
    }
    .news-left-nr-men::after{
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        background-color: #31cf47;
        height: 4px;
        width: 70px;
    }
    .gd{
        border-radius: 50px;
        border: 1px #fff solid;
        color: #fff;
        display: block;
        margin-left: 12px;
        width: 22px;
        height: 22px;
        line-height: 20px;
        font-size: 14px;
        text-align: center;
        float: right;
        margin-top: 20px;
    }
    @media (max-width: 768px){
        .news-tab-son{
            background-size: 500%;
        }
        .news-tab-son{
            height: auto;
        }
        .naws-text p{
            max-height: 60px;
        }
        .news-content .xt-2{
            width: 100%;
        }
        .news-content div{
            width: 100%;
        }
        .news-tab li{
            width: 50%;
        }
        .news-left-nr p{
            font-size: 18px;
        }
        .news{
            padding: 0px;
        }
        .news-left-img img{
            height: auto;
        }
        .news-left{
            width: 98%;
            margin: 0 auto;
            height: auto;
            padding-bottom: 12px;
            margin-bottom: 24px;
        }
        .news-left-text{
            width: 160px;
            height: 48px;
            line-height: 48px;
            font-size: 18px;
        }
        .news-left-nr div{
            margin-top: 8px;
        }
        .news-content a{
            padding: 10 px 5% 10px 5%;
        }
    }
</style>