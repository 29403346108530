<template>
    <div>
        <div class="title">
            <p>
                <i>供热常识</i>
            </p>
            <span>Heating knowledge</span>
        </div>
        <News-List :data='Notice' @getAreaInfo='getAreaInfo'/>
        <Pagination 
        :pageNo="naml.page" 
        :pageSize='naml.limit' 
        :total="Notice.count" 
        :continues="5"
        @getPageNo='getPageNo'
        />
    </div>
</template>

<script>
import {mapState} from 'vuex'
import NewsList from '@/components/pc/NewsListThe'
export default {
  data() {
    return {
        naml:{
          cid:1,
          limit:6,
          page:1,
        },
    }
  },
    components:{
      NewsList
    } ,
    activated() {
        this.postNews(this.naml)
    },
    computed:{
        ...mapState({
            Notice:state=>state.share.Performance
        })
    },
    methods: {
        getPageNo(id){
            this.naml.page=id;
            this.postNews(this.naml)
        },
        postNews(naml){
            this.$store.dispatch('PostPerformance',naml);
        },
        getAreaInfo(id){
            this.$router.push({
                name:'DesignerDetailsPages',
                    query:{
                        id:id,
                    }
            })
        }
    },

}
</script>

<style>

</style>