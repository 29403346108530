<template>
    <!--业务范围-->
    <div class="work nark">
        <div class="work-text">
            <p>
                <i>主要业务范围</i>
            </p>
        </div>
        <div class="work-nrk">
            <ul class="work-img">
                <li>
                    <img src="./img/xip_1.png">
                    <p>大中型医药工程设计</p>
                </li>
                <li>
                    <img src="./img/xip_2.png">
                    <p>食品工程设计</p>
                </li>
                <li>
                    <img src="./img/xip_3.png">
                    <p>仓储物流工程设计</p>
                </li>
                <li>
                    <img src="./img/xip_4.png">
                    <p>药用包材工程设计</p>
                </li>
                <li>
                    <img src="./img/xip_5.png">
                    <p>手术室工程设计</p>
                </li>
                <li>
                    <img src="./img/xip_6.png">
                    <p>医疗器械工程设计</p>
                </li>
                <li>
                    <img src="./img/xip_7.png">
                    <p>日用化工工程设计</p>
                </li>
                <li>
                    <img src="./img/xip_8.png">
                    <p>生物安全实验室</p>
                </li>
                <li>
                    <img src="./img/xip_9.png">
                    <p>项目咨询</p>
                </li>
                <li>
                    <img src="./img/xip_10.png">
                    <p>项目可行性研究</p>
                </li>
                <li>
                    <img src="./img/xip_11.png">
                    <p>项目管理（总承包）</p>
                </li>
                <li>
                    <img src="./img/xip_12.png">
                    <p>安装工程</p>
                </li>
                <li>
                    <img src="./img/xip_13.png">
                    <p>项目监理</p>
                </li>
                <li>
                    <img src="./img/xip_14.png">
                    <p>药品认证咨询服务</p>
                </li>
                <li>
                <img src="./img/xip_15.png">
                    <p>洁净环境测试</p>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
export default {

}
</script>

<style scoped>
    .work {
    margin-top: 60px;
    width: 100%;
    background: url(./img/beij_3.jpg) no-repeat center center;
    overflow: hidden;
    }

    .work-nrk {
        width: 100%;
        margin: 0 auto;
    }

    .work-text {
        width: 100%;
        margin: 0 auto;
        margin-top: 80px;
        overflow: hidden;
        padding-bottom: 60rpx;
    }
    .work-text p{
        text-align: center;
        margin-top: 50px;
    }
    .work-text i {
        font-size: 34px;
        font-weight: bold;
        color: #fff;
    }

    .work-text span {
        font-size: 24px;
        color: #fff;
        margin-left: 25px;
    }

    .work-img {
        width: 100%;
        margin-top: 53px;
    }

    .work-img li {
        width: 20%;
        float: left;
        text-align: center;
        margin-bottom: 94px;
        cursor:pointer
    }

    .work-img li img {
        padding: 24px;
        border: 2px #fff solid;
        border-radius: 50px;
    }

    .work-img li p {
        margin-top: 40px;
        color: #fff;
        font-size: 18px;
        letter-spacing: 1px;
    }
    .work-img li:hover img {
        background-color: #ffa918;
    }
    @media (max-width: 768px){
        .work-img{
            margin-top: 10%;
        }
        .work-text i{
            font-size: 24px;
        }
        .work-text span{
            font-size: 12px;
        }
        .work-text img{
            width: 70%;
        }
        .work-text {
            margin-top: 10%;
        }
        .work-img li img{
            padding: 20px;
            width: 35%;
        }
        .work-img li p{
            margin-top: 8px;
            font-size: 12px;
        }
        .work-img li{
            margin-bottom: 4%;
            width: 33%;
            height: 120px;
            overflow: hidden;
        }
        .work-text p{
            margin-top: 0;
        }
    }
</style>