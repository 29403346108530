<template>
    <div>
        <div class="nrk">
            <Carousel :data='Honor' class="pc-display"/> 
            <CarouselMobile :data='Honor' class="mobile-display"/>
        </div>
        <Timeline :data='Honorother' :mane='nama'/>
    </div>
</template>

<script>
import{mapState} from'vuex';
import Timeline from'@/components/pc/Timeline'
export default {
    components:{
        Timeline
    },
    data() {
        return {
            nama:'—— 吉林医药设计院有限公司近年社会荣誉 ——',
            boyd:{
                page:1,
                limit:100,
                type:1
            },
            boydSon:{
                page:1,
                limit:100,
                type:2
            }
        }
    },
    mounted() {
        this.$store.dispatch('PostHonor',this.boyd);
        this.$store.dispatch('PostHonor',this.boydSon);
    },
    computed:{
        ...mapState({
            Honor:state=>state.share.Honor,
            Honorother:state=>state.share.Honorother,
        })
    },
}
</script>

<style scoped>
    .mobile-display{
        display: none;
    }
    @media (max-width: 768px){
        .Servic p{
            font-size: 18px;
            padding: 18px 0;
            display: block;
            font-weight: bold;
        }
        .mobile-display{
            display: block;
        }
        .pc-display{
            display: none;
        }
    }
</style>