<template>
    <div class="jians">
        <img src="img/jians.png">
        <div class="jianstext">
            <span>—正在建设中—</span>
        </div>
    </div>
</template>

<script>
export default {

}
</script>

<style scoped>
    .detecti{
        width: 100%;
        background: url('@/assets/zx-13.jpg') center center;
        overflow: hidden;
    }
    .detecti img{
        display: block;
        margin: 40px auto;
    }
    .detecti-text{
        margin: 0 auto;
    }
    .detecti-text span{
        text-align: justify;
        display: block;
        font-weight: bold;
        margin-bottom: 40px;
        text-indent: 2em;
        font-size: 16px;
    }
    .jians{
        text-align: center;
        margin: 150px auto;
    }
    .jianstext{
        font-size: 16px;
        font-weight: bold;
        margin-top: 12px;
    }
</style>