import Vue from 'vue';
import VueRouter from 'vue-router';
Vue.use(VueRouter);
import Pc from'@/pages/Pc';
import Home from'@/pages/Pc/Home';//首页
import About from'@/pages/Pc/About';//关于我们
import Company from'@/pages/Pc/About/son/Company';              //关于我们-公司简介
import Servicescope from'@/pages/Pc/About/son/Servicescope';    //关于我们-服务范围
import System from'@/pages/Pc/About/son/System';                //关于我们-管理体系
import Organization from'@/pages/Pc/About/son/Organization';        //关于我们-组织架构
import Idea from'@/pages/Pc/About/son/Idea';                        //关于我们-企业理念
import News from'@/pages/Pc/About/son/News';                        //关于我们-新闻中心
import Culture from'@/pages/Pc/About/son/Culture';                  //关于我们-企业文化
import Enterprise from'@/pages/Pc/About/son/Culture/Enterprise';    //关于我们-企业理念
import Staff from'@/pages/Pc/About/son/Culture/Staff';              //关于我们-员工风采
import Work from'@/pages/Pc/About/son/Culture/Staff/Work';                      //关于我们-工作瞬间
import WorkTabulation from'@/pages/Pc/About/son/Culture/Staff/Work/son/WorkTabulation';   //关于我们-列表
import WorkDetails from'@/pages/Pc/About/son/Culture/Staff/Work/son/WorkDetails';      //关于我们-详情
import Activity from'@/pages/Pc/About/son/Culture/Staff/Activity';              //关于我们-团队活动
import ActivityTabulation from'@/pages/Pc/About/son/Culture/Staff/Activity/son/ActivityTabulation';
import ActivityDetails from'@/pages/Pc/About/son/Culture/Staff/Activity/son/ActivityDetails';               //关于我们-团队活动
import Business from'@/pages/Pc/Business';                                      //业务范围
import Range from'@/pages/Pc/Business/son/Range';                               //业务范围-业务范围
import Technology from'@/pages/Pc/Technology';                                  //技术创新
import Patent from'@/pages/Pc/Technology/son/Patent';                           //技术创新-专利技术
import Proprietary from'@/pages/Pc/Technology/son/Proprietary';                 //技术创新-专有技术
import Designer from'@/pages/Pc/Technology/son/Designer';                       //技术创新-专有技术
import DesignerTabulation from'@/pages/Pc/Technology/son/Designer/son/Tabulation'; //技术创新-专有技术
import DesignerDetails from'@/pages/Pc/Technology/son/Designer/son/Details';        //技术创新-专有技术
import DesignerDetailsPages from'@/pages/Pc/Technology/son/Details';                //技术创新-专有技术
import DesignerReport from'@/pages/Pc/Technology/son/Report';                //技术创新-专有技术
import Project from'@/pages/Pc/Project';                                        //工程业绩
import Case from'@/pages/Pc/Project/son/Case';                                  //工程业绩-典型案例
import Tabulation from'@/pages/Pc/Project/son/Case/son/Tabulation';             //工程业绩-典型案例-列表页面
import List from'@/pages/Pc/Project/son/Case/son/List';                         //工程业绩-典型案例-列表详情
import Details from'@/pages/Pc/Project/son/Case/son/Details';                   //工程业绩-典型案例-列表详情-详情
import Achievement from'@/pages/Pc/Project/son/Achievement';                    //工程业绩-主要业绩
import Exchange from'@/pages/Pc/Project/son/Exchange';                          //工程业绩-对外交流
import ExcTabulation from'@/pages/Pc/Project/son/Exchange/son/Tabulation';      //工程业绩-对外交流-列表
import ExcDetails from'@/pages/Pc/Project/son/Exchange/son/Details';            //工程业绩-对外交流-详情  
import Engineering from'@/pages/Pc/Project/son/Engineering';                    //工程业绩-涉外项目
import EngTabulation from'@/pages/Pc/Project/son/Engineering/son/Tabulation';   //工程业绩-涉外项目-列表
import EngDetails from'@/pages/Pc/Project/son/Engineering/son/Details';         //工程业绩-涉外项目-详情
import Honor from'@/pages/Pc/Honor';                                            //工程业绩-企业荣誉
import Society from'@/pages/Pc/Honor/son/Society';                              //工程业绩-企业荣誉-社会荣誉
import Awards from'@/pages/Pc/Honor/son/Awards';                                //工程业绩-企业荣誉-获奖项目
import ContactUs from'@/pages/Pc/ContactUs';                                    //工程业绩-联系我们
import Address from'@/pages/Pc/Address';                                        //工程业绩-院长致辞
import Floatdada from'@/pages/Pc/Floatdada';                                    //工程业绩-首页浮动
import Detection from'@/pages/Pc/Floatdada/son/Detection';                      //工程业绩-首页浮动
import FloEngineering from'@/pages/Pc/Floatdada/son/FloEngineering';            //工程业绩-首页浮动
import FloHonor from'@/pages/Pc/Floatdada/son/FloHonor';                        //工程业绩-首页浮动
import Recognize from'@/pages/Pc/Floatdada/son/Recognize';                      //工程业绩-首页浮动
import PcNews from'@/pages/Pc/News';                                            //新闻中心
import Notice from'@/pages/Pc/News/son/Notice';                                 //新闻中心-公司动态
import NewsOne from'@/pages/Pc/News/son/NewsOne';                               //新闻中心-新闻中心
import NewsNotice from'@/pages/Pc/News/son/NewsNotice';                         //新闻中心-通知公告
import NewsLaw from'@/pages/Pc/News/son/NewsLaw';                               //新闻中心-行业动态
import NewsTrends from'@/pages/Pc/News/son/NewsTrends';                         //新闻中心-法律法规
import NewsDetails from'@/pages/Pc/News/son/NewsDetails';                       //新闻中心-详情
import PaymentDetails from'@/pages/Pc/News/son/PaymentDetails';                //新闻中心-缴费详情
import Links from'@/pages/Pc/Links';                                            // 友情链接
import English from'@/pages/English';                                           //英文版首页
import EnglishHome from'@/pages/English/Home';                                  //英文版首页
import EnglishBlurb from'@/pages/English/Blurb';                                    //英文版
import EnglishNews from'@/pages/English/son/EnglishNews';                       //英文版
import EnglishContact from'@/pages/English/son/EnglishContact';                 //英文版
import EnglishAddress from'@/pages/English/son/EnglishAddress';   
import EnglishHonor from'@/pages/English/Honor';   
import EnglishOrganization from'@/pages/English/Organization';   
import EnglishContactUs from'@/pages/English/ContactUs';                  //英文版
//配置路由
const router= new VueRouter({
    routes:[
        {
            path:'/English',
            component:English,redirect:'/English/EnglishHome',meta:{isAuth:true},
            children:[
                //首页
                {name:'EnglishHome',path:'EnglishHome',component:EnglishHome},
                {name:'EnglishBlurb',path:'EnglishBlurb',component:EnglishBlurb},
                {name:'EnglishNews',path:'EnglishNews',component:EnglishNews},
                {name:'EnglishContact',path:'EnglishContact',component:EnglishContact},
                {name:'EnglishAddress',path:'EnglishAddress',component:EnglishAddress},
                {name:'EnglishHonor',path:'EnglishHonor',component:EnglishHonor},
                {name:'EnglishOrganization',path:'EnglishOrganization',component:EnglishOrganization},
                {name:'EnglishContactUs',path:'EnglishContactUs',component:EnglishContactUs},
            ]
        },
        {
            path:'/Pc',
            component:Pc,redirect:'/Pc/home',meta:{isAuth:true},
            children:[
                //首页
                {path:'home',component:Home,meta:{isAuth:true}},
                //关于我们
                {path:'About',component:About,redirect:'About/Company',
                    children:[
                        {name:'Company',path:'Company',component:Company},
                        {name:'Servic',path:'Servic',component:Servicescope},
                        {name:'System',path:'System',component:System},
                        {name:'Organization',path:'Organization',component:Organization},
                        {name:'Idea',path:'Idea',component:Idea},
                        {name:'News',path:'News',component:News},
                        {name:'Culture',path:'Culture',component:Culture,redirect:'Culture/Enterprise',
                        children:[
                            {name:'Enterprise',path:'Enterprise',component:Enterprise},
                            {name:'Staff',path:'Staff',component:Staff,redirect:'Staff/Work',
                            children:[
                                {name:'Work',path:'Work',component:Work,redirect:'Work/WorkTabulation',
                                children:[
                                    {name:'WorkTabulation',path:'WorkTabulation',component:WorkTabulation},
                                    {name:'WorkDetails',path:'WorkDetails',component:WorkDetails}
                                ]},
                                {name:'Activity',path:'Activity',component:Activity,redirect:'Activity/ActivityTabulation',
                                children:[
                                    {name:'ActivityTabulation',path:'ActivityTabulation',component:ActivityTabulation},
                                    {name:'ActivityDetails',path:'ActivityDetails',component:ActivityDetails},
                                ]},
                            ]},
                        ]
                        },
                    ]
                },
                //业务范围
                {path:'Business',component:Business,redirect:'Business/Range',
                    children:[
                        {name:'Range',path:'Range',component:Range},
                    ]
                },
                //技术创新
                {path:'Technology',component:Technology,redirect:'Technology/Patent',
                    children:[
                        {name:'Patent',path:'Patent',component:Patent},
                        {name:'Proprietary',path:'Proprietary',component:Proprietary},
                        {name:'DesignerDetailsPages',path:'DesignerDetailsPages',component:DesignerDetailsPages},
                        {name:'DesignerReport',path:'DesignerReport',component:DesignerReport},
                        {name:'Designer',path:'Designer',component:Designer,redirect:'Designer/DesignerTabulation',
                            children:[
                                {name:'DesignerTabulation',path:'DesignerTabulation',component:DesignerTabulation},
                                {name:'DesignerDetails',path:'DesignerDetails',component:DesignerDetails},
                            ]
                        },
                    ]
                },
                //工程业绩
                {path:'Project',component:Project,redirect:'Project/Case',
                    children:[
                        {name:'Case',path:'Case',component:Case,redirect:'Case/Tabulation',
                        children:[
                            {name:'Tabulation',path:'Tabulation',component:Tabulation},
                            {name:'List',path:'List',component:List},
                            {name:'Details',path:'Details',component:Details}
                        ]
                    },
                        {name:'Achievement',path:'Achievement',component:Achievement},
                        {name:'Exchange',path:'Exchange',component:Exchange,redirect:'Exchange/ExcTabulation',
                            children:[
                                {name:'ExcTabulation',path:'ExcTabulation',component:ExcTabulation},
                                {name:'ExcDetails',path:'ExcDetails',component:ExcDetails},
                            ]},
                        {name:'Engineering',path:'Engineering',component:Engineering,redirect:'Engineering/EngTabulation',
                            children:[
                                {name:'EngTabulation',path:'EngTabulation',component:EngTabulation},
                                {name:'EngDetails',path:'EngDetails',component:EngDetails},
                            ]
                        },
                        ]
                },
                //企业荣誉
                {path:'Honor',component:Honor,redirect:'Honor/Society',
                    children:[
                        {name:'Society',path:'Society',component:Society},
                        {name:'Awards',path:'Awards',component:Awards},
                    ]
                },
                //联系我们
                {path:'ContactUs',component:ContactUs},
                //友情链接
                {path:'Links',component:Links,name:'Links'},
                //院长致辞
                {name:'Address',path:'Address',component:Address},
                //首页浮动
                {name:'Floatdada',path:'Floatdada',component:Floatdada,redirect:'Floatdada/Detection',
                    children:[
                        {name:'Detection',path:'Detection',component:Detection},
                        {name:'FloEngineering',path:'FloEngineering',component:FloEngineering},
                        {name:'FloHonor',path:'FloHonor',component:FloHonor},
                        {name:'Recognize',path:'Recognize',component:Recognize},
                    ]
                },
                //首页新闻
                {name:'PcNews',path:'PcNews',component:PcNews,redirect:'PcNews/NewsNotice',
                    children:[
                        {name:'Notice',path:'Notice',component:Notice},
                        {name:'NewsNotice',path:'NewsNotice',component:NewsNotice},
                        {name:'NewsOne',path:'NewsOne',component:NewsOne},
                        {name:'NewsLaw',path:'NewsLaw',component:NewsLaw},
                        {name:'NewsTrends',path:'NewsTrends',component:NewsTrends},
                        {name:'NewsDetails',path:'NewsDetails',component:NewsDetails},
                        {name:'PaymentDetails',path:'PaymentDetails',component:PaymentDetails},
                    ]
                },
            ]
        },
        //重定向,在项目跑起来的时候,访问/,立马让他定向首页
        {
            path:'*',
            redirect:"/Pc/home"
        }
    ]
})
router.beforeEach((to,from,next)=>{
    // console.log(from)
    // if(from.meta.isAuth){
    //     document.body.scrollTop =document.documentElement.scrollTop=0;
    // }
    document.body.scrollTop =document.documentElement.scrollTop=0;
    next()
})
export default router