<template>
    <div class="nrk news">
        <div class="title">
            <p>
                <i>HEATING COMPANY</i>
            </p>
        </div>
        <ul class="News" @mouseleave='lnt=-1'>
            <li 
            v-for="(item,index) in tabulation" 
            :key="index" class="News-l" 
            @mouseenter='lnt=index'
            >
                <img :src="'img/'+item.url" >
                <div class="text" v-if="lnt!=index">
                    <p>{{item.english}}</p>
                </div>
                <div class="nisl" v-if="lnt==index">
                    <p>{{item.english}}</p>
                </div>
            </li>
        </ul>
    </div>
</template>

<script>
export default {
    data() {
        return {
            lnt:-1,
            nametab:[
                {id:1,name:'新闻中心'},
                {id:4,name:'行业动态'},
                {id:5,name:'法律法规'}
            ],
            tabulation:[
                {id:1,name:'服务承诺',english:'SERVICE COMMITMENT',url:'News-1.jpg',src:'Patent'},
                {id:2,name:'供热常识',english:'HEATING KNOWLEDGE',url:'News-2.jpg',src:'Proprietary'},
                {id:2,name:'供热范围',english:'HEATING RANGE',url:'News-3.jpg',src:'DesignerTabulation'},
                {id:3,name:'违章用热举报',english:'VIOLATION REPORTING',url:'News-4.jpg',src:'DesignerReport'},
            ]
        }
    },
    props:['Newsname','NewsNav'],
    methods: {
        clis(src){
            this.$router.push({name:src})
        },
        Addls(){
            this.$router.push({name:'Address'})
        },
        News(){
            this.$router.push({name:'Company'})
        },
        lieb(index){
            this.lnt=index
            this.$emit('Newslieb',this.nametab[index].id)
        },
        NewsJump(){
            let url=this.NewsNav[this.lnt+1].url
            this.$router.push({
                name:url,
            })
        },
        Details(id){
            this.$router.push({
                name:'NewsDetails',
                    query:{
                        id:id,
            }
        })
      },
    },
}
</script>

<style scoped>
    .news{
        height: 500px;
    }
    .nisl{
        width: 100%;
        height: 180px;
        background: url(./img/tps.png) no-repeat top;
        background-size: 100%;
    }
    .News{
        width: 100%;
        overflow: hidden;
        display: flex;
        justify-content:space-evenly;
    }
    .News li{
        width: 18%;
        cursor: pointer;
        
    }
    .News li img{
        width: 100%;
        box-shadow: 0px 3px 3px #d9d9d9;
    }
    .News li .text{
        border-bottom: 4px #315ccf solid;
        text-align: center;
        padding: 14px 0;
        box-shadow: 0px 3px 3px #d9d9d9;
        background-color: #fff;
        overflow: hidden;
    }
    .News li .text p{
        font-size: 14px;

        color: #666666;
    }
    .News li .text i{
        color: #666666;
        margin-top: 6px;
        display: block;
    }
    .nisl{
        text-align: center;
        overflow: hidden;
    }
    .nisl p{
        color: #fff;
        font-size: 16px;
        font-weight: bold;
        margin-top: 32px;

    }
    .nisl i{
        color: #fff;
        margin-top: 6px;
        display: block;
    }
    a:hover{
        text-decoration:none;
    }
    @media (max-width: 768px){
        .news{
            height: auto;
        }
        .News{
            display: block;
        }
        .News li{
            width: 48%;
            float: left;
            margin-bottom: 30px;
        }
        .News li:nth-child(2n){
            float: right;
        }
        .News li img{
            height: 136px;
        }
        .news-left-nr p{
            font-size: 18px;
        }
        .news{
            padding: 0px;
        }
        .news-left-img img{
            height: auto;
        }
        .news-left{
            width: 98%;
            margin: 0 auto;
            height: auto;
            padding-bottom: 12px;
            margin-bottom: 24px;
        }
        .news-left-text{
            width: 160px;
            height: 48px;
            line-height: 48px;
            font-size: 18px;
        }
        .news-left-nr div{
            margin-top: 8px;
            height: 260px;
        }
        .news-content a{
            padding: 10 px 5% 10px 5%;
        }
    }
</style>