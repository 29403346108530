<template>
    <!-- Swiper -->
    <div class="swiper-cont">
        <div class="swiper-wrapper">
            <div class="swiper-slide" v-for="item in data" :key="item.id">
                <img v-lazy="Http+item.src">
                <div>{{item.title}}</div>
            </div>
        </div>
        <div class="swiper-button-next"></div>
        <div class="swiper-button-prev"></div>
    </div>
</template>

<script>
import{mapState} from 'vuex'
import Swiper from "swiper";
import "swiper/css/swiper.min.css";
export default {
    name:'CarouselMobile',
    props:['data'],
    computed:{
        ...mapState({
            Http:state=>state.share.Http
        })
    },
    mounted() {

	},
    watch:{
        data:{
            handler(){
               this.$nextTick(()=>{
                    let swipers=new Swiper('.swiper-cont', {
                        slidesPerView: 1,
                        direction: 'horizontal',
                        navigation: {
                            nextEl: '.swiper-button-next',
                            prevEl: '.swiper-button-prev',
                        },
                        on: {
                            resize: function () {
                            swipers.changeDirection('horizontal');
                            }
                        }
                    });
               }) 
            }
        }
    },
    methods: {
        getDirection() {
            var windowWidth = window.innerWidth;
            var direction = 1500 <= 760 ? 'vertical' : 'horizontal';
            console.log(window.innerWidth)
            return direction;
        }
    },	
}
</script>

<style scoped>
    .swiper-cont {
      width: 100%;
      height: 100%;
    }

    .swiper-slide {
      text-align: center;
      font-size: 18px;
      background: #fff;

      display: inline-block;
      height: inherit; /*继承父级高度*/
      /* Center slide text vertically */
    }
    .swiper-cont{
        padding: 2% 0;
        margin: 40px 0;
        overflow: hidden;
    }
    .swiper-slide{
        max-width: 375px;
    }
    .swiper-slide img{
        width: 74%;
        margin: 0 auto;
        border: 6px #fff solid;
        box-shadow: 0px 3px 3px #d9d9d9;
        display: block;
        max-height: 200px;
    }
    .swiper-slide div{
        text-align: center;
        width: 100%;
        font-size: 13px;
        color: #666;
        margin-top: 30px;
    }
    @media (max-width: 760px) {
      .swiper-slide{
        max-width: 381px;
      }
      .swiper-button-next {
        right: 20px !important;
        font-size: 36px;
      }

      .swiper-button-prev {
        left: 20px !important;
      }
    }
    .swiper-button-prev, .swiper-container-rtl .swiper-button-next {
        left: 0px;
    }
    .swiper-button-next, .swiper-container-rtl .swiper-button-prev {
        right: 0px;
        left: auto;
    }
</style>