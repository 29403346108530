<template>
    <div class="yans">
        <div class="nrk bottom-btn">
            <div class="title">
                <p>
                    <i>违章用热举报</i>
                </p>
                <span>Reporting of illegal use of heat</span>
            </div>
            <div class="gongr">
                <div class="dh">
                    <img src="img/jbdh.png">
                    <p>0431-82333879</p>
                </div>
                <img src="img/logo.png" class="logo">
            </div>
        </div>
    </div>
</template>

<script>
import {mapState} from "vuex"
export default {
    data() {
        return {
            data:{
                type:8
            },
        }
    },
    mounted() {
        this.$store.dispatch('PostAbout',this.data);
    },
    computed:{
		...mapState({
			study:state=>state.share.study,
            About:state=>state.share.About
		})
        
	},
}
</script>

<style scoped>
    .logo{
        float: right;
        height: 50px;
        margin: 20px 0;
    }
    .dh{
        display: flex;
        justify-content: center;
    }
    .dh img{
        width: 80px;
        height: 80px;
    }
    .dh  p{
        line-height: 80px;
        font-size: 26px;
        color: #315ccf;
        margin-left: 12px
    }
    .litext{
        width: 80%;
        color: #666666;
        font-size: 20px;
        line-height: 40px;
        margin: 0 auto;
        font-weight: bold;
    }
    .litext p{
        margin-bottom: 16px;
    }
    .mobile-display{
        display: none;
    }
    .gongr-rq{
        width: 100px;
        height: 97px;
        background-color: #f4f4f4;
        text-align: center;
        color: #0019a7;
        overflow: hidden;
    }
    .gongr-rq h1{
        font-size: 30px;
        margin-top: 16px;
    }
    .gongr-rq span{
        font-size: 16px;
        margin-top: 6px;
    }
    .gongr{
        width: 94%;
        padding: 3%;
        background-color: #fff;
        overflow: hidden;
        background: url('@/assets/zx-18.jpg') no-repeat top center;
    }
    .yans{
        background-color: #f7f7f7;
        overflow: hidden;
    }
     @media (max-width: 768px){
        .litext{
            width: 100%;
        }
        .Servic p{
            font-size: 18px;
            padding: 18px 0;
            display: block;
            font-weight: bold;
        }
        .mobile-display{
            display: block;
        }
        .pc-display{
            display: none;
        }
    }
</style>