<template>
    <div>
        <img src="./img/banner-1.jpg" class="nark img-banner">
        <sub-navigation :data='navigation[1].son'></sub-navigation>
        <keep-alive>
            <router-view></router-view>
        </keep-alive>
    </div>
</template>

<script>
import {mapState} from 'vuex'
import SubNavigation from '@/components/pc/SubNavigation'
export default {
    components:{
      SubNavigation
    } ,
    computed:{
        ...mapState({
            navigation:state=>state.share.navigation
        })
    },
}
</script>
<style>
    
</style>