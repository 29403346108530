<template>
    <div>
        <img src="img/banner-5.jpg" class="nark img-banner">
            <sub-navigation :data='navigation'></sub-navigation>
         <Friendly-Links :switchoff='true'/>
    </div>
</template>

<script>
import FriendlyLinks from '@/components/pc/FriendlyLinks'
import SubNavigation from '@/components/pc/SubNavigation'
export default {
    components:{
        FriendlyLinks,
        SubNavigation
    },
    data() {
        return {
            navigation:[{url:'Links',name:'友情链接'}],
        }
    },
}
</script>

<style>

</style>