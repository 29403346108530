<template>
  <div class="loading">
    <div class="loading-img">
      <img src="img/logo.jpg" class="logo">
      <img src="img/jzz.gif" class="gif">
      <span>加载中~</span>
    </div>
  </div>
</template>

<script>
export default {

}
</script>

<style scoped>
  .loading{
    height: 100%;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    background-color: #fff;
    z-index: 99;
    display: flex;
    align-items: center;
    justify-content:center;
  }
  .loading-img{
    width: 600px;
    margin: 0 auto;
    
  }
  .loading-img .logo{
    width: 80%;
    display: block;
    margin: 0 auto;
  }
  .loading-img .gif{
    width: 60%;
    display:block;
    margin: 0 auto; 
  }
  .loading-img span{
    text-align: center;
    width: 100%;
    display: block;
    margin-top: 12px;
  }
</style>