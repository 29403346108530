<template>
    <div class="yans">
        <div class="nrk bottom-btn">
            <div class="title">
                <p>
                    <i>供热常识</i>
                </p>
                <span>Heating knowledge</span>
            </div>
            <div class="gongr">
                <div class="Servic">
                    <p>{{data.name}}</p>
                </div>
                <div class="gongr-rq">
                    <img src="img/zx-2.jpg">
                </div>
                <div class="litext" v-html="data.content"></div>
                <img src="img/logo.jpg" class="logo">
            </div>
        </div>
    </div>
</template>

<script>
 import{mapState} from'vuex';
export default {
    activated() {
        this.$store.dispatch('PostPerformanceInfo',{id:this.$route.query.id});
    },
    computed:{
        ...mapState({
            data:state=>state.share.PerformanceInfo,
        })
    },
}
</script>

<style>
    .logo{
        float: right;
        height: 50px;
    }
    .litext{
        width: 80%;
        color: #666666;
        font-size: 16px;
        line-height: 32px;
        margin: 0 auto;
    }
    .litext p{
        margin-bottom: 16px;
    }
    .mobile-display{
        display: none;
    }
    .gongr-rq{
        width: 100px;
        height: 97px;
        background-color: #f4f4f4;
        text-align: center;
        color: #0019a7;
        overflow: hidden;
    }
    .gongr-rq h1{
        font-size: 30px;
        margin-top: 16px;
    }
    .gongr-rq span{
        font-size: 16px;
        margin-top: 6px;
    }
    .gongr{
        width: 94%;
        padding: 3%;
        background-color: #fff;
        overflow: hidden;
    }
    .yans{
        background-color: #f7f7f7;
        overflow: hidden;
    }
</style>