<template>
<div class="swipers nark">
	<div class="swiper mySwiper lqs">
		<div class="swiper-wrapper">
			<div class="swiper-slide pc" v-for="item in banner.banner" :key="item.id"><img :src="Http+item.image" class="banone"></div>
			<div class="swiper-slide move"><img src="img/web-baner.jpg" class="banone"></div>
		</div>
		<div class="swiper-pagination"></div>
	</div>
</div>
</template>
 
<script>
import{mapState} from 'vuex'
import Swiper from "swiper";
import "swiper/css/swiper.min.css";
export default {
	name: 'SwiperBanner',
	data () {
		return {}
	},
	mounted() {
		new Swiper(".mySwiper", {
				pagination: {
					el: ".swiper-pagination",
				},
				autoplay: {
					delay: 4000,
					disableOnInteraction: false,
				},
		});
	},	
	computed:{
		...mapState({
			banner:state=>state.share.Banner1,
			Http:state=>state.share.Http
		})
	}
}
</script>
<style  scoped>
	.swipers {
		overflow: hidden;
		position: relative;
		min-height: 230px;
	}
	.swiper img{
		width: 100%;
	}
	.move{
		display: none;
	}
	@media (max-width: 768px){
		.swipers{
			min-height: auto;
		}
		.pc{
			display: none;
		}
		.move{
			display: block;
		}
	}
</style>