   //引入Vue核心库
   import Vue from 'vue'
   //引入Vuex
   import Vuex from 'vuex'
   //应用Vuex插件
   Vue.use(Vuex)
   //引入小仓库
   import share from './share';
   import pc from './pc';
   import mobile from './mobile';
   export default new Vuex.Store({
        modules:{
            share,
            pc,
            mobile
        }
   })