<template>
    <div>
        <div class="staff">
            <span @click="nis=0">
                <router-link :to="{name:'Culture'}" :class="nis==0?'span':''">企业理念</router-link>
            </span >
            <span @click="nis=1">
                <router-link :to="{name:'Staff'}" :class="nis==1?'span':''">工作瞬间</router-link>
            </span>
            <span @click="nis=3">
                <router-link :to="{name:'Activity'}" :class="nis==3?'span':''">团队活动</router-link>
            </span>
        </div>
        <keep-alive>
            <router-view></router-view>
        </keep-alive>
    </div>
</template>

<script>
export default {
    data() {
        return {
            nis:0
        }
    },
}
</script>

<style scoped>
    .staff{
        width:260px;
        margin: 0 auto;
        border: 1px #eee solid;
        margin-top: 16px; 
        height: 40px;
        line-height: 40px;
        border-radius: 5px;
        text-align: center;
        overflow: hidden;
        display: none;

    }
    .staff a{
        width: 33%;
        float: left;
        border-right: 1px #eee solid;
    }
    .staff span:nth-child(3) a{
        border-right:none;
    }
    .span{
        background-color: #0019a7;
        color: #Fff;
    }
    a:link:hover{
        color: #fff;
    }
    @media (max-width: 768px){
        .staff{
            display: block;
        }
    }
</style>
