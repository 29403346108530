<template>
    <div class="nrk exchange">
        <div class="Servic ">
            <p>部分涉外项目图片展示</p>
        </div>
        <div  class="exchange-nr">
            <ul>
                <li v-for="item in Pj.slice(0,2)" :key="item.id" @click="Details(item.id)">
                    <div class="neks">
                        <img v-lazy="Http+item.src"/>
                    </div>
                    <h1>{{item.title}}</h1>
                </li>
            </ul>
            <ul>
                <li v-for="item in Pj.slice(-3)" :key="item.id" @click="Details(item.id)">
                    <div class="neks">
                        <img v-lazy="Http+item.src"/>
                    </div>
                    <h1>{{item.title}}</h1>
                </li>
            </ul>
        </div>
        <div class="Servic ">
            <p>部分涉外项目目录</p>
        </div>
        <div v-html="ursl(OtherPj.content)" class="smallContent"></div>
    </div>
</template>

<script>
 import{mapState} from'vuex';
export default {
    mounted() {
        this.$store.dispatch('PostPj');
        this.$store.dispatch('PostOtherPj');
    },
    computed:{
        ...mapState({
            Pj:state=>state.share.Pj,
            OtherPj:state=>state.share.OtherPj,
            Http:state=>state.share.Http,
        })
    },
    methods: {
        Details(id){
            this.$router.push({
                name:'EngDetails',
                    query:{
                        id:id,
                    }
            })
        },
        ursl(content){
            console.log(content)
            let noticeContent = content;
            let imgReg = RegExp(/<img [^>]*src=['"]([^'"]+)[^>]*>/gi);//定义正则，筛选出img元素
            let matchres=noticeContent.match(imgReg);
            if(matchres){
            matchres.forEach((item,index)=>{
                let _tempStr = item.slice(0,item.length-2);
                let _index = _tempStr.indexOf('/');
                let _str= _tempStr.substring(0, _index) + this.Http +  _tempStr.substring(_index, _tempStr.length) + '"/>';
                noticeContent=noticeContent.replace(item,_str)
            })
            }
            return noticeContent
        },
    },
}
</script>
<style scoped>
    .exchange{
        margin: 36px auto;
        overflow: hidden;
    }
    .exchange-nr{
        width: 100%;
    }
    .exchange-nr h1{
        text-align: center;
        margin-bottom: 40px;
        margin-top: 20px;
        font-size: 18px;
    }
    .exchange-nr ul{
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
    }
    .exchange-nr ul li{
        width: 31%;
        margin-left: 3.5%;
        overflow: hidden;
        cursor: pointer;
    }
    .exchange-nr ul li img{
        width: 100%;
        height: 260px;
    }
    .neks{
        height: 260px;
        overflow: hidden;
    }
    .exchange-nr ul li:hover img {
        transform: scale(1.1);
        transition: all 0.5s ease; 
    }
    .exchange-nr ul li:nth-child(1){
        margin-left: 0;
    }
    @media (max-width: 768px){
        .exchange-nr ul li{
            width: 100%;
            margin-left: 0%;
        }
        .smallContent>>>img {
        width: 100% !important;
        }
        .Servic{
            margin-top: 0;
        }
    }
</style>