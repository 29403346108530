<template>
    <div>
        <News-List :data='Notice' :name='title'  @getAreaInfo='getAreaInfo' :ititem='navigation[4].son[nids]'/>
        <Pagination 
        :pageNo="naml.page" 
        :pageSize='naml.limit' 
        :total="Notice.count" 
        :continues="5"
        @getPageNo='getPageNo'
        />
    </div>
</template>

<script>
import {mapState} from 'vuex'
import NewsList from '@/components/pc/NewsListThe'
import SubNavigation from '@/components/pc/SubNavigation'
export default {
  data() {
    return {
        naml:{
          cid:1,
          limit:6,
          page:1,
        },
        title:{
            name:''
        }
    }
  },
    components:{
      NewsList,
      SubNavigation
    } ,
    activated() {
        console.log(this.$route.query)

    },
    computed:{
        ...mapState({
            navigation:state=>state.share.navigation,
            Notice:state=>state.share.Member,
            nids:state=>state.share.nids
        })
    },
    watch: {
        nids: {
            immediate: true, //初始化时让handler调用一下
            //handler什么时候调用？当mane发送改变时
            handler(newvalue, oldvalue) {
                this.naml.cid=newvalue;
                this.postNews(this.naml)
                // console.log('mane呗修改了', newvalue, oldvalue)
            }
        }
    },
    methods: {
        getPageNo(id){
            this.naml.page=id;
            this.postNews(this.naml)
        },
        postNews(naml){
            this.$store.dispatch('PostMember',naml);
        },
        getAreaInfo(id){
           this.$router.push({
            name:'Details',
                query:{
                    id:id,
                }
        })
        }
    },
}
</script>

<style scoped>
    .work{
        overflow: hidden;
        margin: 20px auto;
        padding: 2% 2%;
    }
    .work li{
        width: 32%;
        float: left;
        margin-right: 2%;
        cursor: pointer;
        box-shadow: 0px 3px 3px #d9d9d9;
        border-radius: 10px;
        overflow: hidden;
        position: relative;
        overflow: hidden;
        max-height: 400px;
        margin-bottom: 40px;
    }
    .work li:nth-child(3n){
        margin-right: 0;
    }
    .work li .img-nrk{
        width: 100%;
        height: 300px;
        overflow: hidden; 
    }
    .work li .img-nrk img{
        width: 100%;
        margin: 0 auto;
        border-radius: 10px;
        height: 300px;    
    }
    .work li:hover img {
    transform: scale(1.1);
    transition: all 0.5s ease; 
    }
    .work li span{
        text-align: center;
        display: block;
        margin: 34px 0;
        font-size: 18px;
    }
    .work li:hover{
        color: #0019a7;
    }
    @media (max-width: 768px){
        .work li{
            width: 100%;
        }
        .work li .nmkimg,.work li img{
            height: auto;
        }
    }
</style>