<template>
    <div>
        <img src="img/banner-0.jpg" class="banone pc">
        <img src="img/web-baner1.jpg" class="banone mobile">
        <!--资质荣誉-->
        <div class="title">
            <p>
                <i>Part Four: Corporate Culture</i>
            </p>
        </div>
        <!-- <div class="nrk">
            <div class="rongyutb">
                <p>1.HOME CULTURE:</p>
                <p>ESTABLISH A PRACTICAL “HOME RULES” (SYSTEM).</p>
                <p>CREATE A UNITED AND HARMONIOUS “HOME VALUES” (ATMOSPHERE).</p>
                <p>UNITE THE SMALL FAMILY, DEVELOP THE BIG FAMILY, CONTRIBUTE TO THE COUNTRY, AND BUILD A WARM AND UPWARD “RONGXIANG HOME”.</p>
            </div>
            <div class="rongyutb">
                <p>2.HARMONY CULTURE:</p>
                <p>SEEK COMMON GROUND WHILE PRESERVING DIFFERENCES, ENHANCE INTEGRATION, AND EMPHASIZE TEAMWORK.</p>
                <p>UNITE SINCERELY, GET ALONG HARMONIOUSLY AND RESOLVE TEAM CONFLICTS.
CREATE AN ATMOSPHERE OF UNITY, HARD WORK, AND UPWARD STRIVING, PROMOTING RONGXIANG’S DEVELOPMENT STRATEGY OF “INTERNATIONALIZATION OF GOALS, DIVERSIFICATION OF INDUSTRIES, STANDARDIZATION OF MANAGEMENT, AND SPECIALIZATION OF TALENTS”.</p>
            </div>
            <div class="rongyutb">
                <p>3.RIGHTEOUSNESS CULTURE:</p>
                <p>UPHOLD JUSTICE, SERVE THE PUBLIC, BE LOYAL TO THE ENTERPRISE, SEEK BENEFITS THROUGH RIGHTEOUSNESS, AND SHARE HONOR AND DISGRACE.</p>
                <p>STAND AT THE FOREFRONT, SEIZE OPPORTUNITIES, STRIVE FOR THE TOP, AND PROMOTE INNOVATION.</p>
            </div>
            <div class="rongyutb">
                <p>4.LOVE CULTURE:</p>
                <p>LOVE ONESELF AND FAMILY, STRIVE FOR PERSONAL DIGNITY AND FAMILY HAPPINESS.
LOVE THE POSITION, LOVE THE TEAM, WORK HARD TO ESTABLISH RONGXIANG’S CORE VALUES OF “INTEGRITY FIRST AND INNOVATION FOR SUCCESS”.</p>
                <p>LOVE THE COUNTRY, LOVE SOCIETY, AND CONTRIBUTE SINCERELY WITH A GREAT HEART.</p>
            </div>
            <div class="rongyutb">
                <p>5.HONESTY CULTURE:</p>
                <p>BE HONEST IN PERSON, TRUSTWORTHY IN WORK, AND REMEMBER RONGXIANG’S MANAGEMENT AND SERVICE PHILOSOPHIES.</p>
                <p>BUILD PERSONAL INTEGRITY FIRST, THEN PRODUCE QUALITY PRODUCTS, SHAPING RONGXIANG’S HONEST AND TRUSTWORTHY BRAND IMAGE.</p>
                <p>PROVIDE FIRST-CLASS SERVICE, CREATE A FIRST-CLASS BRAND, BUILD A FIRST-CLASS ENTERPRISE, AND STRIVE TO ACHIEVE THE BEAUTIFUL VISION OF “BUILDING CHINA’S MOST INFLUENTIAL DIVERSIFIED INDUSTRIAL GROUP”.</p>
            </div>
        </div> -->
        <img src="img/zx-10.jpg" class="nark pc">
        <img src="img/zx-12.jpg" class="nark mobile">
    </div>
    
</template>

<script>
export default {
}
</script>

<style scoped>
    .beij{
        width: 100%;
        margin-top: 40px;
    }
    .rongyutb{
        margin-top: 30px;
    }
    .rongyutb p{
        font-size: 24px;
        text-align: center;
    }
    .company{
        margin: 2% auto;
        overflow: hidden;
    }
    .litext{
        width: 60%;
        float: left;
        padding-top: 1%;
        font-size: 18px;
        line-height: 40px;
        text-align: justify;
        text-indent: 2em;
    }
    .litext-text{
        width: 36%;
        float: right;
    }
    .course {
        width: 100%;
        background: url(@/assets/beij_2.jpg) no-repeat center center;
        overflow: hidden;
        margin-top: 90px;
    }

    .course-nr {
        margin: 0 auto;
        margin-top: 60px;
        margin-bottom: 60px;
    }

    .course-text {
        width: 100%;
        overflow: hidden;
    }

    .course-text li {
        float: left;
        width: 25%;
        height: 110px;
    }
    .course-text li:nth-child(2){
        text-align: center;
    }
    .course-text li:nth-child(2) i{
        margin-left:120px;
    }
    .course-text li:nth-child(3){
        text-align: center;
    }
    .course-text li:nth-child(3) i{
        margin-left:60px;
    }
    .course-text li:last-child{
        text-align: right;
    }
    .course-text li:last-child span{
        text-align: right;
        margin-right: 37px;
    }
    .course-text li:last-child i{
        float: right;
    }
    .course-text li:last-child p{
        float: right;
    }
    .course-text li>div{
        font-size: 76px;
        color: #fff;
        font-weight: bold;
        overflow: hidden;
    }

    .course-text li p {
        font-size: 50px;
        float: left;
    }

    .course-text span {
        font-size: 28px;
        margin-top: 30px;
        color: #fff;
        display: block;
    }

    .course-text li>div i {
        float: left;
    }
    .course-bottom-text {
        font-size: 26px;
        margin-top: 70px;
        color: #fff;
        text-align: justify;
        line-height: 50px;
        margin-bottom: 200px;
    }
    .liesc{
        margin-top: 40px;
        overflow: hidden;
    }
    .liesc>div{
        width: 43%;
        float: left;
        margin-right: 4%;
        border: 1px #eee solid;
        border-radius: 15px;
        padding: 1% 2%;
        margin-bottom: 24px;
    }
    .liesc>div:nth-child(2n){
        margin-right: 0%;
    }
    .letr{
        width: 26%;
        border-right: 1px #eee solid;
        float: left;
        margin-top: 10px;
    }
    .letr img{
        width: 60%;
        margin: 0 auto;
        display: block;
    }
    .letr p{
        text-align:center;
        font-size: 16px;
        font-weight: bold;
        margin-top: 6px;
    }
    .liesc .rlis{
        width: 63%;
        float: right;
        font-size: 14px;
        text-align: justify;

        height: 160px;
        overflow: auto;
        text-indent: 2em;
        padding: 24px 2%;
    }
    .rlis::-webkit-scrollbar { width: 10px;}
    .rlis::-webkit-scrollbar-track { background-color: #e9e9f4;  border-radius: 100px;}
    .rlis::-webkit-scrollbar-thumb { box-shadow: inset 0 0 6px #002cd0;  border-radius: 100px;}
    .rlis::-webkit-scrollbar {width: 10px;} 
    .liesc .rlis span{
        display: block;
        margin-top: 20px;
    }

    @media (max-width: 768px){
        .litext{
            width: 100%;
            font-size: 16px;
            line-height: 30px;
        }
        .litext-text{
            width: 100%;    
        }
        .liesc>div{
            width: 100%;
        }
        .liesc .rlis span{
            margin-top: 0;
        }
        .liesc .rlis{
            margin-right: 5%;
            width: 60%;
        }
        .letr{
            margin-top: 60px;
        }
        .rlis::-webkit-scrollbar { width: 10px;}
        .course{
            margin-top: 40px;
        }
        .course-text span{
            font-size: 14px;
            text-align: center;
            margin-top: 12px;
        }
        .course-text li{
            width: 50%;
            margin-bottom: 20px;
        }
        .course-text li>div{
            font-size: 40px;
            display: flex;
            justify-content: center;
        }
        .course-text li:nth-child(2) i{
            margin-left: 16px;
        }
        .course-text li p{
            font-size: 24px;
        }
        .course-text li:nth-child(3) i{
            margin-left: 12px;
        }
        .course-nr{
            margin-top: 40px;
            width: auto;
            margin-bottom: 20px;
        }
    }
</style>